
// Import Login elements
import { refreshTokensAtom, dashboardsAtom, soilTokensAtom } from './authState'
import { IRIS_APP_ID, TENANT_ID } from './authConfig'
import {
  getTokenExpirationDate, login, RECOVER_URL, FORGET_URL,
  extractAppIds, askFilterDashboard, askDashboards, UserLogin,
  Response, checkboxLabelDict
} from './authUtils'

// Import utils
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { zipObject } from 'lodash'
import { useSetRecoilState } from 'recoil'

// import UI elements
import { AmalfiLogin } from '@amalfi-analytics/components'
import { LanguageSelectorProps } from '@amalfi-analytics/components/dist/types/components/LanguageSelector'

export interface onSubmitProps {
  user: UserLogin
}

export interface IrisLoginProps {
  languageProps: {
    acceptedLanguages: Array<{ code: string, language: string }>
    defaultLanguage: string
  }
  AppLogo: any
}

const IrisLogin = (props: IrisLoginProps): JSX.Element => {
  // Initialitzation //
  const { languageProps, AppLogo } = props
  const { defaultLanguage, acceptedLanguages } = languageProps
  const { t } = useTranslation()

  const defaultResp: Response = { error: false }
  const [error, setError] = useState(defaultResp)
  const [checked, setChecked] = useState(Boolean(localStorage.getItem('remember') === 'true'))
  const [language, setLanguage] = useLocalStorage(
    'i18nextLng',
    defaultLanguage,
    { raw: false, serializer: String, deserializer: String }
  )

  const setRefreshTokensAtom = useSetRecoilState(refreshTokensAtom)
  const setDashboardAtom = useSetRecoilState(dashboardsAtom)
  const setSoilTokensAtom = useSetRecoilState(soilTokensAtom)
  const forgotUrl = FORGET_URL + (TENANT_ID as string)

  // Language Selector //
  const languages = acceptedLanguages.map(w => w.code)

  const handleChangeLanguage = (language?: string): void => {
    if (languages.includes(language ?? '')) {
      setLanguage(language)
      // @ts-expect-error
      window.__localeId__ = language
    }
  }

  useEffect(() => {
    i18n.changeLanguage(language).catch(() => { }).finally(() => { })
    handleChangeLanguage(language)
    // eslint-disable-next-line
  }, [language])
  const languageSelectorProps: LanguageSelectorProps = {
    language,
    acceptedLanguages,
    setLanguage: handleChangeLanguage,
    labels: {
      label: t('login.language')
    }
  }

  // Submit Declaration //
  const onSubmit = async (user: UserLogin): Promise<boolean> => {
    // Login to Iris
    const irisTokensResponse = await login(user.email, user.password, IRIS_APP_ID)
    const irisTokens = irisTokensResponse.state !== undefined && irisTokensResponse.state !== null ? irisTokensResponse.data : undefined
    if (!irisTokensResponse.state) {
      const msg = irisTokensResponse.msg !== undefined ? irisTokensResponse.msg : t('error.21')
      if (msg === 'error.33') {
        const msgTranslation = t(msg)
        setError({ error: true, errorMessage: msgTranslation, errorURL: RECOVER_URL + (irisTokensResponse?.response?.changePasswordId ?? '') })
      } else {
        setError({ error: true, errorMessage: msg, errorURL: forgotUrl })
      }
      return false
    }

    if (irisTokens !== undefined && irisTokens !== null) {
      try {
        const dashboardsResponse = await askDashboards(irisTokens.token)
        const dashboards = dashboardsResponse.state ? dashboardsResponse.data : undefined
        if (!dashboardsResponse.state) {
          const error11 = t('error.11')
          setError({ error: true, errorMessage: error11 })
          return false
        }

        const dashboardResponse = askFilterDashboard(dashboards)
        const dashboard = dashboardResponse.state ? dashboardResponse.data : undefined
        if (!dashboardResponse.state || dashboard === undefined) {
          const error13 = t('error.13')
          setError({ error: true, errorMessage: error13 })
          return false
        }
        const appIds = extractAppIds(dashboard)

        if (appIds === null) {
          const error14 = t('error.14')
          setError({ error: true, errorMessage: error14 })
          return false
        }

        const appTokens = await Promise.all(appIds.map(async appId => await login(user.email, user.password, appId)))

        const failed = appTokens.filter(token => token.msg)

        if (failed.length > 0) {
          const msgs = failed.filter(token => token.msg !== undefined).map(token => token.msg)
          const msg = msgs.length > 0 ? msgs[0] : t('error.14')
          setError({ error: true, errorMessage: msg })
          return false
        }

        const tokens = {
          ...zipObject(appIds, appTokens
            .map(t => t.state !== undefined && t.state !== null ? t.data : null)
            .filter(t => t !== null).map(t => { return t !== null && (t != null) ? { token: t.token, expirationDate: getTokenExpirationDate(t.token) } : null })),
          [IRIS_APP_ID]: ({ token: irisTokens.token, expirationDate: getTokenExpirationDate(irisTokens.token) })
        }
        const refreshTokens = {
          ...zipObject(appIds, appTokens.map(t => {
            if (t.state !== undefined && t.state !== null) { return t.data }
            return null
          }).map(t => { return t !== null && (t != null) ? t.refreshToken : null })),
          [IRIS_APP_ID]: irisTokens.refreshToken
        }
        // return false
        const nonDefined = Object.values(tokens).filter(t => (t === undefined || t === null)).length + Object.values(refreshTokens).filter(t => (t === undefined || t === null)).length

        if (nonDefined > 0) {
          const error14 = t('error.14')
          setError({ error: true, errorMessage: error14 })
          return false
        }
        setDashboardAtom(dashboard)
        setSoilTokensAtom(tokens)
        setRefreshTokensAtom(refreshTokens)
        return true
      } catch (e) {
        console.error(e)
        const storage = localStorage.getItem('remember') === 'true' ? localStorage : sessionStorage
        storage.removeItem('soilTokens')
        storage.removeItem('refresh_tokens')

        const msg = 'Something wrong just happened!'
        setError({ error: true, errorMessage: msg, errorURL: `https://auth.amalfianalytics.com/password/change?changePasswordId=${(irisTokensResponse?.response?.changePasswordId) ?? ''}` })
        return false
      }
    }
    return false
  }

  // Login labels declaration //
  const labels = {
    password: t('login.password'),
    user: t('login.user'),
    login: 'Login',
    forgotPassword: t('login.forgot_password')
  }

  // Storage Checkbox //
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked)
    localStorage.setItem('remember', String(event.target.checked))
  }
  const checkboxLabel = checkboxLabelDict[language ?? defaultLanguage]

  // Call to UI element //
  return (
    <>
      <AmalfiLogin
        AppLogo={AppLogo}
        labels={labels}
        forgotUrl={forgotUrl}
        onSubmit={onSubmit}
        languageSelectorProps={languageSelectorProps}
        error={error}
        storageCheckBox={{
          checked,
          onChange: handleChange,
          label: checkboxLabel
        }}
      />
    </>
  )
}

export default IrisLogin
