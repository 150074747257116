interface LogoProps {
  width?: number
  height?: number
  color?: string
}

const ApisLogo = ({ width, height, color }: LogoProps): JSX.Element => (
  <svg version='1.1' viewBox='0 0 272.6 137.9' height={height} width={width} style={{ fill: color }}>
    <g>
      <path d='M180.9,36.1c0,12.1-3.9,20.4-11.8,25c-6.6,3.8-17.3,5.7-32.3,5.7v43.4H128V6.2h17.9c10.4,0,18.7,2.5,25.1,7.6 C177.6,19.2,180.9,26.6,180.9,36.1z M172.2,36.3c0-8.6-4-14.7-11.9-18.3c-5.2-2.3-11.6-3.5-19.1-3.5c-1.3,0-2.7,0-4.3,0.1v43 c12.3,0,20.6-0.9,24.8-2.6C168.7,52.1,172.2,45.9,172.2,36.3z' />
      <path d='M200.9,15c-3.3,0-4.9-1.5-4.9-4.6s1.6-4.6,4.9-4.6c3.3,0,4.9,1.5,4.9,4.6S204.2,15,200.9,15z M203.6,33.7v76.5 h-5.5V33.7H203.6z' />
      <path d='M242.6,35.9c-2.9,0-5.5,1-7.8,2.9c-2.3,1.9-3.4,4.3-3.4,7.1c0,2.8,1.3,5.4,3.8,7.8c2.5,2.4,5.6,4.5,9.1,6.5 c3.5,2,7.1,4.1,10.7,6.3c3.6,2.3,6.7,5.1,9.2,8.6c2.5,3.5,3.8,7.5,3.8,12c0,6.6-2.5,12.3-7.5,17c-5,4.7-11.1,7-18.1,7 c-4.5,0-8.7-1-12.6-3c-3.9-2-6.7-4.6-8.5-7.8v-8.1c4.3,9.4,11.1,14.2,20.6,14.2c5.8,0,10.8-1.8,14.8-5.4c4-3.6,6-7.6,6-12.1 c0-4.4-1.3-8.2-3.8-11.2c-2.5-3-5.6-5.6-9.2-7.8c-3.6-2.1-7.2-4.2-10.7-6.3c-3.5-2.1-6.6-4.6-9.1-7.6c-2.5-3-3.8-6.5-3.8-10.5 c0-4,1.6-7.4,4.8-10.1c3.2-2.8,7.3-4.1,12.4-4.1c5.1,0,9.4,2,13,5.9V44C252.7,38.6,248.1,35.9,242.6,35.9z' />
      <g>
        <g>
          <rect x='56.4' y='127' style={{ fill: '#009845' }} width='55.2' height='8.9' />
          <g>
            <path d='M90.7,92c-9.2,9.5-20.4,14.2-33.4,14.2c-13,0-24.3-4.7-33.7-14.2c-7.8-7.8-12.4-16.7-13.7-26.8H2.4 c1.5,12,6.7,22.4,15.7,31.4C29,107.3,41.9,112.7,57,112.7c9.9,0,19.1-2.5,27.7-7.6c8.6-5.1,15.1-12,19.4-20.8v25.9h7.4V65.2 h-7.4C102.8,75.3,98.3,84.3,90.7,92z' />
            <path d='M23.6,24.5c9.5-9.6,20.7-14.4,33.6-14.4c12.9,0,24.1,4.8,33.4,14.3c8,8.2,12.5,17.6,13.7,28.4h7.2V6.2h-7.4 v26.1c-3.6-8.6-9.8-15.6-18.8-20.8c-9-5.2-18.5-7.9-28.3-7.9C41.9,3.6,29,9,18.2,19.7C8.7,29.1,3.4,40.1,2.2,52.8h7.5 C10.9,42.2,15.5,32.8,23.6,24.5z' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ApisLogo
