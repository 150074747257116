import {
  RecoilState,
  RecoilValueReadOnly,
  useSetRecoilState,
  useRecoilValue
} from 'recoil'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { AmalfiDatepicker } from '@amalfi-analytics/components'
import { formatStringDate } from '../utils'

interface IrisDatePickerProps {
  atom: RecoilState<{
    name: string
    value: { gte: string, lt: string }
  }>
  selector: RecoilValueReadOnly<{
    name: string
    value: { gte: string, lt: string }
  }>
  disabled?: boolean
}

// TO DO: Fix the disable button
export default function IrisDatePicker ({ atom, selector }: IrisDatePickerProps): JSX.Element {
  const { t } = useTranslation()
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)
  if (state === undefined) {
    return <Skeleton />
  }

  const handleIni = (newValue: string | null): void => {
    if (newValue !== null) {
      const value = { ...state.value, gte: formatStringDate(newValue) }
      setState({ ...state, value })
    }
  }

  const handleEnd = (newValue: string | null): void => {
    if (newValue !== null) {
      const value = { ...state.value, lt: formatStringDate(newValue) }
      setState({ ...state, value })
    }
  }
  // const disabled =  props.disabled ? true : false
  // const disabled = true
  const { gte, lt } = state.value
  return (
    <Grid container spacing={1} direction='row'>
      <Grid item>
        <AmalfiDatepicker
          value={gte}
          label={t('time.initial_date')}
          handleChange={handleIni}
        // {...disabled}
        />
      </Grid>
      <Grid item>
        <AmalfiDatepicker
          value={lt}
          label={t('time.final_date')}
          handleChange={handleEnd}
        />
      </Grid>
    </Grid>
  )
}
