import { atom, selectorFamily } from 'recoil'
import { configurationType } from '../types/types'
import { SOIL_URL } from './authConfig'

const localStorageEffect = (key: string) => ({ setSelf, onSet }: any) => {
  let storage = localStorage.getItem('remember') === 'true' ? localStorage : sessionStorage

  const savedValue = storage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue))
  }

  onSet((newValue: any) => {
    storage = localStorage.getItem('remember') === 'true' ? localStorage : sessionStorage
    if (newValue === null) {
      storage.removeItem(key)
    } else {
      storage.setItem(key, JSON.stringify(newValue))
    }
  })
}

export type RefreshToken = string
export interface refreshTokensAtomType { [appId: string]: RefreshToken }
export const refreshTokensAtom = atom<null | refreshTokensAtomType>({
  key: 'RefreshTokens',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect('refresh_tokens')
  ]
})

export const currentDashboardAtom = atom<undefined | string>({
  key: 'CurrentDashboard',
  default: undefined,
  effects_UNSTABLE: [
    localStorageEffect('current_dashboard')
  ]
})

export const drawerOpenAtom = atom<boolean>({
  key: 'DrawerOpen',
  default: true,
  effects_UNSTABLE: [
    localStorageEffect('drawer_open')
  ]
})

export type Dashboard = string
// To do: replace any by somethig similar to []
export const dashboardsAtom = atom<null | configurationType[]>({
  key: 'Dashboards',
  default: null
  // effects_UNSTABLE: [
  //     localStorageEffect('dashboards'),
  // ]
})

export interface SoilToken { token: string, expirationDate: Date }
export interface SoilTokensAtomsType { [appId: string]: SoilToken }
export const soilTokensAtom = atom<null | SoilTokensAtomsType>({
  key: 'SoilTokens',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect('soilTokens')
  ]
})

export const emptyAtom = atom({
  key: 'Empty', default: null
})

interface soilConfigType { host: string, token: string }

export const soilConfigFamily = selectorFamily({
  key: 'SoilConfigFamily',
  get: (appId: string) => ({ get }) => {
    const soilTokens = get(soilTokensAtom)
    // To Fix @Marti
    if (soilTokens === null) {
      return null
    }
    const res: soilConfigType = {
      host: `${SOIL_URL}/api`,
      token: soilTokens[appId].token
    }
    return res
  }
})
