import { omit } from 'lodash'
import Skeleton from '@mui/material/Skeleton'
import { AmalfiMultiCard } from '@amalfi-analytics/components'
import { IrisMultiCardType } from '../../types/componentTypes'
import useData from '../../utils/useData'
import useDictionary from '../../utils/useDictionary'

const IrisMultiCard = ({
  element
}: {
  element: IrisMultiCardType
}): JSX.Element => {
  const data = useData(element)
  const { searchInDictionary, loading, error } = useDictionary(
    element.dictName,
    element.applicationID
  )
  const castedProps = omit(element.props, 'data') as {
    groupBy: string
    showBy: string
    colors: Array<{ name: string, value: string }>
  }
  if (error !== undefined) {
    console.error(error)
  }
  if (data.error !== undefined) {
    console.error(data.error)
  }
  if (data.data === undefined || error !== undefined || loading) {
    return (
      <Skeleton
        variant='rectangular'
        width='96%'
        height='100'
        style={{ margin: 10 }}
      />
    )
  }
  const translatedData = data.data.map((row: any) => ({
    ...row,
    [castedProps.groupBy]: searchInDictionary(row[castedProps.groupBy]),
    [castedProps.showBy]: searchInDictionary(row[castedProps.showBy])
  }))
  return <AmalfiMultiCard<any> data={translatedData} {...castedProps} />
}

export default IrisMultiCard
