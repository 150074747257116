import { useAsync } from 'react-use'
import { isObject } from 'lodash'
import soil from '@amalfi-analytics/soil-sdk'
import { useRecoilValue } from 'recoil'
import { SoilInstance, Dictionary } from '@amalfi-analytics/soil-sdk/types/SoilInstance'
import { IRIS_APP_ID } from '../authentification/authConfig'
import { soilTokensAtom, soilConfigFamily } from '../authentification/authState'

const dictionaryCache: { [key: string]: Dictionary } = {}
async function memoizedDictionary (soilInstance: SoilInstance, dictName: string, language: string): Promise<Dictionary> {
  if (dictionaryCache[dictName + language] !== undefined) {
    return dictionaryCache[dictName + language]
  }
  const dict = await soilInstance.getDictionary(dictName, language)
  dictionaryCache[dictName + language] = dict
  return dictionaryCache[dictName + language]
}

export async function getDict (
  dictName: string | undefined,
  soilInstance: SoilInstance
): Promise<{
    searchInDictionary: (key?: string | undefined, strict?: boolean) => string
    dictionary: Dictionary | null
  }> {
  const lang = localStorage.getItem('i18nextLng') ?? 'ca' // TODO: - getLanguage
  let dict: Dictionary | null = null
  try {
    if (dictName !== undefined) {
      dict = await memoizedDictionary(soilInstance, dictName, lang)
    }
  } catch (e) {
    console.warn(`Dictionary ${dictName ?? ''} not found.`)
  }
  const searchDict = (key?: string, strict = false): string => {
    if (key === undefined) {
      return ''
    }
    if (dict !== null) {
      // This is to ensure compatibility with all dictionary formats.
      let content: { [id: string]: string } = dict.content as never as { [id: string]: string }
      if ('list' in dict.content && isObject(dict.content.list)) {
        content = dict.content.list
      }
      if (content[key] !== undefined) {
        return content[key]
      }
      if (strict) {
        throw new Error(`Key ${key ?? ''} not found in dictionary ${dictName ?? ''}`)
      }
      return key ?? ''
    }
    if (strict) {
      throw new Error(`Dictionary ${dictName ?? ''} has not been loaded.`)
    }
    return key ?? ''
  }
  return { searchInDictionary: searchDict, dictionary: dict }
}

/**
 *
 * @param dictionaryName The name of the dictioanry. When the name changes the dictionary is reloaded.
 * @returns A function that gets a key and a strict mode flag. The strict mode flag will throw an exception
 * if the dictionary does not contain the key. If the key is undefined the function returns an empty string to avoid
 * having to set as string all the time.
 */
function useDictionary (dictionaryName?: string, appId?: string): {
  loading: boolean
  error: Error | undefined
  searchInDictionary: (key?: string | undefined, strict?: boolean) => string
  dictionary: Dictionary | null
} {
  const tokens = useRecoilValue(soilTokensAtom)
  if (appId === undefined) {
    appId = Object.keys(tokens ?? []).filter(k => k !== IRIS_APP_ID)[0]
  }
  const config = useRecoilValue(soilConfigFamily(appId))
  if (config === null) {
    throw new Error(`Could not find a token for appId: ${appId}`)
  }
  const soilInstance = soil.configure(config)
  const { loading, error, value } = useAsync(async () => await getDict(dictionaryName, soilInstance), [dictionaryName])
  if (value === undefined) {
    return { loading, error, searchInDictionary: (key?: string, strict = false) => key ?? '', dictionary: null }
  }
  return { loading, error, searchInDictionary: value.searchInDictionary, dictionary: value.dictionary }
}

export default useDictionary
