import { omit } from 'lodash'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import ComponentFactory from './componentsFactory'
import { ContainedItem } from './dashboardUtils'

export const TraverseArray = ({ arr }: { arr: ContainedItem[] }): JSX.Element => (
  <>{arr.map((element, i) => <TraverseObject key={`element_${i}`} element={element} />)}</>
)

export const TraverseObject = ({ element }: { element: ContainedItem }): JSX.Element => {
  let children
  if (element.components !== undefined) {
    children = <TraverseArray arr={element.components} />
    if (element.gridProps !== undefined && 'paper' in element.gridProps) {
      console.warn('element.gridProps.paper: true is deprecated and will be removed in future versions. Use IrisPaper component instead.')
      return (
        <Grid container item {...omit(element.gridProps, ['spacing', 'paper', 'direction']) as any}>
          <Paper style={{ width: '100%', padding: 20, height: '100%' }}>
            <Grid container justifyContent='center'>
              {children}
            </Grid>
          </Paper>
        </Grid>
      )
    }
    if (element.component === undefined) {
      return (
        <Grid container item {...{ spacing: 2, ...element.gridProps }}>
          {children}
        </Grid>
      )
    }
  }
  if (element.component !== undefined) {
    const gridChildren = (
      <Grid container {...element.gridProps}>
        {children}
      </Grid>
    )
    const component = ComponentFactory(element, gridChildren)
    return (
      <Grid item {...element.gridProps}>
        {component}
      </Grid>
    )
  }
  throw new Error('Error traversing object.')
}
