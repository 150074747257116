/* eslint-disable @typescript-eslint/naming-convention */

// @ts-expect-error
const { iris_app_id, login_key, tenant_id, iris_url, dashboard_update_time, soil_url, app_name } = window.CONFIG

export const IRIS_APP_ID = iris_app_id
export const LOGIN_KEY = login_key
export const TENANT_ID = tenant_id
export const IRIS_URL = iris_url
// Time in seconds to update the dashboard. Default 1 minute.
export const DASHBOARD_UPDATE_TIME = dashboard_update_time ?? 60
export const SOIL_URL = soil_url as string ?? 'https://soil.amalfianalytics.com'
export const APP_NAME = app_name as (string | undefined)
