import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import AmalfiTrafficLight from './AmalfiTrafficLight'

const LastActualitzation = ({ label, time }: { label?: string, time?: string }): JSX.Element => {
  return (
    <Paper elevation={3} style={{ textAlign: 'center', padding: 4 }}>
      <Typography>{label ?? ''} {time ?? ''} </Typography>
    </Paper>
  )
}

const CurrentTime = ({ date }: { date?: { value: { lt: string, gte: string } } }): JSX.Element => {
  return (
    <Paper elevation={3} style={{ textAlign: 'center', padding: 4 }}>
      <Typography>{date?.value.gte} - {date?.value.lt}</Typography>
    </Paper>
  )
}

interface AmalfiActualizationMenuProps {
  timeFilterValue?: { value: { gte: string, lt: string } }
  lights?: boolean[]
  lastUpdate?: string
  lastUpdateLabel?: string
  lightsLabel?: string
}

const AmalfiActualitzationMenu = ({ timeFilterValue, lights, lastUpdate, lastUpdateLabel, lightsLabel }: AmalfiActualizationMenuProps): JSX.Element => {
  if (timeFilterValue !== undefined && lastUpdate !== undefined) {
    return (
      <Grid
        container
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={1}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <LastActualitzation label={lastUpdateLabel} time={lastUpdate} />
        </Grid>
        <Grid item xs={2}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <CurrentTime date={timeFilterValue} />
        </Grid>
        <Grid item xs={1}>
          <Divider />
        </Grid>
      </Grid>
    )
  }
  if (lights !== undefined && lastUpdate !== undefined) {
    return (
      <Grid
        container
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={1}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <LastActualitzation label={lastUpdateLabel} time={lastUpdate} />
        </Grid>
        <Grid item xs={2}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <AmalfiTrafficLight lights={lights} label={lightsLabel} />
        </Grid>
        <Grid item xs={1}>
          <Divider />
        </Grid>
      </Grid>
    )
  }
  if (lights !== undefined) {
    return (
      <Grid
        container
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={4}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <AmalfiTrafficLight lights={lights} label={lightsLabel} />
        </Grid>
        <Grid item xs={4}>
          <Divider />
        </Grid>
      </Grid>
    )
  }
  if (lastUpdate !== undefined) {
    return (
      <Grid
        container
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={4}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <LastActualitzation label={lastUpdateLabel} time={lastUpdate} />
        </Grid>
        <Grid item xs={4}>
          <Divider />
        </Grid>
      </Grid>
    )
  }
  return <></>
}

export default AmalfiActualitzationMenu
