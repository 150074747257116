import useDictionary from '../../utils/useDictionary'
import { useTranslation } from 'react-i18next'
import useData from '../../utils/useData'
import { IrisCardType } from '../../types/componentTypes'
import Skeleton from '@mui/material/Skeleton'
import { AmalfiCard, AmalfiCardProps } from '@amalfi-analytics/components'

export interface IrisCardProps extends AmalfiCardProps {
  dictName?: string
  props: AmalfiCardProps
}

const IrisCard = ({ element }: { element: IrisCardType }): JSX.Element => {
  const { props, dictName, applicationID } = element
  const { label, units, variant, format } = props ?? {}
  const { t } = useTranslation()
  const { searchInDictionary } = useDictionary(dictName, applicationID)
  const { data, error } = useData<number | string | { value: number | string, color?: 'primary' | 'secondary' | 'error' }>(element)

  if (error !== undefined) {
    console.error(error)
  }

  if (error !== undefined || data === undefined) {
    return <Skeleton variant='rectangular' width='96%' height='100' style={{ margin: 10 }} />
  }

  // Format the card
  let color = props?.color !== undefined ? props?.color : 'primary'

  // Format the value
  let result
  if (typeof data === 'number' || typeof data === 'string') {
    result = data
  } else {
    result = data.value
    if (data.color !== undefined) {
      color = data.color
    }
  }

  let display
  if (result === null || result === undefined) {
    display = t('error.no_data')
  } else if (format === 'HH:mm') {
    const ROUNDED_VALUE = Math.round(Number(result))
    const hours = Math.floor(ROUNDED_VALUE / 60).toString().padStart(2, '0')
    const minutes = (ROUNDED_VALUE % 60).toString().padStart(2, '0')
    display = hours + ':' + minutes
  } else {
    display = result.toLocaleString('ca', { minimumFractionDigits: 0 })
  }

  const unitsText = searchInDictionary(units)
  const card = {
    value: display,
    label: searchInDictionary(label),
    color,
    units: display !== t('error.no_data') ? unitsText : '',
    variant
  }
  return <AmalfiCard {...card} />
}

export default IrisCard
