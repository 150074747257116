import { RecoilState, RecoilValueReadOnly, useRecoilValue, useSetRecoilState } from 'recoil'
import { AmalfiDateButtonGroup } from '@amalfi-analytics/components'
import useDictionary from '../../utils/useDictionary'

interface RetrospectiveSelectorProps {
  atom: RecoilState<{
    name: string
    value: {
      gte: string
      lt: string
    }
    dictName?: string
  }>
  selector: RecoilValueReadOnly<{
    name: string
    value: {
      gte: string
      lt: string
    }
    dictName?: string
    today?: string
  }>
}

export default function IrisRetrospectiveSelector ({ atom, selector }: RetrospectiveSelectorProps): JSX.Element {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)

  const { loading, searchInDictionary } = useDictionary(state.dictName)

  if (loading) {
    return <></>
  }
  const fromDate = state.today !== undefined ? `'${state.today}'` : 'now'
  const lastDay = (): void => {
    const lt = `$date(${fromDate})`
    const gte = `$date(${fromDate},subtract,1,days)`
    setState({ ...state, value: { gte, lt } })
  }
  const lastWeek = (): void => {
    const lt = `$date(${fromDate})`
    const gte = `$date(${fromDate},subtract,1,weeks)`
    setState({ ...state, value: { gte, lt } })
  }
  const lastMonth = (): void => {
    const lt = `$date(${fromDate})`
    const gte = `$date(${fromDate},subtract,1,months)`
    setState({ ...state, value: { gte, lt } })
  }
  const lastYear = (): void => {
    const lt = `$date(${fromDate})`
    const gte = `$date(${fromDate},subtract,1,years)`
    setState({ ...state, value: { gte, lt } })
  }
  const buttonsData = [
    {
      label: searchInDictionary('last_day'),
      handle: lastDay
    },
    {
      label: searchInDictionary('last_week'),
      handle: lastWeek
    },
    {
      label: searchInDictionary('last_month'),
      handle: lastMonth
    },
    {
      label: searchInDictionary('last_year'),
      handle: lastYear
    }
  ]
  return <AmalfiDateButtonGroup buttonsData={buttonsData} />
}

// Some Fix selectors for demo and fix retrospective
export function RetrospectiveSelector2020 ({ atom, selector }: RetrospectiveSelectorProps): JSX.Element {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)

  const { loading, searchInDictionary } = useDictionary(state.dictName)
  if (loading) {
    return <></>
  }

  const lastDay = (): void => {
    setState({ ...state, value: { gte: '2019-12-31 00:00:00', lt: '2020-01-01 00:00:00' } })
  }
  const lastWeek = (): void => {
    setState({ ...state, value: { gte: '2019-12-24 00:00:00', lt: '2020-01-01 00:00:00' } })
  }
  const lastMonth = (): void => {
    setState({ ...state, value: { gte: '2019-12-01 00:00:00', lt: '2020-01-01 00:00:00' } })
  }
  const lastYear = (): void => {
    setState({ ...state, value: { gte: '2019-01-01 00:00:00', lt: '2020-01-01 00:00:00' } })
  }

  const buttonsData = [
    {
      label: searchInDictionary('last_day'),
      handle: lastDay
    },
    {
      label: searchInDictionary('last_week'),
      handle: lastWeek
    },
    {
      label: searchInDictionary('last_month'),
      handle: lastMonth
    },
    {
      label: searchInDictionary('last_year'),
      handle: lastYear
    }
  ]
  return <AmalfiDateButtonGroup buttonsData={buttonsData} />
}

export function RetrospectiveSelector2021 ({ atom, selector }: RetrospectiveSelectorProps): JSX.Element {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)

  const { loading, searchInDictionary } = useDictionary(state.dictName)
  if (loading) {
    return <></>
  }

  const lastDay = (): void => {
    setState({ ...state, value: { gte: '2020-12-31 00:00:00', lt: '2021-01-01 00:00:00' } })
  }
  const lastWeek = (): void => {
    setState({ ...state, value: { gte: '2020-12-24 00:00:00', lt: '2021-01-01 00:00:00' } })
  }
  const lastMonth = (): void => {
    setState({ ...state, value: { gte: '2020-12-01 00:00:00', lt: '2021-01-01 00:00:00' } })
  }
  const lastYear = (): void => {
    setState({ ...state, value: { gte: '2020-01-01 00:00:00', lt: '2021-01-01 00:00:00' } })
  }

  const buttonsData = [
    {
      label: searchInDictionary('last_day'),
      handle: lastDay
    },
    {
      label: searchInDictionary('last_week'),
      handle: lastWeek
    },
    {
      label: searchInDictionary('last_month'),
      handle: lastMonth
    },
    {
      label: searchInDictionary('last_year'),
      handle: lastYear
    }
  ]
  return <AmalfiDateButtonGroup buttonsData={buttonsData} />
}
