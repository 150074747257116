import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import { green, grey, orange, red } from '@mui/material/colors'
import { mdiCheckboxBlankCircle } from '@mdi/js'
import Icon from '@mdi/react'

export interface AmalfiTrafficLightProps {
  lights: boolean[]
  label?: string
}

const AmalfiTrafficLight = ({ lights, label }: AmalfiTrafficLightProps): JSX.Element => {
  const colors = [red[500], orange[500], green[500]]
  const lgts = lights.map((a, i) => {
    if (!a) { return grey[500] }
    return colors[i]
  })

  return (
    <Paper elevation={3}>
      <Grid container flexDirection='row' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='body1'> {label ?? ''} </Typography>
        </Grid>
        <Grid item>
          <Icon path={mdiCheckboxBlankCircle} size={1} color={lgts[0]} />
        </Grid>
        <Grid item>
          <Icon path={mdiCheckboxBlankCircle} size={1} color={lgts[1]} />
        </Grid>
        <Grid item>
          <Icon path={mdiCheckboxBlankCircle} size={1} color={lgts[2]} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AmalfiTrafficLight
