import { useState } from 'react'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'
import { soilConfigFamily, emptyAtom } from '../authentification/authState'
import { useLocalStorage } from 'react-use'
import { mdiContentSave } from '@mdi/js'
import Icon from '@mdi/react'
import soil from '@amalfi-analytics/soil-sdk'
import { queriesFamily } from '../filters/filtersStateIris'
import { useRecoilValue } from 'recoil'
import { buildQuery } from '../dashboard/dashboardUtils'
import { DownloadButtonType } from '../types/componentTypes'

export default function DownloadButton ({ element }: { element: DownloadButtonType }): JSX.Element {
  const { t } = useTranslation()

  const [language] = useLocalStorage('i18nextLng', 'ca', { raw: false, serializer: String, deserializer: String })
  const [downloading, setDownloading] = useState(false)
  const [downloadingError, setDownloadingError] = useState(false)

  const filters = element.filters ?? []
  const query = useRecoilValue(queriesFamily(filters))

  const appId = element.applicationID
  const alias = element.alias
  const config = useRecoilValue(appId === undefined ? emptyAtom : soilConfigFamily(appId))

  const format = element?.export_props?.format ?? 'xlsx'

  async function getResult (): Promise<unknown> {
    if (config === null || alias === undefined) {
      return false
    }
    const soilInstance = soil.configure(config)
    const dataRef = await soilInstance.data(alias)

    const question = buildQuery(element as any, query as object, language ?? 'ca')

    if (question === null) {
      return
    }

    // const file="data.txt"
    const result = await dataRef.export(JSON.stringify(format), { ...question, _compress: false })
    return result
  }

  function generateBlob (fileName: string, blob: any): void {
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    link.download = fileName
    link.href = url
    link.click()
    window.URL.revokeObjectURL(url)
  }

  const onClick = async (): Promise<void> => {
    setDownloading(true)
    // DATA ANY
    try {
      const data = await getResult() as any
      generateBlob('data.xlsx', data)
    } catch (e) {
      console.error('Error downloading', e)
      setDownloadingError(true)
    }
  }

  return (
    <>
      <Snackbar open={downloading} autoHideDuration={6000} onClose={() => setDownloading(false)}>
        <Alert
          variant='filled'
          elevation={6}
          onClose={() => setDownloading(false)} severity='info' sx={{ width: '100%' }}
        >
          {t('downloads.downloading')}...
        </Alert>
      </Snackbar>
      <Snackbar open={downloadingError} autoHideDuration={6000} onClose={() => setDownloadingError(false)}>
        <Alert
          variant='filled'
          elevation={6}
          onClose={() => setDownloadingError(false)} severity='error' sx={{ width: '100%' }}
        >
          {t('downloads.error')}
        </Alert>
      </Snackbar>
      <Button
        // variant="contained"
        variant='outlined'
        color='inherit'
        startIcon={<Icon path={mdiContentSave} size={1} />}
        onClick={() => {
          onClick().finally(() => { })
        }}
      >
        {t('downloads.download')}
      </Button>
    </>
  )
}
