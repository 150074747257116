import { Fragment } from 'react'
import { useRecoilState } from 'recoil'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import { AmalfiDrawer, AmalfiList, AmalfiListItem, AmalfiMain, AmalfiAppBar } from '@amalfi-analytics/components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { pathsItemProps, routeProps } from '../types/types'
import useDictionary from '../utils/useDictionary'
import { logoutAction } from '../authentification/Logout'
import { drawerOpenAtom } from '../authentification/authState'
import { getAppLogo } from '../utils/appLogo'

const DICT_NAME = 'navigation'

interface OterListItemProps extends pathsItemProps {
  indentation?: number
  searchInDictionary: (key?: string | undefined, strict?: boolean | undefined) => string
}

export const OuterListItem = (props: OterListItemProps): JSX.Element => {
  const { name, icon, path, indentation, searchInDictionary } = props
  const [location, setLocation] = useLocation()

  return (
    <AmalfiListItem
      selected={path === location}
      text={searchInDictionary(name)}
      path={path}
      icon={icon}
      navComponent={Link}
      indentation={indentation ?? 0}
      onClick={() => setLocation(path)}
    />
  )
}

interface RoutedListProps extends routeProps {
  searchInDictionary: (key?: string | undefined, strict?: boolean | undefined) => string
}

export function RoutedList (props: RoutedListProps): JSX.Element {
  const { icon, name, searchInDictionary } = props
  const [location] = useLocation()
  return (
    <AmalfiList
      collapsable
      selected={props.paths.map(r => r.path).includes(location)}
      text={searchInDictionary(name)}
      icon={icon}
    >
      <>
        {
          props.paths.map((route, i) => (
            <OuterListItem key={`${route.path}_${i}`} searchInDictionary={searchInDictionary} indentation={1} {...route} />
          ))
        }
      </>
    </AmalfiList>
  )
}

interface Props {
  logoName?: string
  children?: JSX.Element
  routesData: routeProps[]
  dashboardSelector?: JSX.Element
}

export default function ResponsiveDrawer ({ logoName, routesData, dashboardSelector, children }: Props): JSX.Element {
  const { t } = useTranslation()
  const { searchInDictionary } = useDictionary(DICT_NAME)
  const [open, setOpen] = useRecoilState(drawerOpenAtom)
  const dialogContent = (
    <Box sx={{ pb: 1, pl: 1, pr: 1, maxWidth: 400 }}>
      <Typography>
        {t('help.support')} <Typography component='a' color='primary' href='mailto:suport@amalfianalytics.com'>suport@amalfianalytics.com</Typography>
      </Typography>
    </Box>
  )
  const exitButtonTitle = t('toolbar.exit_button_title')
  const exit = t('toolbar.help')
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AmalfiAppBar
        toolboxProps={{
          logoutProps: {
            logoutAction,
            label: exitButtonTitle
          },
          helpButtonProps: {
            dialogContent,
            label: exit
          },
          extraElements: dashboardSelector
        }}
        bannerProps={{
          AppLogo: getAppLogo(logoName),
          drawerCallback: () => setOpen(!open)
        }}
      />
      <Box component='nav'>
        <AmalfiDrawer open={open}>
          <AmalfiList>
            <>
              {
                routesData.map((group, i) => (
                  <Fragment key={`group_${i}`}>
                    {
                      group.paths.length > 1
                        ? <RoutedList searchInDictionary={searchInDictionary} {...group} />
                        : <OuterListItem searchInDictionary={searchInDictionary} {...group.paths[0]} />
                    }
                    <Divider />
                  </Fragment>
                ))
              }
            </>
          </AmalfiList>
        </AmalfiDrawer>
      </Box>
      <AmalfiMain open={open}>
        <Toolbar />
        {children}
      </AmalfiMain>
    </Box>
  )
}
