interface LogoProps {
  width?: number
  height?: number
  color?: string
}

// style="enable-background:new 0 0 546.9 140.9;"

const Logo = ({ width, height, color }: LogoProps): JSX.Element => (
  <svg
    version='1.1'
    x='0px'
    y='0px'
    height={height}
    width={width}
    style={{ fill: color }}
    viewBox='0 0 1097 139'
  >
    <style type='text/css'>
      {
        '\n\t.st0{fill:#FFFFFF;}\n\t.st1{fill:#F9F9F9;}\n\t.st2{enable-background:new    ;}\n'
      }
    </style>
    <g>
      <path
        className='st0'
        d='M162.41,105.68h-9.17l-22.1-36.79c-1.25,0.08-3.25,0.12-5.99,0.12h-4.59v36.79h-7.52V17.63h15.16 c8.78,0,15.87,2.16,21.28,6.47c5.64,4.55,8.46,10.86,8.46,18.93c0,13.09-6.19,21.32-18.57,24.69L162.41,105.68z M150.54,43.14 c0-7.21-2.86-12.22-8.58-15.05c-4.55-2.27-11.68-3.41-21.4-3.41v36.56c10.5,0,18.12-1.41,22.86-4.23 C148.17,54.19,150.54,49.56,150.54,43.14z'
      />
      <path
        className='st0'
        d='M224.74,40.85h4.58v64.37h-4.58V92.21c-4.86,9.73-13.16,14.59-24.89,14.59c-8.01,0-14.74-2.76-20.17-8.3 c-5.44-5.53-8.15-12.25-8.15-20.17V40.85h4.58v37.48c0,6.58,2.31,12.3,6.94,17.17c4.62,4.86,10.23,7.3,16.81,7.3 s12.37-2.43,17.38-7.3c5.01-4.86,7.51-10.59,7.51-17.17V40.85H224.74z'
      />
      <path
        className='st0'
        d='M297.91,43.43c4.05-2.77,8.99-4.15,14.81-4.15c5.81,0,10.92,2.1,15.31,6.29c4.38,4.2,6.58,9.87,6.58,17.02 v42.63h-4.58V62.6c0-5.53-1.81-10.13-5.44-13.8c-3.63-3.67-7.99-5.51-13.09-5.51s-9.63,1.91-13.59,5.72s-5.94,8.25-5.94,13.3v42.92 h-4.58V62.31c0-5.15-1.81-9.61-5.44-13.37c-3.63-3.77-8.01-5.65-13.16-5.65s-9.63,1.91-13.45,5.72c-3.82,3.81-5.72,8.25-5.72,13.3 v42.92h-4.58V40.85h4.58v9.01c1.91-2.86,4.74-5.34,8.51-7.44s7.68-3.15,11.73-3.15c4.05,0,7.94,1.41,11.66,4.22 s6.44,6.46,8.15,10.94C291.12,49.87,293.86,46.19,297.91,43.43z'
      />
      <g>
        <g>
          <rect
            x={52.45}
            y={120.13}
            className='st0'
            width={46.77}
            height={7.56}
          />
          <g>
            <path
              className='st0'
              d='M81.59,90.41c-7.84,8.03-17.28,12.06-28.31,12.06c-11.04,0-20.57-4.02-28.6-12.06 c-6.59-6.58-10.48-14.16-11.66-22.73h-6.3c1.24,10.15,5.68,19.03,13.35,26.65c9.14,9.08,20.11,13.62,32.91,13.62 c8.36,0,16.2-2.15,23.51-6.47c7.32-4.31,12.8-10.19,16.46-17.63v21.95h6.26V67.68h-6.27C91.79,76.26,88.01,83.84,81.59,90.41z'
            />
            <path
              className='st0'
              d='M24.68,33.2c8.03-8.16,17.53-12.24,28.5-12.24s20.41,4.05,28.31,12.15c6.78,6.94,10.64,14.96,11.6,24.07 h6.12V17.63h-6.26v22.14c-3.01-7.32-8.33-13.19-15.97-17.64c-7.64-4.43-15.65-6.66-24.01-6.66c-12.8,0-23.77,4.54-32.91,13.62 c-8.03,7.98-12.54,17.35-13.51,28.1h6.34C13.9,48.16,17.82,40.17,24.68,33.2z'
            />
          </g>
        </g>
      </g>
    </g>
    <g>
      <rect x={361.32} y={10.52} className='st1' width={2.48} height={123.97} />
    </g>
    <g>
      <rect x={899.29} y={10.52} className='st1' width={2.48} height={123.97} />
    </g>
    <g>
      <path
        className='st0'
        d='M513.11,17.31c1.08,0.8,2.44,0.72,3.65,1.14c7.4,2.59,10.99,7.57,11.04,15.4c0.11,18.76,0.23,37.52,0.34,56.27 c0.01,2.14,0.16,4.25,0.69,6.34c1.72,6.87,6.12,10.71,13.17,11.47c0.47,0.05,0.95,0.07,1.77,0.14c-3.3,2.89-6.34,5.57-9.38,8.24 c-3.44,3.02-7.04,5.88-10.26,9.13c-2.21,2.23-4.64,2.49-7.39,2.09c-11.77-1.69-19.62-10.03-20.63-21.9 c-0.13-1.5-0.19-3.01-0.19-4.51c-0.01-16.69-0.13-33.38,0.05-50.06c0.08-7.56-2.81-10.72-10.98-10.8c-0.24,0-0.47,0.03-0.85,0.07 c0,28.65,0,57.24,0,85.94c-10.65,0-21.15,0-31.65,0c-0.42-0.75-0.23-1.51-0.23-2.24c-0.05-23.84-0.09-47.68-0.13-71.52 c0-0.69,0-1.38,0-2.07c-0.02-7.73-3.79-11.14-11.85-10.49c0,28.73,0,57.49,0,86.35c-10.81,0-21.39,0-31.96,0 c-0.4-0.61-0.2-1.25-0.2-1.85c-0.01-23.28-0.02-46.55-0.01-69.83c0-1.95-0.13-3.89-0.52-5.8c-1.61-7.78-6.29-11.68-15.1-12.4 c7.33-6.47,14.48-12.79,21.64-19.1c3.64,0,7.28,0,10.92,0c2.01,0.94,4.24,1.22,6.24,2.24c4.18,2.15,7.46,5.04,8.63,9.8 c0.22,0.89,0.31,1.8,0.51,3.04c1.89-1.66,3.59-3.11,5.25-4.6c3.9-3.51,8.03-6.77,11.75-10.47c3.89,0,7.78,0,11.67,0 c0.77,0.61,1.75,0.48,2.62,0.75c5.71,1.75,10.13,4.9,11.89,10.92c0.33,1.15,0.45,2.36,0.69,3.71c3.74-3.28,7.36-6.38,10.89-9.58 c2.11-1.91,4.53-3.51,6.29-5.8C505.33,17.31,509.22,17.31,513.11,17.31z'
      />
      <path
        className='st0'
        d='M605.61,18.73c4.37,0,8.5,0,12.73,0c0,15.91,0,31.75,0,47.71c-2.49,0-4.97,0-7.65,0c0-12.23,0-24.53,0-36.82 c-0.13-0.02-0.27-0.03-0.4-0.05c-3.18,12.26-6.35,24.52-9.56,36.88c-2.24,0-4.47,0-6.92,0c-3.17-12.23-6.36-24.53-9.55-36.84 c-0.1,0.02-0.21,0.03-0.31,0.05c0,12.23,0,24.46,0,36.8c-2.61,0-5.1,0-7.72,0c0-15.83,0-31.67,0-47.66c4.16,0,8.38,0,12.72,0 c2.73,10.33,5.45,20.66,8.31,31.5C600.12,39.48,602.85,29.14,605.61,18.73z'
      />
      <path
        className='st0'
        d='M687.68,78.72c2.72,10.31,5.44,20.62,8.16,30.94c0.09,0.02,0.19,0.03,0.28,0.05 c2.73-10.32,5.45-20.64,8.2-31.03c4.25,0,8.43,0,12.72,0c0,15.83,0,31.68,0,47.65c-2.48,0-5.01,0-7.71,0c0-12.26,0-24.51,0-36.76 c-0.11-0.01-0.22-0.03-0.34-0.04c-3.18,12.27-6.35,24.53-9.54,36.85c-2.29,0-4.53,0-6.91,0c-3.19-12.32-6.39-24.64-9.58-36.96 c-0.1,0.02-0.2,0.04-0.3,0.05c0,12.24,0,24.49,0,36.86c-2.56,0-5.04,0-7.66,0c0-15.8,0-31.64,0-47.61 C679.07,78.72,683.24,78.72,687.68,78.72z'
      />
      <path
        className='st0'
        d='M694.01,66.47c-3.01,0-5.93,0-9.05,0c0-6.8,0-13.6,0-20.5c-4.36,0-8.54,0-12.89,0c0,6.74,0,13.54,0,20.47 c-3.07,0-5.99,0-9.05,0c0-15.83,0-31.72,0-47.7c2.89,0,5.82,0,8.89,0c0,6.25,0,12.48,0,18.84c4.36,0,8.58,0,12.96,0 c0-6.26,0-12.5,0-18.83c3.14,0,6.12,0,9.13,0C694.01,34.7,694.01,50.54,694.01,66.47z'
      />
      <path
        className='st0'
        d='M774.31,94.02c-3.08,0-6.05,0-9.09,0c-0.29-1.38-0.16-2.77-0.59-4.1c-0.88-2.78-2.52-4-5.42-3.9 c-3.21,0.11-4.85,1.41-5.41,4.38c-0.16,0.85-0.15,1.75-0.16,2.62c-0.01,6.33-0.01,12.67,0,19c0,0.63,0,1.26,0.06,1.88 c0.31,3.33,2.06,4.98,5.35,5.07c3.3,0.1,5.45-1.6,5.89-4.77c0.2-1.48,0.16-2.99,0.24-4.66c-2.17,0-4.21,0-6.37,0 c0-2.58,0-4.99,0-7.62c2.57,0,5.12,0,7.67,0s5.11,0,7.75,0c0,8.23,0,16.29,0,24.44c-1.55,0-3.03,0-4.69,0 c-0.42-1.11-0.88-2.32-1.34-3.54c-0.56-0.16-0.75,0.34-1.01,0.63c-3.82,4.21-8.57,4.28-13.51,2.85c-4.84-1.39-7.59-4.86-8.64-9.7 c-0.28-1.28-0.47-2.6-0.48-3.91c-0.05-6.77-0.18-13.54,0.01-20.31c0.32-11.75,8.13-15.53,17.94-14.26 c6.71,0.86,10.58,5.3,11.48,12.44C774.12,91.68,774.2,92.8,774.31,94.02z'
      />
      <path
        className='st0'
        d='M701.15,42.08c0.18-4.11-0.37-8.58,0.5-12.99c1.26-6.44,4.69-9.86,11.24-10.86c4.13-0.63,8.27-0.41,12.06,1.72 c3.89,2.19,5.5,5.82,6.15,10.02c0.53,3.43,0.35,6.88,0.31,10.33c-0.05,4.82,0.29,9.65-0.27,14.47c-0.86,7.44-5.32,11.75-12.77,12.3 c-2.64,0.19-5.27,0.15-7.83-0.61c-5.43-1.61-8.69-5.98-9.19-12.2C701.03,50.32,701.23,46.36,701.15,42.08z M710.35,42.49 c0.01,0,0.01,0,0.02,0c0,3.69-0.07,7.39,0.02,11.08c0.07,2.96,1.27,4.69,3.66,5.22c5.3,1.16,8.08-0.94,8.15-5.77 c0.01-0.5,0.04-1,0.04-1.5c0-5.95,0.01-11.9-0.01-17.84c0-1.06-0.04-2.14-0.21-3.18c-0.27-1.67-0.97-3.08-2.63-3.84 c-4.71-2.15-8.92,0.38-9.02,5.51C710.3,35.6,710.35,39.05,710.35,42.49z'
      />
      <path
        className='st0'
        d='M760.54,18.73c3.13,0,6.06,0,8.96,0c0.43,0.63,0.24,1.28,0.24,1.88c0.01,10.16,0.01,20.32,0.01,30.49 c0,1.82-0.09,3.63-0.46,5.42c-1.16,5.66-4.66,8.96-10.24,10.15c-3.23,0.69-6.48,0.71-9.71,0.04c-6.64-1.38-10.55-5.8-10.71-12.6 c-0.19-8.34-0.09-16.68-0.11-25.03c-0.01-3.38,0-6.76,0-10.29c3.05,0,6.01,0,9.13,0c0,0.68,0,1.36,0,2.03c0,9.91,0,19.82,0,29.73 c0,0.63-0.01,1.25,0.01,1.88c0.13,5.44,4.42,8.22,9.47,6.14c2.31-0.95,3.26-2.88,3.33-5.22c0.1-3.45,0.08-6.9,0.08-10.35 C760.54,34.99,760.54,26.96,760.54,18.73z'
      />
      <path
        className='st0'
        d='M802.56,78.64c3.07,0,5.94,0,8.86,0c0.45,0.35,0.24,0.89,0.24,1.36c0.01,10.66,0.01,21.32,0.01,31.99 c0,1.45-0.11,2.88-0.39,4.3c-1,5.06-3.88,8.45-8.86,9.93c-4.19,1.24-8.42,1.24-12.61,0.06c-5.73-1.62-9.12-5.92-9.27-12.66 c-0.24-11.03-0.09-22.08-0.11-33.11c0-0.56,0-1.11,0-1.8c3.06,0,6.02,0,9.16,0c0,0.71,0,1.38,0,2.05c0,9.91,0,19.82,0,29.73 c0,0.75,0.04,1.5,0.07,2.26c0.17,5.56,3.9,6.76,8.2,6.11c2.47-0.37,3.91-1.97,4.43-4.49c0.25-1.18,0.27-2.36,0.27-3.56 C802.56,100.15,802.56,89.49,802.56,78.64z'
      />
      <path
        className='st0'
        d='M634.91,18.7c4.02,0,7.84,0,11.77,0c3.82,15.86,7.64,31.72,11.5,47.73c-2.94,0-5.72,0-8.72,0 c-0.76-3.22-1.54-6.55-2.33-9.92c-4.23,0-8.42,0-12.67,0c-0.85,3.3-1.69,6.56-2.56,9.92c-2.86,0-5.73,0-8.8,0 C627.02,50.56,630.95,34.7,634.91,18.7z M636.16,48.23c3.11,0,5.97,0,9.01,0c-1.46-6.36-2.89-12.57-4.32-18.78 c-0.12,0.02-0.23,0.05-0.35,0.07C639.07,35.71,637.63,41.9,636.16,48.23z'
      />
      <path
        className='st0'
        d='M843.92,118.31c0,2.86,0,5.41,0,8.06c-8.51,0-16.95,0-25.53,0c0-15.86,0-31.74,0-47.7c8.22,0,16.42,0,24.76,0 c0,2.53,0,5.12,0,7.87c-5.1,0-10.27,0-15.58,0c0,3.74,0,7.32,0,11.08c3.9,0,7.82,0,11.84,0c0,2.76,0,5.35,0,8.09 c-3.91,0-7.83,0-11.86,0c0,4.23,0,8.33,0,12.61C832.96,118.31,838.38,118.31,843.92,118.31z'
      />
      <path
        className='st0'
        d='M637.81,78.65c2.72,0,5.28,0,7.96,0c4.02,11.49,8.03,22.95,12.03,34.42c0.12-0.02,0.24-0.04,0.36-0.06 c0-11.42,0-22.84,0-34.35c1.8,0,3.4,0,5.1,0c0,15.88,0,31.72,0,47.68c-1.78,0-3.57,0-5.48,0c-4.84-13.55-9.69-27.16-14.55-40.77 c-0.12,0.03-0.24,0.05-0.36,0.08c0,13.52,0,27.03,0,40.68c-1.74,0-3.33,0-5.05,0C637.81,110.5,637.81,94.62,637.81,78.65z'
      />
      <path
        className='st0'
        d='M872.92,126.39c-7.89,0-15.7,0-23.6,0c0-15.92,0-31.75,0-47.7c2.95,0,5.87,0,8.95,0c0,13.09,0,26.23,0,39.56 c4.94,0,9.73,0,14.65,0C872.92,121.03,872.92,123.63,872.92,126.39z'
      />
      <path
        className='st0'
        d='M625.03,126.33c-0.49-2.43-0.96-4.76-1.43-7.09c-0.46-2.27-0.92-4.54-1.4-6.92c-4.04,0-8.07,0-12.21,0 c-0.94,4.67-1.89,9.32-2.84,14.04c-1.68,0-3.36,0-5.16,0c3.51-15.93,7-31.79,10.5-47.71c2.38,0,4.68,0,7.21,0 c3.49,15.83,6.98,31.68,10.5,47.67C628.47,126.33,626.83,126.33,625.03,126.33z M621.44,107.76c-1.76-8.51-3.48-16.88-5.21-25.24 c-0.09,0-0.19,0.01-0.28,0.01c-1.72,8.37-3.45,16.74-5.2,25.23C614.41,107.76,617.82,107.76,621.44,107.76z'
      />
      <path
        className='st0'
        d='M573.85,113.67c1.67,0,3.15,0,4.67,0c0.15,0.9,0.28,1.75,0.44,2.6c0.92,4.67,4.53,7.2,9.02,6.36 c4.02-0.75,6.37-4.73,5.5-9.25c-0.39-2.04-1.4-3.72-2.83-5.19c-2.33-2.4-5.19-4.13-7.85-6.1c-2.02-1.49-3.98-3.04-5.56-5.02 c-2.5-3.13-3.33-6.71-2.58-10.59c0.93-4.81,4.48-7.92,9.48-8.52c5.5-0.65,9.98,1.65,12.1,6.28c0.92,2.01,1.35,4.14,1.46,6.42 c-1.51,0.36-3,0.1-4.57,0.17c-0.32-1.27-0.54-2.55-0.95-3.77c-1.35-4.02-5.24-5.83-9.19-4.32c-3.55,1.35-4.93,5.2-3.25,9.09 c0.9,2.09,2.5,3.58,4.28,4.89c2.52,1.86,5.13,3.59,7.61,5.5c4.25,3.27,6.73,7.45,6.64,13.01c-0.13,7.99-5.28,12.41-13.39,11.8 c-5.85-0.45-9.86-4.52-10.73-10.53C574,115.64,573.95,114.76,573.85,113.67z'
      />
      <path
        className='st0'
        d='M735.88,126.42c-2.97,0-5.84,0-8.84,0c0-15.88,0-31.75,0-47.72c2.88,0,5.8,0,8.84,0 C735.88,94.5,735.88,110.37,735.88,126.42z'
      />
    </g>
    <g className='st2'>
      <path
        className='st0'
        d='M936.81,57.24l-0.02,3.29h-16.94V33.2h16.94v3.29h-12.87v8.66h10.36v3.18h-10.36v8.91H936.81z'
      />
      <path
        className='st0'
        d='M944.04,43.87c0.42-0.44,0.86-0.85,1.31-1.22c0.46-0.37,0.94-0.68,1.46-0.94c0.51-0.26,1.07-0.46,1.66-0.61 c0.59-0.15,1.22-0.22,1.89-0.22c1.07,0,2,0.18,2.81,0.53s1.48,0.85,2.03,1.49s0.96,1.41,1.24,2.31c0.28,0.9,0.42,1.9,0.42,2.99 v12.32h-3.73V48.22c0-1.38-0.32-2.46-0.95-3.23c-0.63-0.77-1.61-1.15-2.91-1.15c-0.95,0-1.84,0.23-2.67,0.68 c-0.83,0.45-1.6,1.06-2.31,1.84v14.18h-3.71V41.19h2.23c0.52,0,0.84,0.25,0.97,0.74L944.04,43.87z'
      />
      <path
        className='st0'
        d='M978.23,41.19l-7.84,19.34h-3.35l-7.84-19.34h3.06c0.29,0,0.53,0.07,0.72,0.21c0.19,0.14,0.32,0.32,0.38,0.53 l4.63,11.88c0.17,0.5,0.31,0.99,0.43,1.48c0.12,0.49,0.23,0.97,0.33,1.46c0.1-0.48,0.21-0.96,0.34-1.45s0.28-0.98,0.46-1.49 l4.68-11.88c0.08-0.22,0.21-0.39,0.4-0.53c0.19-0.14,0.42-0.21,0.68-0.21H978.23z'
      />
      <path
        className='st0'
        d='M980.27,43.89c1.08-1.03,2.25-1.8,3.51-2.3c1.26-0.51,2.65-0.76,4.16-0.76c1.1,0,2.08,0.18,2.93,0.54 c0.85,0.36,1.57,0.87,2.15,1.51s1.02,1.42,1.32,2.32c0.3,0.9,0.45,1.9,0.45,2.99v12.33h-1.66c-0.37,0-0.65-0.06-0.84-0.18 c-0.19-0.12-0.34-0.35-0.46-0.68l-0.4-1.69c-0.5,0.46-0.98,0.86-1.47,1.21s-0.99,0.65-1.51,0.89c-0.53,0.25-1.09,0.43-1.68,0.56 c-0.6,0.13-1.26,0.19-1.98,0.19c-0.76,0-1.47-0.1-2.14-0.31c-0.67-0.21-1.25-0.53-1.74-0.96s-0.88-0.97-1.17-1.62 c-0.29-0.65-0.43-1.4-0.43-2.26c0-0.76,0.21-1.5,0.62-2.2s1.08-1.33,2.02-1.89c0.93-0.56,2.15-1.02,3.66-1.37 c1.5-0.35,3.35-0.56,5.55-0.61V48.2c0-1.46-0.31-2.55-0.93-3.27c-0.62-0.72-1.54-1.09-2.74-1.09c-0.81,0-1.49,0.1-2.05,0.3 c-0.55,0.2-1.03,0.43-1.43,0.68c-0.4,0.25-0.75,0.47-1.05,0.68c-0.3,0.2-0.61,0.3-0.92,0.3c-0.25,0-0.47-0.07-0.66-0.21 c-0.18-0.14-0.33-0.3-0.45-0.5L980.27,43.89z M991.16,51.95c-1.51,0.05-2.79,0.17-3.85,0.35c-1.05,0.18-1.91,0.43-2.57,0.73 s-1.14,0.66-1.43,1.08c-0.29,0.41-0.44,0.87-0.44,1.38c0,0.48,0.08,0.9,0.24,1.25c0.16,0.35,0.38,0.63,0.65,0.86 c0.27,0.22,0.59,0.38,0.96,0.49c0.37,0.1,0.77,0.15,1.2,0.15c0.57,0,1.09-0.06,1.57-0.17c0.48-0.11,0.92-0.28,1.34-0.49 s0.82-0.47,1.2-0.77c0.38-0.3,0.75-0.65,1.12-1.05V51.95z'
      />
      <path
        className='st0'
        d='M1011.18,44.52c-0.1,0.17-0.21,0.28-0.32,0.35s-0.26,0.1-0.44,0.1c-0.2,0-0.43-0.07-0.68-0.21 c-0.25-0.14-0.55-0.29-0.89-0.46c-0.35-0.17-0.76-0.32-1.23-0.46s-1.03-0.21-1.67-0.21c-0.53,0-1.02,0.07-1.46,0.2 c-0.44,0.13-0.81,0.32-1.11,0.55c-0.3,0.24-0.54,0.51-0.71,0.84c-0.17,0.32-0.25,0.67-0.25,1.04c0,0.48,0.14,0.88,0.43,1.2 c0.29,0.32,0.66,0.59,1.12,0.82c0.46,0.23,0.99,0.43,1.58,0.62c0.59,0.18,1.19,0.38,1.81,0.58s1.22,0.43,1.81,0.69 c0.59,0.26,1.12,0.58,1.58,0.97c0.46,0.39,0.84,0.85,1.12,1.4c0.29,0.55,0.43,1.21,0.43,2c0,0.9-0.16,1.74-0.49,2.5 c-0.32,0.77-0.8,1.43-1.43,1.99c-0.63,0.56-1.41,1-2.33,1.31c-0.93,0.32-1.99,0.48-3.18,0.48c-1.33,0-2.56-0.22-3.69-0.67 c-1.13-0.44-2.08-1.01-2.84-1.69l0.88-1.43c0.1-0.18,0.23-0.31,0.38-0.41c0.15-0.1,0.36-0.14,0.61-0.14c0.24,0,0.49,0.09,0.74,0.27 s0.56,0.37,0.91,0.58c0.35,0.21,0.79,0.4,1.29,0.57c0.51,0.17,1.14,0.26,1.9,0.26c0.63,0,1.19-0.08,1.66-0.24 c0.47-0.16,0.86-0.37,1.17-0.65c0.31-0.27,0.54-0.59,0.69-0.94c0.15-0.36,0.23-0.73,0.23-1.12c0-0.51-0.14-0.93-0.43-1.27 c-0.29-0.34-0.66-0.62-1.12-0.87c-0.46-0.24-0.99-0.45-1.59-0.63c-0.6-0.18-1.21-0.37-1.83-0.57c-0.62-0.2-1.23-0.44-1.83-0.71 c-0.6-0.27-1.13-0.6-1.59-1.01c-0.46-0.41-0.84-0.9-1.12-1.48s-0.43-1.29-0.43-2.13c0-0.75,0.16-1.47,0.47-2.15 c0.31-0.68,0.76-1.29,1.35-1.81c0.59-0.52,1.32-0.94,2.19-1.25c0.87-0.31,1.86-0.47,2.98-0.47c1.28,0,2.44,0.2,3.47,0.61 s1.92,0.96,2.66,1.67L1011.18,44.52z'
      />
      <path
        className='st0'
        d='M1015.83,43.89c1.08-1.03,2.25-1.8,3.51-2.3c1.26-0.51,2.65-0.76,4.16-0.76c1.1,0,2.08,0.18,2.93,0.54 c0.85,0.36,1.57,0.87,2.15,1.51s1.02,1.42,1.32,2.32c0.3,0.9,0.45,1.9,0.45,2.99v12.33h-1.66c-0.37,0-0.65-0.06-0.84-0.18 c-0.19-0.12-0.34-0.35-0.46-0.68l-0.4-1.69c-0.5,0.46-0.98,0.86-1.47,1.21s-0.99,0.65-1.51,0.89c-0.53,0.25-1.09,0.43-1.68,0.56 c-0.6,0.13-1.26,0.19-1.98,0.19c-0.76,0-1.47-0.1-2.14-0.31c-0.67-0.21-1.25-0.53-1.74-0.96s-0.88-0.97-1.17-1.62 c-0.29-0.65-0.43-1.4-0.43-2.26c0-0.76,0.21-1.5,0.62-2.2s1.08-1.33,2.02-1.89c0.93-0.56,2.15-1.02,3.66-1.37 c1.5-0.35,3.35-0.56,5.55-0.61V48.2c0-1.46-0.31-2.55-0.93-3.27c-0.62-0.72-1.54-1.09-2.74-1.09c-0.81,0-1.49,0.1-2.05,0.3 c-0.55,0.2-1.03,0.43-1.43,0.68c-0.4,0.25-0.75,0.47-1.05,0.68c-0.3,0.2-0.61,0.3-0.92,0.3c-0.25,0-0.47-0.07-0.66-0.21 c-0.18-0.14-0.33-0.3-0.45-0.5L1015.83,43.89z M1026.71,51.95c-1.51,0.05-2.79,0.17-3.85,0.35c-1.05,0.18-1.91,0.43-2.57,0.73 s-1.14,0.66-1.43,1.08c-0.29,0.41-0.44,0.87-0.44,1.38c0,0.48,0.08,0.9,0.24,1.25c0.16,0.35,0.38,0.63,0.65,0.86 c0.27,0.22,0.59,0.38,0.96,0.49c0.37,0.1,0.77,0.15,1.2,0.15c0.57,0,1.09-0.06,1.57-0.17c0.48-0.11,0.92-0.28,1.34-0.49 s0.82-0.47,1.2-0.77c0.38-0.3,0.75-0.65,1.12-1.05V51.95z'
      />
      <path
        className='st0'
        d='M1051.54,32.44v28.1h-2.25c-0.52,0-0.84-0.25-0.97-0.74l-0.32-2.23c-0.83,0.98-1.76,1.76-2.81,2.35 c-1.05,0.59-2.26,0.88-3.63,0.88c-1.1,0-2.1-0.22-3-0.65c-0.9-0.43-1.66-1.06-2.29-1.89c-0.63-0.83-1.12-1.86-1.47-3.09 c-0.34-1.23-0.51-2.65-0.51-4.24c0-1.43,0.19-2.77,0.57-4c0.38-1.23,0.93-2.3,1.66-3.2c0.72-0.9,1.6-1.61,2.63-2.12 s2.19-0.77,3.48-0.77c1.17,0,2.16,0.19,2.98,0.57c0.82,0.38,1.55,0.92,2.2,1.62V32.44H1051.54z M1047.8,45.87 c-0.61-0.8-1.27-1.36-1.97-1.67c-0.71-0.32-1.5-0.48-2.37-0.48c-1.7,0-3.02,0.61-3.95,1.84s-1.4,3.01-1.4,5.36 c0,1.23,0.11,2.28,0.31,3.15c0.21,0.87,0.51,1.58,0.91,2.13c0.4,0.55,0.89,0.96,1.47,1.22c0.58,0.26,1.24,0.39,1.98,0.39 c1.08,0,2.01-0.24,2.8-0.72c0.79-0.48,1.52-1.16,2.21-2.04V45.87z'
      />
      <path
        className='st0'
        d='M1064.97,40.89c1.42,0,2.71,0.23,3.85,0.7c1.15,0.46,2.12,1.13,2.93,2c0.81,0.87,1.42,1.92,1.86,3.14 s0.65,2.6,0.65,4.12c0,1.52-0.22,2.9-0.65,4.13c-0.43,1.23-1.05,2.28-1.86,3.14c-0.81,0.86-1.78,1.53-2.93,1.99 c-1.15,0.46-2.43,0.7-3.85,0.7c-1.43,0-2.73-0.23-3.87-0.7c-1.15-0.46-2.13-1.13-2.94-1.99c-0.81-0.86-1.44-1.91-1.88-3.14 c-0.44-1.23-0.66-2.61-0.66-4.13c0-1.52,0.22-2.9,0.66-4.12c0.44-1.22,1.06-2.27,1.88-3.14c0.81-0.87,1.79-1.54,2.94-2 C1062.25,41.12,1063.54,40.89,1064.97,40.89z M1064.97,57.9c1.83,0,3.19-0.62,4.09-1.85c0.9-1.23,1.35-2.96,1.35-5.2 s-0.45-3.97-1.35-5.21c-0.9-1.24-2.27-1.86-4.09-1.86c-1.85,0-3.23,0.62-4.14,1.86s-1.36,2.97-1.36,5.21s0.45,3.97,1.36,5.2 C1061.74,57.29,1063.12,57.9,1064.97,57.9z'
      />
    </g>
    <g className='st2'>
      <path
        className='st0'
        d='M943.55,106.21h-4.43c-0.5,0-0.9-0.12-1.21-0.35c-0.31-0.23-0.54-0.54-0.69-0.9l-1.87-5.27h-11l-1.87,5.27 c-0.13,0.32-0.35,0.61-0.67,0.87c-0.32,0.26-0.72,0.39-1.2,0.39h-4.47l10.79-27.62h5.84L943.55,106.21z M933.95,95.72l-2.99-8.45 c-0.18-0.44-0.37-0.96-0.56-1.56s-0.38-1.24-0.56-1.94c-0.18,0.71-0.37,1.37-0.56,1.97c-0.2,0.6-0.38,1.13-0.56,1.57l-2.97,8.41 H933.95z'
      />
      <path
        className='st0'
        d='M962.49,101.57v4.64H946.1V78.59h5.71v22.98H962.49z'
      />
      <path
        className='st0'
        d='M991.82,92.39c0,2.02-0.34,3.89-1.02,5.62s-1.64,3.22-2.87,4.47c-1.24,1.26-2.73,2.24-4.46,2.96 c-1.74,0.72-3.67,1.08-5.79,1.08s-4.05-0.36-5.79-1.08c-1.74-0.72-3.23-1.7-4.47-2.96c-1.24-1.26-2.21-2.75-2.88-4.47 s-1.02-3.6-1.02-5.62s0.34-3.89,1.02-5.61c0.68-1.72,1.64-3.21,2.88-4.46c1.24-1.26,2.73-2.24,4.47-2.96 c1.74-0.72,3.67-1.08,5.79-1.08s4.05,0.36,5.79,1.08c1.74,0.72,3.23,1.71,4.46,2.97s2.2,2.75,2.87,4.47S991.82,90.39,991.82,92.39z  M985.98,92.39c0-1.45-0.19-2.74-0.57-3.89s-0.93-2.13-1.65-2.93s-1.59-1.42-2.61-1.85c-1.02-0.42-2.18-0.64-3.47-0.64 c-1.29,0-2.46,0.21-3.48,0.64c-1.03,0.43-1.9,1.04-2.62,1.85s-1.27,1.78-1.65,2.93s-0.57,2.45-0.57,3.89 c0,1.46,0.19,2.76,0.57,3.91c0.38,1.15,0.93,2.12,1.65,2.92c0.72,0.8,1.59,1.41,2.62,1.84c1.03,0.43,2.19,0.64,3.48,0.64 c1.29,0,2.45-0.21,3.47-0.64c1.02-0.42,1.89-1.04,2.61-1.84c0.72-0.8,1.27-1.77,1.65-2.92C985.79,95.15,985.98,93.85,985.98,92.39z '
      />
      <path
        className='st0'
        d='M1019.23,78.59l-11.12,27.62h-5.18l-11.12-27.62h4.61c0.5,0,0.9,0.12,1.21,0.35c0.31,0.23,0.54,0.54,0.69,0.9 l5.98,15.91c0.23,0.6,0.45,1.25,0.68,1.95c0.22,0.7,0.43,1.44,0.62,2.22c0.17-0.77,0.35-1.51,0.54-2.22 c0.2-0.7,0.41-1.35,0.64-1.95l5.94-15.91c0.13-0.32,0.35-0.61,0.68-0.87c0.32-0.26,0.73-0.39,1.21-0.39H1019.23z'
      />
      <path
        className='st0'
        d='M1027.53,83.06v7.1h9.21v4.32h-9.21v7.25h11.84v4.47h-17.59V78.59h17.59v4.47H1027.53z'
      />
      <path
        className='st0'
        d='M1065.66,106.21h-5.16c-0.96,0-1.66-0.37-2.09-1.1l-5.25-8.47c-0.22-0.33-0.45-0.57-0.71-0.72 c-0.26-0.15-0.64-0.23-1.15-0.23h-1.94v10.53h-5.71V78.59h8.68c1.93,0,3.58,0.2,4.95,0.6c1.37,0.4,2.49,0.96,3.37,1.67 s1.52,1.56,1.92,2.54c0.41,0.98,0.61,2.07,0.61,3.25c0,0.91-0.13,1.78-0.39,2.6c-0.26,0.82-0.64,1.56-1.13,2.24 c-0.5,0.67-1.1,1.27-1.83,1.79c-0.72,0.52-1.55,0.94-2.47,1.26c0.37,0.2,0.71,0.45,1.03,0.73c0.32,0.29,0.6,0.63,0.86,1.02 L1065.66,106.21z M1052.28,91.67c0.93,0,1.73-0.12,2.4-0.35c0.67-0.23,1.22-0.56,1.66-0.97c0.43-0.41,0.75-0.9,0.95-1.46 c0.2-0.56,0.3-1.17,0.3-1.83c0-1.31-0.43-2.32-1.3-3.05c-0.87-0.72-2.19-1.08-3.97-1.08h-2.97v8.74H1052.28z'
      />
      <path
        className='st0'
        d='M1092.54,106.21h-4.43c-0.5,0-0.9-0.12-1.21-0.35c-0.31-0.23-0.54-0.54-0.69-0.9l-1.87-5.27h-11l-1.87,5.27 c-0.13,0.32-0.35,0.61-0.67,0.87c-0.32,0.26-0.72,0.39-1.2,0.39h-4.47l10.79-27.62h5.84L1092.54,106.21z M1082.94,95.72l-2.99-8.45 c-0.18-0.44-0.37-0.96-0.56-1.56s-0.38-1.24-0.56-1.94c-0.18,0.71-0.37,1.37-0.56,1.97c-0.2,0.6-0.38,1.13-0.56,1.57l-2.97,8.41 H1082.94z'
      />
    </g>
  </svg>
)

export default Logo
