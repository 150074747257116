import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { ContainedItem } from './dashboardUtils'
import { TraverseArray } from './dashboardTraversals'

interface DashboardConstructorProps {
  components: ContainedItem[]
}

export default function Dashboard ({ components }: DashboardConstructorProps): JSX.Element {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={3}
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <TraverseArray arr={components} />
      </Grid>
    </Box>
  )
}
