import ApisLogoLogin from '../assets/logos/ApisLogoLogin'
import ApisLogo from '../assets/logos/ApisLogo'
import ApisLogoFigueres from '../assets/logos/ApisLogoFigueres'
import ArumLogoLogin from '../assets/logos/ArumLogoLogin'
import ArumLogo from '../assets/logos/ArumLogo'
import ArumLogoMahou from '../assets/logos/ArumLogoMahou'
import { getApplication } from './host'

const appsComponent: { [key: string]: any } = {
  apis: ApisLogoLogin,
  arum: ArumLogoLogin
}

const appsSvg: { [key: string]: any } = {
  apis: ApisLogo,
  'apis-figueres': ApisLogoFigueres,
  arum: ArumLogo,
  'arum-mahou': ArumLogoMahou
}

export const getAppLogoComponent = (): any => {
  const application = getApplication()
  if (application === undefined) {
    return appsComponent.apis // change default some day.
  }
  return appsComponent[application]
}

export const getAppLogo = (applicationName?: string): any => {
  const application = applicationName ?? getApplication()
  if (application === undefined) {
    return appsSvg.apis // change default some day.
  }
  return appsSvg[application]
}
