
// Import utils
import { useEffect } from 'react'
import { map, min, values, keys } from 'lodash'
import { useRecoilCallback, useRecoilValue, useRecoilState } from 'recoil'

// Import auth elements
import { refreshTokensAtom, soilTokensAtom, dashboardsAtom } from './components/authentification/authState'
import { refreshDashboards, refreshSoilTokens } from './components/authentification/authUtils'
import { logoutAction } from './components/authentification/Logout'
import { IRIS_APP_ID } from './components/authentification/authConfig'

// Import Host elements
import { setMetadata } from './components/utils/host'
import { getAppLogoComponent } from './components/utils/appLogo'

// Import components
import IrisLogin from './components/authentification/IrisLogin'
import Main from './components/dashboard/Main'
import { ErrorBoundaryApp } from './components/errors/ErrorBoundary'
import Loading from './components/irisComponents/helpers/Loading'

// Import UI elements
import Box from '@mui/material/Box'

interface LanguageProps { code: string, language: string }

function InnerApp (): JSX.Element {
  const [dashboard, setDashboardAtom] = useRecoilState(dashboardsAtom)
  const refreshTokens = useRecoilValue(refreshTokensAtom)
  const soilTokens = useRecoilValue(soilTokensAtom)

  const refreshSoilTokensCb = useRecoilCallback(({ snapshot, set }) => async () => {
    const currentRefreshTokens = snapshot.getLoadable(refreshTokensAtom)

    // To do: clean up. Switch
    try {
      if (currentRefreshTokens.state === 'hasError') {
        throw currentRefreshTokens.contents
      }
      let contents
      if (currentRefreshTokens.state === 'loading') {
        contents = await currentRefreshTokens.toPromise()
      } else {
        contents = currentRefreshTokens.contents
      }
      if (contents === null) {
        return
      }
      const newTokens = await refreshSoilTokens(contents)
      set(soilTokensAtom, newTokens)
      window.location.reload()
    } catch (e) {
      logoutAction()
    }
  }, [soilTokens])

  useEffect(() => {
    if (soilTokens !== null && soilTokens !== undefined) {
      if ((dashboard == null) || dashboard.length === 0) {
        refreshDashboards(soilTokens[IRIS_APP_ID].token, keys(soilTokens), setDashboardAtom).catch(e => {
          console.error('Error refreshing dashboards', e)
        }).finally(() => {})
      }
      const interval = min(map(values(soilTokens), t => new Date(t.expirationDate).getTime() - new Date().getTime()))

      if (interval === undefined || interval <= 0) {
        refreshSoilTokensCb().catch(e => {
          console.error('Error refreshing token for first time', e)
        }).finally(() => {})
        return
      }
      const intervalID = setInterval(() => {
        refreshSoilTokensCb().catch(e => {
          console.error('Error refreshing token', e)
        }).finally(() => {})
      }, interval)
      return () => {
        clearInterval(intervalID)
      }
    }
  }, [soilTokens, dashboard, refreshSoilTokensCb, setDashboardAtom])

  // Language Props for Login
  const languageProps = {
    acceptedLanguages: [
      { code: 'ca', language: 'Català' },
      { code: 'en', language: 'English' },
      { code: 'es', language: 'Español' },
      { code: 'fr', language: 'Français' }
    ] as LanguageProps[],
    defaultLanguage: 'ca'
  }

  const AppLogo = getAppLogoComponent()
  if (refreshTokens === null) { // || dashboard === null  || dashboard.length === 0
    return <IrisLogin AppLogo={AppLogo} languageProps={languageProps} />
  }

  if (dashboard === null) {
    if (soilTokens !== null && refreshTokens !== null) {
      // TODO: Set time out - Amb components (buscar per internet)
      return <><Loading /></>
    } else {
      logoutAction()
    }
  }

  return (
    <Box height={100} width='100%'>
      <Main />
    </Box>
  )
}

function App (): JSX.Element {
  setMetadata()

  return <ErrorBoundaryApp><InnerApp /></ErrorBoundaryApp>
}

export default App
