import _ from 'lodash'
import moment from 'moment'

// For now only $date() is parsed

const VALID_TIME_UNITS = ['years', 'y', 'quarters', 'Q', 'months', 'M', 'weeks', 'w', 'days', 'd', 'hours', 'h', 'minutes', 'm', 'seconds', 's', 'milliseconds', 'ms']

function parseDate (value: string): string {
  const regex = /\$date\((?<time>(now|'[\d\-:\s]+'))?,?\s*(?<operation>\w+)?,?\s*(?<value>\d+)?,?\s*(?<unit>\w+)?\)/
  const matches = value.match(regex)
  if (matches == null) {
    return value
  }
  let newDate = moment()
  if (matches.groups?.time !== 'now') {
    newDate = moment(matches.groups?.time.slice(1, -1))
  }
  const unit = VALID_TIME_UNITS.includes(matches.groups?.unit ?? '') ? matches.groups?.unit : undefined
  if (matches.groups?.operation === 'subtract') {
    // @ts-expect-error
    newDate = newDate.subtract(Number(matches.groups?.value ?? '0'), unit)
  } else if (matches.groups?.operation === 'add') {
    // @ts-expect-error
    newDate = newDate.add(Number(matches.groups?.value ?? '0'), unit)
  }
  // FIXME WE SHOULD PASS TIMEZONES HERE.
  return newDate.format('YYYY-MM-DD HH:mm:ss')
}

function parseValue (value: string | number): number | string {
  if (!_.isString(value)) {
    return value
  }
  const regex = /\$(date\(.*\))/g
  const matches = value.match(regex)
  if (matches == null) {
    return value
  }
  if (matches[0].startsWith('$date')) {
    return parseDate(value)
  }
  return value
}

type filterValue = string | string[] | number | number[] | { [key: string]: string | string[] | number | number[] }

export function parseValues (value: filterValue): filterValue {
  if (_.isArray(value)) {
    // @ts-expect-error
    return _.map(value, parseValues)
  }
  if (_.isObject(value)) {
    return _.mapValues(value, parseValues) as filterValue
  }
  return parseValue(value)
}
