interface ApisLogoProps {
  width?: number
  height?: number
  color?: string
}

const ApisLogo = ({ width, height, color }: ApisLogoProps): JSX.Element => (
  <svg version='1.1' viewBox='0 0 422.64 137.95' height={height} width={width} style={{ fill: color }}>
    <g>
      <g>
        <path d='M181.12,35.9c0,12.05-3.91,20.33-11.73,24.83c-6.53,3.77-17.24,5.66-32.14,5.66v43.17h-8.83V6.11h17.79
   c10.3,0,18.62,2.53,24.97,7.59C177.8,19.03,181.12,26.43,181.12,35.9z M172.43,36.04c0-8.55-3.96-14.62-11.86-18.21
   c-5.15-2.3-11.5-3.45-19.03-3.45c-1.29,0-2.71,0.05-4.28,0.14v42.76c12.23,0,20.46-0.87,24.69-2.62
   C168.93,51.81,172.43,45.6,172.43,36.04z'
        />
        <path d='M200.97,14.84c-3.28,0-4.92-1.53-4.92-4.58s1.64-4.58,4.92-4.58c3.28,0,4.92,1.53,4.92,4.58S204.25,14.84,200.97,14.84z
    M203.68,33.49v76.13h-5.43V33.49H203.68z'
        />
        <path d='M242.48,35.73c-2.91,0-5.48,0.95-7.72,2.85c-2.24,1.91-3.36,4.25-3.36,7.05s1.26,5.37,3.78,7.72s5.54,4.51,9.06,6.46
   c3.52,1.96,7.08,4.06,10.66,6.3c3.58,2.24,6.63,5.09,9.15,8.56s3.78,7.44,3.78,11.92c0,6.61-2.49,12.23-7.47,16.87
   c-4.98,4.64-11,6.96-18.04,6.96c-4.48,0-8.65-0.98-12.51-2.94s-6.69-4.56-8.48-7.8v-8.06c4.25,9.4,11.08,14.1,20.48,14.1
   c5.82,0,10.71-1.79,14.69-5.37c3.97-3.58,5.96-7.58,5.96-12s-1.26-8.14-3.78-11.16s-5.57-5.59-9.15-7.72s-7.13-4.22-10.66-6.29
   c-3.52-2.07-6.55-4.59-9.06-7.55c-2.52-2.96-3.78-6.43-3.78-10.41c0-3.97,1.59-7.33,4.78-10.07s7.3-4.11,12.34-4.11
   c5.04,0,9.34,1.96,12.93,5.88v6.88C252.49,38.42,247.96,35.73,242.48,35.73z'
        />
        <g>
          <g>
            <rect x='57.17' y='126.38' width='54.88' height='8.88' />
            <g>
              <path d='M91.36,91.51c-9.2,9.43-20.28,14.15-33.22,14.15c-12.96,0-24.14-4.72-33.56-14.15c-7.74-7.73-12.29-16.62-13.68-26.67
      H3.51c1.45,11.91,6.66,22.33,15.66,31.27c10.73,10.66,23.6,15.98,38.62,15.98c9.81,0,19.01-2.53,27.59-7.59
      c8.59-5.06,15.02-11.95,19.32-20.69v25.75h7.35V64.83h-7.35C103.33,74.91,98.89,83.8,91.36,91.51z'
              />
              <path d='M24.58,24.38C34,14.8,45.15,10.02,58.02,10.02c12.88,0,23.95,4.75,33.22,14.26c7.95,8.14,12.48,17.56,13.62,28.25h7.19
      V6.12h-7.35V32.1c-3.53-8.59-9.77-15.48-18.74-20.7C76.99,6.2,67.6,3.59,57.79,3.59c-15.02,0-27.89,5.33-38.62,15.98
      C9.75,28.93,4.46,39.93,3.32,52.54h7.43C11.93,41.94,16.53,32.56,24.58,24.38z'
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <path d='M412.24,74.98v1.14c-1.6,0-2.95,0.55-4.07,1.64c-1.11,1.09-1.67,2.46-1.67,4.12l0.01,8.26l5.78-0.01v1.37l-5.78,0.01
   l0.01,9.26h-1.32l-0.03-18.88c0-2.01,0.68-3.67,2.06-4.96C408.62,75.63,410.29,74.98,412.24,74.98z'
        />
        <path d='M416.79,77.07c-0.8,0-1.2-0.36-1.2-1.1c0-0.73,0.4-1.1,1.2-1.1s1.2,0.37,1.2,1.1C417.99,76.71,417.59,77.07,416.79,77.07z
    M417.46,81.56l0.03,18.31h-1.32l-0.03-18.31H417.46z'
        />
        <path d='M327.51,109.32l0.01,8.38h-0.6v-2.09c-0.35,0.71-0.88,1.27-1.58,1.68s-1.46,0.62-2.26,0.62c-1.23,0-2.29-0.43-3.17-1.29
   c-0.88-0.86-1.32-1.89-1.33-3.1c0-1.2,0.44-2.24,1.32-3.1c0.88-0.86,1.93-1.3,3.17-1.3c0.8,0,1.57,0.21,2.31,0.63
   s1.25,0.98,1.54,1.67v-2.1H327.51z M319.18,113.55c0,1.04,0.39,1.94,1.16,2.71c0.77,0.76,1.69,1.14,2.76,1.14
   c1.06,0,1.97-0.39,2.72-1.15c0.75-0.76,1.13-1.67,1.13-2.72s-0.38-1.96-1.14-2.73c-0.76-0.77-1.67-1.15-2.73-1.15
   s-1.97,0.39-2.74,1.17C319.57,111.59,319.18,112.5,319.18,113.55z'
        />
        <path d='M334.16,117.7h-0.6l-0.01-8.38h0.6v1.7c0.64-1.27,1.73-1.9,3.28-1.9c1.06,0,1.94,0.36,2.66,1.08s1.08,1.59,1.08,2.62
   l0.01,4.88h-0.6l-0.01-4.88c0-0.86-0.31-1.6-0.92-2.23s-1.35-0.95-2.22-0.95s-1.63,0.32-2.29,0.95s-0.99,1.38-0.99,2.24
   L334.16,117.7z'
        />
        <path d='M355.66,109.28l0.01,8.38h-0.6v-2.08c-0.35,0.71-0.88,1.27-1.58,1.68s-1.46,0.62-2.26,0.62c-1.23,0-2.29-0.43-3.17-1.29
   c-0.88-0.86-1.32-1.89-1.32-3.1c0-1.2,0.44-2.24,1.32-3.1s1.93-1.3,3.17-1.3c0.8,0,1.57,0.21,2.31,0.63s1.25,0.98,1.54,1.67v-2.1
   L355.66,109.28z M347.33,113.5c0,1.04,0.39,1.94,1.16,2.71c0.77,0.76,1.69,1.14,2.76,1.14c1.06,0,1.97-0.39,2.72-1.15
   c0.75-0.76,1.13-1.67,1.13-2.72s-0.38-1.96-1.14-2.73c-0.76-0.77-1.67-1.15-2.73-1.15s-1.97,0.39-2.74,1.17
   C347.72,111.55,347.33,112.46,347.33,113.5z'
        />
        <path d='M364.93,117.29v0.52c-0.89,0-1.65-0.29-2.28-0.88s-0.95-1.34-0.95-2.26l-0.02-10.99h0.6l0.02,10.99
   c0,0.76,0.26,1.39,0.77,1.88C363.59,117.05,364.21,117.3,364.93,117.29z'
        />
        <path d='M377.03,109.25h0.6l0.01,8.12c0,1.1-0.39,2.04-1.17,2.81c-0.78,0.77-1.73,1.16-2.87,1.16c-1.36,0-2.43-0.51-3.23-1.54
   l0.04-0.93c0.35,0.6,0.82,1.07,1.39,1.42s1.17,0.53,1.8,0.53c0.93,0,1.73-0.33,2.41-1s1.02-1.46,1.01-2.39v-1.41
   c-0.61,1.22-1.69,1.83-3.22,1.83c-1.04,0-1.94-0.36-2.68-1.09s-1.12-1.6-1.12-2.61l-0.01-4.88h0.6l0.01,4.88
   c0,0.86,0.32,1.6,0.96,2.23s1.39,0.95,2.26,0.95s1.62-0.32,2.25-0.95s0.95-1.38,0.95-2.24L377.03,109.25z'
        />
        <path d='M386.52,117.21v0.52c-0.89,0-1.65-0.29-2.28-0.88s-0.94-1.34-0.95-2.26l-0.01-8.71h0.6v3.39h2.6v0.52h-2.6l0.01,4.8
   c0,0.76,0.26,1.38,0.77,1.88C385.18,116.96,385.8,117.21,386.52,117.21z'
        />
        <path d='M392.34,107.2c-0.36,0-0.55-0.17-0.55-0.5c0-0.34,0.18-0.5,0.55-0.5c0.36,0,0.55,0.17,0.55,0.5
   C392.88,107.03,392.7,107.2,392.34,107.2z M392.64,109.25l0.01,8.36h-0.6l-0.01-8.36H392.64z'
        />
        <path d='M403.04,117.84h-0.17c-1.21,0-2.24-0.44-3.11-1.32s-1.3-1.91-1.31-3.1c0-1.18,0.44-2.21,1.32-3.07
   c0.88-0.86,1.96-1.3,3.24-1.3c1.28,0,2.38,0.47,3.28,1.43v0.95c-0.39-0.57-0.89-1.02-1.48-1.36c-0.6-0.33-1.22-0.5-1.88-0.5
   c-1.04,0-1.95,0.39-2.72,1.15c-0.77,0.76-1.16,1.67-1.15,2.73c0,1.06,0.39,1.96,1.16,2.73c0.77,0.76,1.68,1.14,2.73,1.14
   c0.65,0,1.28-0.17,1.87-0.51c0.6-0.34,1.09-0.8,1.48-1.38v0.97C405.42,117.36,404.34,117.83,403.04,117.84z'
        />
        <path d='M413.52,109.5c-0.33,0-0.62,0.11-0.87,0.32c-0.25,0.21-0.38,0.47-0.38,0.78s0.14,0.6,0.43,0.86
   c0.28,0.26,0.62,0.5,1.02,0.71c0.4,0.22,0.8,0.45,1.2,0.7s0.75,0.56,1.03,0.95s0.43,0.83,0.43,1.32c0,0.73-0.28,1.36-0.84,1.87
   c-0.56,0.52-1.23,0.77-2.03,0.78c-0.5,0-0.97-0.11-1.4-0.32c-0.43-0.22-0.75-0.5-0.95-0.86v-0.89c0.48,1.04,1.25,1.56,2.3,1.56
   c0.65,0,1.2-0.2,1.65-0.6s0.67-0.84,0.67-1.33s-0.14-0.9-0.43-1.24c-0.28-0.33-0.63-0.62-1.03-0.86s-0.8-0.47-1.2-0.7
   s-0.74-0.51-1.02-0.84s-0.42-0.71-0.43-1.15c0-0.44,0.18-0.81,0.54-1.12c0.36-0.3,0.82-0.46,1.39-0.46s1.05,0.21,1.45,0.65v0.76
   C414.64,109.79,414.13,109.5,413.52,109.5z'
        />
        <path d='M418.52,102.38l-103.2,0.15v1.06l103.2-0.15V102.38z' />
        <path d='M412.36,89.64l-7.15,0.01v2.13l7.15-0.01V89.64z' />
        <path d='M319.86,84.9c1.69-1.7,3.69-2.55,6-2.55s4.3,0.83,5.97,2.51c1.4,1.41,2.21,2.97,2.44,4.88h1.29l-0.01-8.08h-1.29l0.01,4.6
   c-0.65-1.52-1.76-2.74-3.38-3.67c-1.61-0.92-3.3-1.38-5.07-1.38c-2.7,0-5.01,0.94-6.93,2.84c-1.66,1.63-2.6,3.38-2.83,5.73h1.34
   C317.62,87.85,318.44,86.32,319.86,84.9z'
        />
        <path d='M331.87,96.79c-1.65,1.67-3.64,2.52-5.96,2.52c-2.33,0-4.34-0.84-6.03-2.51c-1.44-1.41-2.26-3.21-2.49-4.91h-1.33
   c0.23,2.13,1.18,4.1,2.84,5.73c1.93,1.89,4.24,2.83,6.94,2.83c1.76,0,3.42-0.45,4.96-1.35c1.54-0.9,2.61-2.12,3.47-3.67l0.01,4.53
   h1.29l-0.01-8.08h-1.28C334.06,93.57,333.27,95.37,331.87,96.79z'
        />
        <path d='M341.8,87.74c0-1.44,0.57-2.7,1.67-3.8c1.1-1.09,2.4-1.63,3.89-1.64c1.49,0,2.67,0.53,3.72,1.61
   c1.05,1.07,1.49,2.34,1.49,3.81v1.98h1.51v-1.98c0-1.44,0.56-2.7,1.7-3.8c1.14-1.09,2.45-1.63,3.92-1.64
   c1.47,0,2.74,0.52,3.79,1.57s1.58,2.36,1.58,3.93v1.9h1.29v-1.9c0-2.04-0.63-3.65-1.9-4.85c-1.27-1.19-2.74-1.79-4.42-1.79
   s-3.1,0.4-4.27,1.19c-1.17,0.79-1.96,1.84-2.37,3.14c-0.5-1.28-1.28-2.31-2.36-3.12c-1.08-0.8-2.2-1.2-3.37-1.2
   s-2.32,0.3-3.41,0.9c-1.09,0.6-2.05,1.31-2.48,2.13v-2.56h-1.29l0.01,8.08h1.29L341.8,87.74z'
        />
        <path d='M366.37,91.82h-1.29l0.01,8.08h1.29L366.37,91.82z' />
        <path d='M341.81,91.85h-1.29l0.01,8.08h1.29L341.81,91.85z' />
        <path d='M354.09,91.84h-1.51l0.01,8.08h1.51L354.09,91.84z' />
        <path d='M374.16,84.82c1.69-1.7,3.69-2.55,6-2.55s4.3,0.83,5.97,2.51c1.4,1.41,2.21,2.97,2.44,4.88h1.27l-0.01-8.08h-1.29
   l0.01,4.6c-0.65-1.52-1.76-2.74-3.37-3.67c-1.61-0.92-3.3-1.38-5.06-1.38c-2.7,0-5.01,0.94-6.93,2.84
   c-1.66,1.63-2.6,3.38-2.82,5.73h1.34C371.93,87.77,372.75,86.24,374.16,84.82z'
        />
        <path d='M386.18,96.71c-1.65,1.67-3.64,2.52-5.97,2.52c-2.33,0-4.34-0.84-6.03-2.51c-1.44-1.41-2.26-3.21-2.49-4.91h-1.33
   c0.23,2.13,1.18,4.1,2.84,5.73c1.93,1.89,4.24,2.83,6.94,2.83c1.76,0,3.41-0.45,4.95-1.35c1.54-0.9,2.6-2.12,3.46-3.67l0.01,4.53
   h1.29l-0.01-8.08h-1.26C388.37,93.49,387.57,95.29,386.18,96.71z'
        />
        <path d='M396.07,69.23h-1.29l0.03,20.42h1.29L396.07,69.23z' />
        <path d='M394.81,91.78v1.6c0,2.01,0.72,3.66,2.1,4.95s2.87,1.93,5.02,1.93v-1.14c-1.72,0-2.99-0.54-4.11-1.63
   c-1.12-1.09-1.71-2.46-1.72-4.12v-1.6L394.81,91.78z'
        />
        <path d='M279.13,101.65V81.18h2.52v8.42c0.59-0.68,1.27-1.22,2.03-1.63c0.77-0.42,1.65-0.63,2.63-0.63c0.83,0,1.57,0.15,2.24,0.46
   c0.67,0.31,1.23,0.76,1.7,1.38c0.47,0.6,0.83,1.35,1.08,2.25c0.25,0.89,0.38,1.92,0.38,3.09c0,1.04-0.14,2.01-0.42,2.9
   c-0.28,0.89-0.69,1.66-1.22,2.32c-0.53,0.65-1.17,1.16-1.94,1.54c-0.76,0.37-1.62,0.56-2.58,0.56c-0.92,0-1.7-0.18-2.35-0.53
   c-0.64-0.35-1.2-0.84-1.67-1.47l-0.13,1.28c-0.08,0.35-0.29,0.53-0.65,0.53L279.13,101.65L279.13,101.65z M285.49,89.32
   c-0.82,0-1.53,0.19-2.15,0.56c-0.61,0.37-1.17,0.89-1.69,1.57v6.81c0.45,0.61,0.95,1.04,1.49,1.29c0.55,0.25,1.17,0.38,1.84,0.38
   c1.33,0,2.35-0.47,3.07-1.4c0.71-0.94,1.07-2.27,1.07-4c0-0.92-0.08-1.7-0.25-2.36c-0.16-0.66-0.39-1.2-0.7-1.61
   c-0.31-0.43-0.69-0.74-1.14-0.93S286.06,89.32,285.49,89.32z'
        />
        <path d='M298.59,105.8c-0.08,0.19-0.19,0.33-0.32,0.44c-0.12,0.11-0.31,0.17-0.58,0.17h-1.86l2.6-5.59l-5.88-13.26h2.17
   c0.22,0,0.38,0.06,0.51,0.17c0.12,0.1,0.21,0.22,0.27,0.35l3.81,8.87c0.08,0.2,0.15,0.41,0.21,0.61c0.07,0.2,0.12,0.41,0.17,0.63
   c0.07-0.21,0.13-0.42,0.2-0.63c0.07-0.2,0.14-0.41,0.23-0.63l3.7-8.85c0.06-0.15,0.15-0.27,0.28-0.36
   c0.14-0.1,0.29-0.15,0.45-0.15h2L298.59,105.8z'
        />
      </g>
    </g>
  </svg>
)

export default ApisLogo
