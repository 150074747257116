import { AmalfiTable } from '@amalfi-analytics/components'
import { omit } from 'lodash'
import Skeleton from '@mui/material/Skeleton'
import useDictionary from '../../utils/useDictionary'
import useData from '../../utils/useData'
import { IrisTableType } from '../../types/componentTypes'

const IrisTable = ({ element }: { element: IrisTableType }): JSX.Element => {
  const { dictName, props, applicationID } = element
  const { data } = useData(element)
  const { searchInDictionary, loading } = useDictionary(dictName, applicationID)
  if (props === undefined || props.columns === undefined) {
    throw new Error('IrisTable expects columns to be defined')
  }
  const translatedColumns = props.columns.map(col => ({ ...col, headerName: searchInDictionary(col.headerName) }))
  if (data === undefined || loading) {
    return <Skeleton variant='rectangular' width='96%' height={400} style={{ margin: 10 }} />
  }
  const rows = data.map((r: object, i: number) => ({ ...r, id: i }))
  return (
    <AmalfiTable rows={rows} columns={translatedColumns} {...omit(props, ['columns'])} />
  )
}

export default IrisTable
