import { selectorFamily, atomFamily, atom } from 'recoil'
import { parseValues } from './valueParser'
import { filterObject } from '../types/types'

export const filtersDefault = atom<{ [filterId: string]: filterObject }>({
  key: 'Filters/Defaults',
  default: {}
})

export const filtersFamilyAtom = atomFamily<filterObject, string>({
  key: 'FiltersAtom',
  default: selectorFamily<filterObject, string>({
    key: 'Filters/Default',
    get: (filterId: string) => ({ get }) => {
      const res = get(filtersDefault)[filterId]
      return res
    }
  })
})

// Updating this atom forces the filtersFamilySelector to be updated
export const updateFiltersAtom = atom<number>({
  key: 'updater',
  default: 0
})

export const filtersFamilySelector = selectorFamily({
  key: 'Filters',
  get: (filterId: string) => ({ get }) => {
    get(updateFiltersAtom)
    const filterValue = get(filtersFamilyAtom(filterId)) as (filterObject | undefined)
    if (filterValue?.value !== undefined) {
      return { ...filterValue, value: parseValues(filterValue.value) }
    }
    return filterValue
  }
})

export const multiFiltersFamilySelectorRaw = selectorFamily({
  key: 'Filters',
  get: (filterIds: string[]) => ({ get }) => {
    return filterIds.map(filterId => (
      get(filtersFamilyAtom(filterId)) as (filterObject | undefined)
    ))
  }
})

// IMPORTNANT //
// NOTE THAT WITH THIS IMPLEMENTATION AND EMPTY LIST MEANS ALL //

const filters2filtersIris = (filter?: filterObject): {
  variable: string | undefined
  value: string | number | string[] | number[] | {
    [key: string]: string | number | string[] | number[]
  } | undefined
  operator: string | undefined
} | null => {
  if (filter === undefined) {
    return null
  }
  // const value = (filter.fn === 'list' || filter.fn === 'list-deactivated-default') ? filter["values"] : filter["value"]
  const value = filter.value
  return {
    variable: filter.variable,
    value,
    operator: filter.operator
  }
}

const TYPE = 'iris'
const EMPTY = { TYPE, filters: [] }

export const queriesFamily = selectorFamily({
  key: 'Queries',
  get: (filterIds: string[]) => ({ get }) => {
    if (filterIds.length === 0) {
      return EMPTY
    }

    // Ideally this will work as this. However, this may not be the case due to time loading filters.
    const filtersValues = filterIds.map(filterId => get(filtersFamilySelector(filterId)))
    const filtersIris = filtersValues.filter(filter => filter !== undefined).map((filter: filterObject | undefined) => filters2filtersIris(filter))
    return (filtersIris.length > 0) ? { TYPE, filters: filtersIris } : EMPTY
  }
})
