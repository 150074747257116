import useDictionary from '../../utils/useDictionary'
import { IrisTitleType } from '../../types/componentTypes'
import Title from '../../inConstruction/Title'

const IrisTitle = ({ element }: { element: IrisTitleType }): JSX.Element => {
  const { loading, searchInDictionary } = useDictionary(element.dictName, element.applicationID)
  if (loading) {
    return <></>
  }
  const subtitle = searchInDictionary(element?.props?.subtitle)
  const extra = searchInDictionary(element?.props?.extra)
  const label = searchInDictionary(element?.props?.label)
  return <Title label={label} subtitle={subtitle.length === 0 ? undefined : subtitle} extra={extra.length === 0 ? undefined : extra} />
}

export default IrisTitle
