import React from 'react'
import { logoutAction } from '../authentification/Logout'
import Catch from './functional-error-boundary'

interface props {
  // TO DO FIX ANY
  children: any
}

export const ErrorBoundaryApp = Catch(function MyErrorBoundary (props: props, error?: Error) {
  if (error != null) {
    logoutAction()
    return (
      <div className='error-screen'>
        <h2>An error has occured:</h2>
        <h4>{error.message}</h4>
      </div>
    )
  } else {
    return <>{props.children}</>
  }
})

const ErrorBoundary = Catch(function MyErrorBoundary (props: props, error?: Error) {
  if (error != null) {
    return (
      <div className='error-screen'>
        <h2>An error has occured:</h2>
        <h4>{error.message}</h4>
      </div>
    )
  } else {
    return <>{props.children}</>
  }
})

export default ErrorBoundary
