interface ApisLogoProps {
  width?: number
  height?: number
  color?: string
}

const Logo = ({ width, height, color }: ApisLogoProps): JSX.Element => (
  <svg viewBox='0 0 647.7 140.9' height={height} width={width} style={{ fill: color }}>
    <g>
      <g>
        <g>
          <path d='M195.8,40.3c0,10.8-3.5,18.3-10.6,22.3c-5.9,3.4-15.5,5.1-28.9,5.1v38.9h-7.9V13.5h16
        c9.3,0,16.8,2.3,22.5,6.8C192.8,25.1,195.8,31.8,195.8,40.3z M188,40.4c0-7.7-3.6-13.2-10.7-16.4c-4.6-2.1-10.3-3.1-17.1-3.1
        c-1.2,0-2.4,0-3.8,0.1v38.5c11,0,18.4-0.8,22.2-2.4C184.8,54.6,188,49,188,40.4z'
          />
          <path d='M213.7,21.3c-3,0-4.4-1.4-4.4-4.1s1.5-4.1,4.4-4.1c2.9,0,4.4,1.4,4.4,4.1S216.6,21.3,213.7,21.3z
         M216.1,38.1v68.5h-4.9V38.1H216.1z'
          />
          <path d='M251,40.1c-2.6,0-4.9,0.9-6.9,2.6c-2,1.7-3,3.8-3,6.3c0,2.5,1.1,4.8,3.4,6.9c2.3,2.1,5,4.1,8.2,5.8
        c3.2,1.8,6.4,3.7,9.6,5.7c3.2,2,6,4.6,8.2,7.7c2.3,3.1,3.4,6.7,3.4,10.7c0,5.9-2.2,11-6.7,15.2c-4.5,4.2-9.9,6.3-16.2,6.3
        c-4,0-7.8-0.9-11.3-2.6c-3.5-1.8-6-4.1-7.6-7v-7.3c3.8,8.5,10,12.7,18.4,12.7c5.2,0,9.6-1.6,13.2-4.8c3.6-3.2,5.4-6.8,5.4-10.8
        c0-4-1.1-7.3-3.4-10c-2.3-2.7-5-5-8.2-6.9c-3.2-1.9-6.4-3.8-9.6-5.7c-3.2-1.9-5.9-4.1-8.2-6.8c-2.3-2.7-3.4-5.8-3.4-9.4
        c0-3.6,1.4-6.6,4.3-9.1c2.9-2.5,6.6-3.7,11.1-3.7c4.5,0,8.4,1.8,11.6,5.3v6.2C260.1,42.5,256,40.1,251,40.1z'
          />
          <g>
            <g>
              <rect x='84.2' y='121.7' width='49.4' height='8' />
              <g>
                <path d='M115,90.3c-8.3,8.5-18.2,12.7-29.9,12.7c-11.7,0-21.7-4.2-30.2-12.7c-7-7-11.1-15-12.3-24H36
              c1.3,10.7,6,20.1,14.1,28.1c9.7,9.6,21.2,14.4,34.8,14.4c8.8,0,17.1-2.3,24.8-6.8c7.7-4.6,13.5-10.8,17.4-18.6v23.2h6.6V66.3
              H127C125.8,75.4,121.8,83.4,115,90.3z'
                />
                <path d='M54.9,29.9C63.4,21.3,73.4,17,85,17c11.6,0,21.6,4.3,29.9,12.8c7.2,7.3,11.2,15.8,12.3,25.4h6.5V13.5H127
              v23.4c-3.2-7.7-8.8-13.9-16.9-18.6c-8.1-4.7-16.5-7-25.4-7c-13.5,0-25.1,4.8-34.8,14.4C41.6,34,36.8,43.9,35.8,55.2h6.7
              C43.5,45.7,47.7,37.3,54.9,29.9z'
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <path d='M403.8,75.4l0,1c-1.4,0-2.7,0.5-3.7,1.5c-1,1-1.5,2.2-1.5,3.7l0,7.4l5.2,0l0,1.2l-5.2,0l0,8.3l-1.2,0l0-17
        c0-1.8,0.6-3.3,1.9-4.5C400.6,76,402.1,75.4,403.8,75.4z'
          />
          <path d='M407.9,77.3c-0.7,0-1.1-0.3-1.1-1c0-0.7,0.4-1,1.1-1c0.7,0,1.1,0.3,1.1,1C409,77,408.6,77.3,407.9,77.3z
         M408.5,81.4l0,16.5l-1.2,0l0-16.5L408.5,81.4z'
          />
          <path d='M327.6,106.4l0,7.5H327l0-1.9c-0.3,0.6-0.8,1.1-1.4,1.5c-0.6,0.4-1.3,0.6-2,0.6c-1.1,0-2.1-0.4-2.9-1.2
        c-0.8-0.8-1.2-1.7-1.2-2.8c0-1.1,0.4-2,1.2-2.8c0.8-0.8,1.7-1.2,2.9-1.2c0.7,0,1.4,0.2,2.1,0.6c0.7,0.4,1.1,0.9,1.4,1.5l0-1.9
        L327.6,106.4z M320.1,110.2c0,0.9,0.4,1.8,1,2.4c0.7,0.7,1.5,1,2.5,1c1,0,1.8-0.3,2.5-1c0.7-0.7,1-1.5,1-2.4c0-0.9-0.3-1.8-1-2.5
        c-0.7-0.7-1.5-1-2.5-1c-1,0-1.8,0.4-2.5,1.1C320.4,108.4,320.1,109.2,320.1,110.2z'
          />
          <path d='M333.6,113.9H333l0-7.5h0.5l0,1.5c0.6-1.1,1.6-1.7,3-1.7c1,0,1.7,0.3,2.4,1c0.6,0.6,1,1.4,1,2.4l0,4.4h-0.5
        l0-4.4c0-0.8-0.3-1.4-0.8-2c-0.6-0.6-1.2-0.9-2-0.9c-0.8,0-1.5,0.3-2.1,0.9c-0.6,0.6-0.9,1.2-0.9,2L333.6,113.9z'
          />
          <path d='M352.9,106.3l0,7.5h-0.5l0-1.9c-0.3,0.6-0.8,1.1-1.4,1.5c-0.6,0.4-1.3,0.6-2,0.6c-1.1,0-2.1-0.4-2.9-1.2
        c-0.8-0.8-1.2-1.7-1.2-2.8c0-1.1,0.4-2,1.2-2.8s1.7-1.2,2.9-1.2c0.7,0,1.4,0.2,2.1,0.6c0.7,0.4,1.1,0.9,1.4,1.5l0-1.9
        L352.9,106.3z M345.4,110.1c0,0.9,0.4,1.8,1,2.4c0.7,0.7,1.5,1,2.5,1c1,0,1.8-0.3,2.5-1c0.7-0.7,1-1.5,1-2.4c0-0.9-0.3-1.8-1-2.5
        c-0.7-0.7-1.5-1-2.5-1c-1,0-1.8,0.4-2.5,1.1C345.8,108.4,345.4,109.2,345.4,110.1z'
          />
          <path d='M361.3,113.5v0.5c-0.8,0-1.5-0.3-2.1-0.8c-0.6-0.5-0.9-1.2-0.9-2l0-9.9h0.5l0,9.9c0,0.7,0.2,1.2,0.7,1.7
        C360,113.3,360.6,113.5,361.3,113.5z'
          />
          <path d='M372.1,106.3h0.5l0,7.3c0,1-0.3,1.8-1,2.5c-0.7,0.7-1.6,1-2.6,1c-1.2,0-2.2-0.5-2.9-1.4l0-0.8
        c0.3,0.5,0.7,1,1.2,1.3c0.5,0.3,1.1,0.5,1.6,0.5c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2l0-1.3c-0.6,1.1-1.5,1.6-2.9,1.6
        c-0.9,0-1.7-0.3-2.4-1c-0.7-0.7-1-1.4-1-2.4l0-4.4h0.5l0,4.4c0,0.8,0.3,1.4,0.9,2c0.6,0.6,1.2,0.9,2,0.9c0.8,0,1.5-0.3,2-0.9
        c0.6-0.6,0.9-1.2,0.9-2L372.1,106.3z'
          />
          <path d='M380.7,113.5v0.5c-0.8,0-1.5-0.3-2.1-0.8c-0.6-0.5-0.8-1.2-0.9-2l0-7.8h0.5l0,3.1l2.3,0v0.5l-2.3,0l0,4.3
        c0,0.7,0.2,1.2,0.7,1.7C379.5,113.2,380,113.5,380.7,113.5z'
          />
          <path d='M385.9,104.4c-0.3,0-0.5-0.1-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.1,0.5,0.5
        C386.4,104.3,386.2,104.4,385.9,104.4z M386.2,106.3l0,7.5h-0.5l0-7.5H386.2z'
          />
          <path d='M395.6,114h-0.2c-1.1,0-2-0.4-2.8-1.2c-0.8-0.8-1.2-1.7-1.2-2.8c0-1.1,0.4-2,1.2-2.8
        c0.8-0.8,1.8-1.2,2.9-1.2c1.2,0,2.1,0.4,3,1.3l0,0.9c-0.4-0.5-0.8-0.9-1.3-1.2c-0.5-0.3-1.1-0.4-1.7-0.4c-0.9,0-1.8,0.3-2.5,1
        c-0.7,0.7-1,1.5-1,2.5c0,0.9,0.4,1.8,1,2.5c0.7,0.7,1.5,1,2.5,1c0.6,0,1.1-0.2,1.7-0.5c0.5-0.3,1-0.7,1.3-1.2l0,0.9
        C397.7,113.6,396.7,114,395.6,114z'
          />
          <path d='M405,106.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.4,0.8c0.3,0.2,0.6,0.4,0.9,0.6
        c0.4,0.2,0.7,0.4,1.1,0.6c0.4,0.2,0.7,0.5,0.9,0.9c0.3,0.3,0.4,0.7,0.4,1.2c0,0.7-0.3,1.2-0.8,1.7c-0.5,0.5-1.1,0.7-1.8,0.7
        c-0.5,0-0.9-0.1-1.3-0.3s-0.7-0.5-0.9-0.8l0-0.8c0.4,0.9,1.1,1.4,2.1,1.4c0.6,0,1.1-0.2,1.5-0.5c0.4-0.4,0.6-0.8,0.6-1.2
        s-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.6-0.9-0.8c-0.4-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-0.7-0.5-0.9-0.8c-0.3-0.3-0.4-0.6-0.4-1
        c0-0.4,0.2-0.7,0.5-1c0.3-0.3,0.7-0.4,1.2-0.4c0.5,0,0.9,0.2,1.3,0.6v0.7C406,106.8,405.5,106.5,405,106.5z'
          />
          <path d='M409.5,100.1l-92.9,0.1l0,1l92.9-0.1L409.5,100.1z' />
          <path d='M403.9,88.6l-6.4,0l0,1.9l6.4,0L403.9,88.6z' />
          <path d='M320.7,84.4c1.5-1.5,3.3-2.3,5.4-2.3c2.1,0,3.9,0.7,5.4,2.3c1.3,1.3,2,2.7,2.2,4.4l1.2,0l0-7.3l-1.2,0l0,4.1
        c-0.6-1.4-1.6-2.5-3-3.3c-1.5-0.8-3-1.2-4.6-1.2c-2.4,0-4.5,0.9-6.2,2.6c-1.5,1.5-2.3,3-2.5,5.2l1.2,0
        C318.7,87,319.4,85.7,320.7,84.4z'
          />
          <path d='M331.5,95.1c-1.5,1.5-3.3,2.3-5.4,2.3c-2.1,0-3.9-0.8-5.4-2.3c-1.3-1.3-2-2.9-2.2-4.4l-1.2,0
        c0.2,1.9,1.1,3.7,2.6,5.2c1.7,1.7,3.8,2.6,6.2,2.5c1.6,0,3.1-0.4,4.5-1.2c1.4-0.8,2.4-1.9,3.1-3.3l0,4.1l1.2,0l0-7.3l-1.2,0
        C333.5,92.2,332.7,93.8,331.5,95.1z'
          />
          <path d='M340.4,86.9c0-1.3,0.5-2.4,1.5-3.4c1-1,2.2-1.5,3.5-1.5c1.3,0,2.4,0.5,3.3,1.4c0.9,1,1.3,2.1,1.3,3.4l0,1.8
        l1.4,0l0-1.8c0-1.3,0.5-2.4,1.5-3.4c1-1,2.2-1.5,3.5-1.5c1.3,0,2.5,0.5,3.4,1.4c0.9,0.9,1.4,2.1,1.4,3.5l0,1.7l1.2,0l0-1.7
        c0-1.8-0.6-3.3-1.7-4.4c-1.1-1.1-2.5-1.6-4-1.6c-1.5,0-2.8,0.4-3.8,1.1c-1.1,0.7-1.8,1.7-2.1,2.8c-0.4-1.1-1.2-2.1-2.1-2.8
        c-1-0.7-2-1.1-3-1.1c-1.1,0-2.1,0.3-3.1,0.8c-1,0.5-1.8,1.2-2.2,1.9l0-2.3l-1.2,0l0,7.3l1.2,0L340.4,86.9z'
          />
          <path d='M362.5,90.6l-1.2,0l0,7.3l1.2,0L362.5,90.6z' />
          <path d='M340.4,90.6l-1.2,0l0,7.3l1.2,0L340.4,90.6z' />
          <path d='M351.5,90.6l-1.4,0l0,7.3l1.4,0L351.5,90.6z' />
          <path d='M369.6,84.3c1.5-1.5,3.3-2.3,5.4-2.3c2.1,0,3.9,0.7,5.4,2.3c1.3,1.3,2,2.7,2.2,4.4l1.1,0l0-7.3l-1.2,0l0,4.1
        c-0.6-1.4-1.6-2.5-3-3.3c-1.5-0.8-3-1.2-4.6-1.2c-2.4,0-4.5,0.9-6.2,2.6c-1.5,1.5-2.3,3-2.5,5.2l1.2,0
        C367.5,87,368.3,85.6,369.6,84.3z'
          />
          <path d='M380.4,95c-1.5,1.5-3.3,2.3-5.4,2.3c-2.1,0-3.9-0.8-5.4-2.3c-1.3-1.3-2-2.9-2.2-4.4l-1.2,0
        c0.2,1.9,1.1,3.7,2.6,5.2c1.7,1.7,3.8,2.6,6.2,2.5c1.6,0,3.1-0.4,4.5-1.2c1.4-0.8,2.3-1.9,3.1-3.3l0,4.1l1.2,0l0-7.3l-1.1,0
        C382.3,92.1,381.6,93.7,380.4,95z'
          />
          <path d='M389.3,70.3l-1.2,0l0,18.4l1.2,0L389.3,70.3z' />
          <path d='M388.1,90.6l0,1.4c0,1.8,0.6,3.3,1.9,4.5c1.2,1.2,2.6,1.7,4.5,1.7l0-1c-1.6,0-2.7-0.5-3.7-1.5
        c-1-1-1.5-2.2-1.5-3.7l0-1.4L388.1,90.6z'
          />
          <path d='M284,99.5V81h2.3v7.6c0.5-0.6,1.1-1.1,1.8-1.5c0.7-0.4,1.5-0.6,2.4-0.6c0.7,0,1.4,0.1,2,0.4s1.1,0.7,1.5,1.2
        c0.4,0.5,0.7,1.2,1,2c0.2,0.8,0.3,1.7,0.3,2.8c0,0.9-0.1,1.8-0.4,2.6c-0.3,0.8-0.6,1.5-1.1,2.1c-0.5,0.6-1.1,1-1.7,1.4
        c-0.7,0.3-1.5,0.5-2.3,0.5c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.5-1.3l-0.1,1.2c-0.1,0.3-0.3,0.5-0.6,0.5H284z M289.7,88.4
        c-0.7,0-1.4,0.2-1.9,0.5c-0.5,0.3-1.1,0.8-1.5,1.4v6.1c0.4,0.6,0.9,0.9,1.3,1.2c0.5,0.2,1.1,0.3,1.7,0.3c1.2,0,2.1-0.4,2.8-1.3
        c0.6-0.8,1-2,1-3.6c0-0.8-0.1-1.5-0.2-2.1c-0.1-0.6-0.4-1.1-0.6-1.5c-0.3-0.4-0.6-0.7-1-0.8S290.3,88.4,289.7,88.4z'
          />
          <path d='M301.5,103.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2h-1.7l2.3-5l-5.3-11.9h2
        c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.3l3.4,8c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1-0.2,0.1-0.4,0.2-0.6
        c0.1-0.2,0.1-0.4,0.2-0.6l3.3-8c0.1-0.1,0.1-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1h1.8L301.5,103.2z'
          />
        </g>
      </g>
      <g>
        <g>
          <path d='M478.8,70.6c-5.9-0.3-11.2-1.8-15.8-5.6c-4.6-3.7-7.6-8.3-9-13.9c-1.8-7.7-0.3-14.8,4.5-21
        c4.2-5.5,9.8-8.8,16.8-9.7c7.2-0.9,13.7,0.9,19.3,5.6c0.7,0.5,1.3,1.1,1.9,1.7c0.9,0.7,0.7,1.3,0,1.9c-3.8,3.6-6.9,7.8-9.5,12.2
        c-0.1,0.2-0.4,0.5-0.7,0.9c0-2.9-0.4-5.6-1-8.2c-0.9-4-2.4-7.8-4.3-11.4c-0.2-0.3-0.3-0.6-0.7-0.6c-0.5,0-0.6,0.5-0.7,0.8
        c-2.3,4.5-4.1,9.2-5.2,14.2c-0.6,2.9-1.1,5.8-1.1,8.8c-2.7-4.1-5.7-8.1-9.4-11.4c-0.9-0.8-1.8-1.4-2.8-2
        c-0.8-0.5-1.3-0.3-1.3,0.7c0,1.4-0.1,2.8,0.1,4.2c1,7.1,3.2,13.7,6.9,19.9c2,3.3,4.3,6.3,7.2,8.9C475.2,68.2,477,69.4,478.8,70.6
        z'
          />
          <path d='M506.5,23.5c0.7,11.8-1.1,22.8-6.3,33.1c-4.5,8.8-10.9,16-18.9,22.3c-0.7-10.9,0.8-21.2,5.3-31
        C491,38.3,498,30.4,506.5,23.5z'
          />
          <path d='M478.8,70.6c-1.8-1.3-3.6-2.4-5.2-3.9c-2.8-2.6-5.2-5.6-7.2-8.9c-3.6-6.2-5.9-12.8-6.9-19.9
        c-0.2-1.4-0.1-2.8-0.1-4.2c0-1,0.5-1.2,1.3-0.7c1,0.6,2,1.2,2.8,2c3.7,3.3,6.7,7.3,9.4,11.4c0.2,0.6,0.3,1.1,0.3,1.7
        c0,6.9,1.8,13.4,4.4,19.8C478.2,68.7,479.1,69.5,478.8,70.6z'
          />
          <g>
            <path d='M515.8,69.4c0,2,0,4.1,0,6.1c0,0.9-0.3,1.1-1.1,1.1c-2.1-0.1-1.7,0.2-1.8-1.8c0-1.3,0-2.6,0-3.8
          c0-0.6-0.2-0.8-0.8-0.8c-1.5,0-2.9,0-4.4,0c-0.6,0-0.8,0.2-0.8,0.8c0,1.6,0,3.2,0,4.7c0,0.7-0.2,0.9-0.9,0.9c-2,0-2,0.1-2-1.8
          c0-3.8,0.1-7.6,0-11.4c0-1.3,0.7-1.1,1.5-1.2c0.8,0,1.5,0,1.4,1.2c-0.1,1.2,0,2.4,0,3.6c0,0.7,0.1,1,0.9,1
          c1.4-0.1,2.7-0.1,4.1,0c0.8,0,0.9-0.3,0.9-1c0-1.3,0-2.6,0-3.8c-0.1-1.2,0.8-0.9,1.4-0.9c0.6,0,1.5-0.4,1.5,0.9
          C515.8,65.2,515.8,67.3,515.8,69.4z'
            />
            <path d='M543.4,67.3c2.3-1.8,4.5-2,6.2-0.6c1.2,1,1.5,2.3,1.6,3.7c0.2,1.7-0.1,3.4-1.1,4.9c-1.4,2-4,2.1-6.6,0.3
          c0,1.6-0.1,3,0,4.4c0.1,1.3-0.8,1-1.5,1c-0.7,0-1.4,0.1-1.3-1c0.1-4.2,0-8.4,0-12.5c0-0.5-0.4-1.4,0.7-1.3
          C542.2,66.2,543.5,65.2,543.4,67.3z M543.6,71.3c0,0.3,0,0.7,0,1c0.1,1.1,1.2,2.4,2.1,2.4c1,0,1.9-0.3,2.2-1.3
          c0.4-1.2,0.7-2.3,0.3-3.6c-0.5-1.8-1.9-2.6-3.3-1.8C543.7,68.8,543.6,70,543.6,71.3z'
            />
            <path d='M523.8,65.8c3.4-0.2,5.4,2.2,5.5,5.5c0.1,3.2-2.3,5.5-5.6,5.5c-3.3,0-5.5-2.3-5.5-5.6
          C518.3,67.8,521,65.5,523.8,65.8z M521.2,71.4c0.1,0.4,0,0.9,0.2,1.5c0.4,1.1,1.2,1.7,2.2,1.8c0.9,0.1,1.8-0.2,2.4-1.1
          c0.7-1.3,0.9-2.6,0.4-4c-0.4-1-1.1-1.7-2.3-1.7C522,67.8,521.1,68.9,521.2,71.4z'
            />
            <path d='M568,68.6c-0.4-2.1-0.4-2.3,1.5-2.7c1.2-0.3,2.5-0.3,3.7,0c1.7,0.3,2.7,1.5,2.8,3.3
          c0.1,2.3-0.2,4.5,0.2,6.8c0.1,0.4-0.1,0.6-0.5,0.6c-0.7-0.1-1.5,0.4-1.9-0.5c-0.1-0.3-0.1-0.6-0.1-0.9c-1.2,1.2-2.5,2-4.2,1.6
          c-2.1-0.5-2.8-1.6-2.7-3.7c0.1-1.4,1.4-2.6,3.1-2.8c1-0.1,2-0.2,3.1-0.2c0.7,0,0.8-0.2,0.6-0.8c-0.4-1.1-1.2-1.6-2.5-1.4
          C569.9,67.8,568.9,68,568,68.6z M571.1,74.8c0.3,0.1,0.7-0.1,1.1-0.3c1-0.5,1.5-1.5,1.3-2.4c-0.2-0.9-1.2-0.3-1.9-0.3
          c-0.4,0-0.8,0.1-1.1,0.2c-0.9,0.3-1.4,0.9-1.2,1.8C569.5,74.6,570.2,74.7,571.1,74.8z'
            />
            <path d='M537.8,68c-1.1-0.2-2-0.6-3.1-0.3c-0.5,0.2-0.9,0.4-1,1c-0.1,0.6,0.4,0.8,0.8,1c0.7,0.3,1.3,0.5,2,0.7
          c1.4,0.4,1.9,1.5,2,2.8c0,1.2-0.6,2.2-1.8,2.9c-1.7,0.9-3.5,0.6-5.2,0.2c-1.1-0.2-0.3-1.1-0.3-1.7c0-0.8,0.5-0.3,0.8-0.2
          c0.7,0.2,1.5,0.3,2.3,0.3c0.8-0.1,1.4-0.4,1.5-1.2c0.1-0.7-0.5-1.1-1.1-1.4c-0.5-0.2-1-0.3-1.5-0.4c-1.8-0.6-2.4-1.6-2.2-3.4
          c0.2-1.3,1.5-2.6,3-2.7c0.6,0,1.3,0,1.9,0C538.1,65.8,538.1,65.8,537.8,68z'
            />
            <path d='M565.4,74.6c-0.2,0.7,0.6,1.7-0.7,2.1c-1.5,0.5-3.6-0.3-4.1-1.5c-0.3-0.6-0.3-1.2-0.3-1.9
          c0-1.5,0-2.9,0-4.4c0-0.8-0.3-1.3-1.2-1.1c-0.8,0.1-0.9-0.3-0.9-1c0-0.8,0.3-1,1-0.9c0.5,0.1,1.2,0.1,1-0.6
          c-0.3-2,1.2-2.1,2.6-2.5c0,0.7,0,1.3,0,1.9c-0.1,0.9,0.3,1.2,1.2,1.2c0.6,0,1.4-0.4,1.4,0.8c0,1.1-0.7,1-1.3,0.9
          c-0.9-0.1-1.3,0.2-1.3,1.2c0.1,1.1,0,2.2,0,3.3C562.9,74.4,563.4,74.9,565.4,74.6z'
            />
            <path d='M581.6,68.8c0,2.2-0.1,4.4,0,6.5c0,1.1-0.4,1.3-1.3,1.2c-0.8-0.1-1.6,0.2-1.5-1.2c0.1-4.4,0.1-8.8,0-13.2
          c0-0.8,0.2-1.1,1.1-1.1c1.7,0,1.7,0,1.7,1.7C581.6,64.8,581.6,66.8,581.6,68.8z'
            />
            <path d='M556.2,71.3c0,1.5-0.1,2.9,0,4.4c0.1,1.2-0.8,0.9-1.4,0.9c-0.6,0-1.3,0.2-1.2-0.9c0.1-2.9,0.1-5.9,0-8.8
          c0-1.1,0.8-0.8,1.3-0.8c0.5,0,1.4-0.3,1.3,0.8C556.1,68.3,556.2,69.8,556.2,71.3z'
            />
            <path d='M555,61.5c1.3-0.5,1.1,0.4,1.2,1.2c0.1,1.1-0.4,1.3-1.4,1.3c-0.9,0-1.3-0.2-1.3-1.2
          C553.5,61.8,553.7,61,555,61.5z'
            />
          </g>
          <g>
            <path d='M551.3,101.5c5.6,1.2,7.5,0.3,7-3.7c-1.1,0.7-2.1,1.3-3.4,1.4c-1.9,0.1-3.8-1.4-4.3-3.1
          c-0.5-1.8-0.3-3.6,0.5-5.2c1.2-2.3,4.6-3.4,7.2-0.9c0.4-0.3-0.1-0.9,0.5-1.1c0.8-0.3,1.4,0.1,1.4,1c0,3.2,0,6.4,0,9.7
          c0,2.5-1.8,4.3-4.3,4.3c-1.1,0-2.1,0.1-3.2-0.1C551.4,103.3,550.9,102.7,551.3,101.5z M558.4,94c0.1-0.7-0.1-1.4-0.4-2
          c-0.5-1.5-2-2.2-3.4-1.9c-1.8,0.5-2.2,1.9-2.4,3.4c-0.2,1.6,0.2,3.1,1.7,3.9C556,98.5,558.4,96.6,558.4,94z'
            />
            <path d='M512.4,97.9c-2.9,2.1-5.8,2-7.2-0.3c-1-1.6-1.3-3.4-0.8-5.3c0.7-3,2.8-4.3,5.8-3.6c0.8,0.2,1.4,0.8,2.2,1.6
          c0-2,0-3.7,0-5.4c0-0.6,0-1.1,0.9-1.1c0.9,0,0.9,0.4,0.9,1.1c0,4.4,0,8.7,0,13.1c0,0.6,0.1,1.1-0.8,1.2
          C512.3,99.2,512.4,98.7,512.4,97.9z M506.3,93.8c0,0.4,0,0.8,0,1.1c0.1,1.3,1.1,2.6,2.1,2.9c1.2,0.3,2.3,0,3.1-1
          c1.1-1.3,1.3-3.9,0.3-5.4c-0.6-0.9-1.4-1.6-2.6-1.6c-1.1,0-1.9,0.6-2.4,1.6C506.3,92.2,506.2,93,506.3,93.8z'
            />
            <path d='M525.6,97c-0.2,2.1-0.7,2.4-3.4,2.4c-2.6,0-4.3-1.2-5-3.5c-0.8-2.8,0-5.4,2-6.8c1.5-1,3.7-1,5.1-0.1
          c1.3,0.9,2.3,3.2,2,4.8c-0.1,0.7-0.5,0.5-0.9,0.5c-1.8,0-3.7,0-5.5,0c-0.8,0-1.2,0.1-0.8,1.1c0.7,2,2.5,2.8,4.8,2.2
          C524.4,97.5,524.9,97.3,525.6,97z M521.7,92.9c0.8,0,1.5,0,2.3,0c0.5,0,0.6-0.2,0.5-0.6c-0.2-1.2-1.1-2.2-2.3-2.4
          c-1.2-0.2-2.1,0.4-2.8,1.4c-0.3,0.4-0.8,1-0.5,1.5c0.2,0.4,0.9,0.1,1.3,0.2C520.7,93,521.2,92.9,521.7,92.9z'
            />
            <path d='M584.2,97c0.5,1.4-0.1,2-1.3,2.2c-1.3,0.2-2.6,0.3-3.8,0c-2.6-0.8-3.8-3-3.5-6.3c0.2-2.4,2.1-4.2,4.6-4.5
          c2.1-0.2,4.2,1.3,4.6,3.4c0.5,3,0.8,2.6-2.1,2.6c-1.3,0-2.7,0-4,0c-1.2,0-1.4,0.4-0.8,1.5c0.8,1.6,2.7,2.4,4.7,1.7
          C583.1,97.5,583.7,97.3,584.2,97z M580.4,92.9c0.5,0,1,0,1.5,0c1.2,0,1.4-0.3,0.9-1.4c-0.5-1.1-1.4-1.6-2.5-1.6
          c-1.1,0-1.9,0.6-2.4,1.6c-0.6,1.2-0.5,1.5,0.9,1.5C579.4,92.9,579.9,92.9,580.4,92.9z'
            />
            <path d='M603.8,97.1c0.6,1.5-0.2,2-1.4,2.2c-1.1,0.1-2.2,0.2-3.3,0c-3-0.5-4.6-3.9-3.6-7.2c0.9-3,3.6-4.3,6.6-3.2
          c1.6,0.6,3,3.4,2.6,5.1c-0.1,0.7-0.6,0.5-1,0.5c-1.8,0-3.7,0-5.5,0c-1.1,0-1.1,0.4-0.8,1.2c0.8,1.9,2.6,2.6,4.8,2
          C602.7,97.5,603.2,97.3,603.8,97.1z M602.8,92.9c-0.4-2.1-1-2.9-2.4-3c-1.2-0.1-2.4,0.6-2.9,2c-0.3,0.7-0.3,1.1,0.6,1.1
          C599.6,92.9,601.1,92.9,602.8,92.9z'
            />
            <path d='M571,97.7c-1.1,1.3-2.4,1.7-3.9,1.6c-1.7-0.1-2.9-1-3.3-2.6c-0.5-2.5-0.2-5-0.2-7.5c0-0.8,0.6-0.5,1-0.6
          c0.6-0.1,1,0.2,0.9,0.9c0,1.4,0,2.9,0,4.3c0,0.5,0,0.9,0,1.4c0.1,1.6,0.6,2.4,1.8,2.6c1.5,0.2,2.9-0.5,3.3-1.9
          c0.3-0.8,0.4-1.6,0.3-2.4c0-1.4,0-2.7,0-4.1c0-0.9,0.6-0.7,1.1-0.8c0.6,0,0.8,0.2,0.7,0.8c0,2.8,0,5.5,0,8.3
          c0,0.5,0.5,1.2-0.6,1.3C571.3,99.2,570.8,98.9,571,97.7z'
            />
            <path d='M535,92c0-2.1,0-4.2,0-6.2c0-0.7,0.2-1,0.9-1c1.8,0,3.7,0,5.5,0c0.6,0,1,0.1,1,0.9c0,0.9-0.5,0.9-1.1,0.9
          c-1.2,0-2.4,0-3.6,0c-0.7,0-1,0.2-1,1c-0.2,3.4-0.2,3.4,3.1,3.4c0.5,0,0.9,0,1.4,0c0.6,0,1,0.1,0.9,0.8c-0.1,0.6-0.1,1-0.9,1
          c-1.1-0.1-2.3,0-3.5,0c-0.9-0.1-1.1,0.3-1.1,1.1c0.1,1.5,0,3,0,4.4c0,0.8-0.3,0.9-1,0.9c-0.8,0-0.9-0.3-0.9-1
          C535.1,96.1,535,94,535,92C535,92,535,92,535,92z'
            />
            <path d='M606.8,97.3c0.9,0.4,1.9,0.6,2.8,0.5c0.8-0.1,1.3-0.5,1.4-1.2c0.1-0.6-0.3-1.1-0.8-1.4
          c-0.5-0.3-1-0.6-1.5-0.9c-1.9-1-2.5-2.1-2.1-3.5c0.5-1.6,2.3-2.6,4.1-2.3c0.4,0.1,0.7,0.2,1.1,0.2c0.9,0.1,0.7,0.6,0.6,1.2
          c-0.1,0.7-0.4,0.5-0.9,0.4c-0.7-0.1-1.4-0.4-2.1,0c-1.2,0.5-1.4,1.4-0.4,2.2c0.6,0.5,1.3,0.7,2,1.1c1.6,0.8,2.2,1.9,1.9,3.3
          c-0.4,1.7-1.5,2.5-3.3,2.5c-0.4,0-0.9,0-1.3,0C606.7,99.3,606.5,99.1,606.8,97.3z'
            />
            <path d='M589.5,90.2c1-0.9,1.7-1.6,2.8-1.7c0.7-0.1,1.4,0,1.4,1c0,0.5-0.1,0.7-0.6,0.7c-2.3,0.2-3.3,1.3-3.3,3.6
          c0,1.5-0.1,3,0,4.4c0,0.8-0.4,0.9-1,0.9c-0.6,0-0.9-0.1-0.9-0.8c0.1-2.7,0-5.4,0.1-8.1c0-0.5-0.5-1.3,0.6-1.4
          C589.7,88.5,589.6,89.3,589.5,90.2z'
            />
            <path d='M547.4,93.8c0,1.5,0,3,0,4.4c0,0.8-0.4,0.8-1,0.8c-0.6,0-0.9-0.1-0.9-0.8c0.1-2.9,0.1-5.8,0-8.6
          c0-0.7,0.2-1,0.9-1c0.8,0,0.9,0.4,0.9,1C547.3,91,547.4,92.4,547.4,93.8z'
            />
            <path d='M546.4,84.3c1.2-0.3,0.9,0.5,1,1.1c0.1,0.9-0.4,0.9-1.1,0.9c-0.9,0.1-1.1-0.3-1.1-1.1
          C545.3,84.3,545.6,84.1,546.4,84.3z'
            />
          </g>
          <g>
            <g>
              <path d='M523.9,109c0-0.7,0-1.3,0-1.9c0-0.5,0.1-0.6,0.6-0.6c0.5,0,0.6,0.2,0.6,0.7c0,1.9,0,3.8,0,5.7
            c0,0.7-0.5,1-1.1,0.6c-0.3-0.2-0.3-0.1-0.5,0c-1.2,0.6-2.4,0.1-2.7-1.2c-0.2-0.8-0.2-1.5,0-2.3
            C521.3,108.6,522.4,108.3,523.9,109z M522,111.3c0,0.1,0,0.3,0.1,0.5c0.1,0.4,0.3,0.9,0.9,0.9c0.5,0,0.8-0.3,0.9-0.8
            c0.1-0.2,0.1-0.4,0.1-0.6c0-0.9-0.4-1.6-1-1.6C522.3,109.6,522,110.2,522,111.3z'
              />
              <path d='M515.6,111c0-0.5,0-1,0-1.6c0-0.7,0.5-1.1,1.1-0.7c0.4,0.3,0.5,0.1,0.8,0c1.2-0.6,2.4,0.1,2.4,1.5
            c0,0.9,0,1.9,0,2.8c0,0.5-0.3,0.5-0.7,0.6c-0.4,0-0.6-0.1-0.6-0.6c0-0.8,0-1.6,0-2.5c0-0.4-0.1-0.8-0.6-0.9
            c-0.5,0-0.8,0.3-0.9,0.7c-0.2,0.8-0.1,1.7-0.1,2.5c0,0.5-0.2,0.7-0.7,0.7c-0.6,0-0.6-0.3-0.6-0.7
            C515.6,112.3,515.6,111.6,515.6,111z'
              />
              <path d='M539.7,108.6c1.4,0,2.4,1.1,2.4,2.6c0,1.5-1,2.6-2.5,2.5c-1.4,0-2.4-1.1-2.4-2.5
            C537.3,109.6,538.3,108.6,539.7,108.6z M540.8,111.1c0-0.9-0.4-1.5-1.1-1.5c-0.7,0-1.1,0.6-1.1,1.4c0,0.9,0.4,1.6,1.1,1.6
            C540.4,112.6,540.8,112.1,540.8,111.1z'
              />
              <path d='M530.1,111.8c0,0.3,0.1,0.7,0.1,1c0,0.2,0.2,0.6-0.2,0.7c-0.2,0.1-0.7,0.1-0.8,0c-0.3-0.5-0.5-0.2-0.8,0
            c-0.6,0.3-1.3,0.3-1.8-0.1c-0.6-0.4-0.6-1-0.5-1.6c0.2-0.7,0.7-1,1.3-1.1c0.3-0.1,0.6,0,0.9-0.1c0.2,0,0.6,0.1,0.6-0.3
            c0-0.3-0.2-0.5-0.5-0.6c-0.4-0.1-0.9,0-1.3,0.1c-0.2,0.1-0.4,0.4-0.5,0.1c-0.1-0.3-0.2-0.8,0.2-1c0.8-0.4,1.6-0.4,2.5-0.1
            c0.7,0.3,0.8,0.9,0.9,1.6C530.1,110.9,530.1,111.3,530.1,111.8z M529,111.6c0-0.4-0.4-0.3-0.6-0.3c-0.5,0-1.1,0.1-1.1,0.8
            c0,0.5,0.3,0.6,0.7,0.6C528.5,112.7,529,112.1,529,111.6z'
              />
              <path d='M514.4,111.2c0,0.5,0,1.1,0,1.6c0,0.2,0.1,0.6-0.2,0.7c-0.3,0.1-0.7,0.2-1-0.1c-0.2-0.3-0.4-0.1-0.5,0
            c-1.4,0.7-2.5,0-2.5-1.5c0-0.9,0-1.8,0-2.6c0-0.4,0.1-0.6,0.6-0.6c0.5,0,0.7,0.1,0.7,0.6c0,0.8,0,1.6,0,2.3
            c0,0.4,0.1,0.9,0.7,1c0.6,0,0.8-0.4,0.9-0.9c0.2-0.8,0.1-1.6,0.1-2.5c0-0.5,0.2-0.6,0.7-0.6c0.5,0,0.6,0.2,0.6,0.6
            C514.4,109.9,514.4,110.6,514.4,111.2z'
              />
              <path d='M505.9,110.2c0-0.9,0-1.7,0-2.6c0-0.5,0.2-0.6,0.6-0.6c0.8,0,1.5,0,2.3,0c0.5,0,0.5,0.2,0.5,0.6
            c0,0.4-0.1,0.6-0.5,0.6c-0.3,0-0.6,0-0.8,0c-0.7-0.1-0.9,0.2-0.8,0.8c0,0.6,0.2,0.8,0.7,0.7c0.2,0,0.4,0,0.7,0
            c0.4,0,0.7,0,0.7,0.6c0,0.6-0.3,0.6-0.7,0.6c-1.3,0-1.3,0-1.3,1.3c0,0.2,0,0.5,0,0.7c0.1,0.7-0.2,0.8-0.8,0.8
            c-0.4,0-0.5-0.1-0.5-0.5C505.9,112.1,505.9,111.2,505.9,110.2z'
              />
              <path d='M530.9,111.1c0-1.8,1.3-2.8,2.9-2.5c0.4,0.1,0.5,0.2,0.5,0.7c-0.1,0.4-0.2,0.5-0.6,0.4
            c-0.5-0.1-1,0-1.2,0.5c-0.4,0.6-0.4,1.3,0,1.9c0.3,0.5,0.8,0.6,1.3,0.5c0.2,0,0.6-0.3,0.6,0.2c0,0.4,0.1,0.8-0.5,0.9
            C532.1,113.9,530.9,112.9,530.9,111.1z'
              />
              <path d='M536.4,111.2c0,0.6,0,1.3,0,1.9c0,0.5-0.2,0.5-0.6,0.5c-0.3,0-0.7,0.1-0.7-0.4c0-1.3,0-2.6,0-4
            c0-0.5,0.2-0.5,0.6-0.5c0.3,0,0.7-0.1,0.7,0.4C536.4,109.8,536.4,110.5,536.4,111.2z'
              />
              <path d='M535.7,106.6c0.5-0.1,0.7,0.1,0.7,0.6c0,0.5-0.1,0.7-0.6,0.7c-0.5,0-0.6-0.2-0.6-0.7
            C535.2,106.8,535.2,106.5,535.7,106.6z'
              />
              <path d='M539.1,108c0.4-1.4,0.7-1.5,1.9-1.2C540.3,107.3,540.2,108.3,539.1,108z' />
            </g>
            <g>
              <path d='M571,111.2c0-0.6,0-1.2,0-1.7c0-0.6,0.4-0.8,0.8-0.5c0.2,0.2,0.3,0.1,0.5,0c0.7-0.4,1.4-0.4,2,0.2
            c0.2,0.2,0.3,0.2,0.6,0c1.4-1,2.5-0.5,2.7,1.2c0.1,0.9,0,1.8,0,2.8c0,0.3-0.1,0.5-0.5,0.5c-0.4,0-0.5-0.1-0.5-0.5
            c0-0.8,0-1.7,0-2.5c0-0.5-0.1-1.1-0.8-1.1c-0.7,0-1,0.5-1,1.1c-0.1,0.8-0.1,1.6-0.1,2.4c0,0.4,0,0.6-0.5,0.6
            c-0.5,0-0.4-0.3-0.4-0.6c0-0.8,0-1.6,0-2.4c0-0.5-0.1-1.1-0.7-1.1c-0.7,0-1,0.5-1.1,1c-0.1,0.8-0.1,1.6-0.1,2.5
            c0,0.3,0,0.6-0.4,0.6c-0.5,0-0.5-0.2-0.5-0.6C571,112.4,571,111.8,571,111.2z'
              />
              <path d='M595.5,109c0-0.6,0-1.3,0-2c0-0.3,0-0.5,0.4-0.5c0.4,0,0.5,0.2,0.5,0.6c0,2,0,4,0,6c0,0.5-0.4,0.8-0.7,0.5
            c-0.3-0.4-0.5-0.1-0.7,0c-1.1,0.5-2.2,0-2.7-1.1c-0.3-0.7-0.3-1.5-0.1-2.2c0.4-1.3,1.6-1.9,2.8-1.3
            C595.1,109,595.2,109.1,595.5,109z M593,111.3c0,0.1,0.1,0.4,0.1,0.7c0.2,0.5,0.5,0.9,1.1,0.9c0.6,0,0.9-0.3,1.1-0.8
            c0.3-0.7,0.2-1.4-0.1-2c-0.2-0.4-0.6-0.6-1-0.6c-0.5,0-0.8,0.3-1,0.7C593,110.5,593,110.8,593,111.3z'
              />
              <path d='M579.6,113.3c0,0.6,0,1.2,0,1.7c0,0.4-0.1,0.6-0.5,0.6c-0.4,0-0.5-0.2-0.5-0.5c0-1.9,0-3.8,0-5.8
            c0-0.7,0.5-0.6,0.7-0.4c0.3,0.3,0.5,0.1,0.8,0c1.2-0.6,2.4,0,2.7,1.3c0.2,0.7,0.2,1.4,0,2.1
            C582.5,113.7,581.3,114.1,579.6,113.3z M582.1,111.1c0-0.1,0-0.2,0-0.4c-0.1-0.6-0.4-1.2-1.1-1.2c-0.6,0-1.1,0.4-1.2,1.2
            c0,0.2-0.1,0.3-0.1,0.5c0,1,0.5,1.8,1.2,1.8C581.5,113,582,112.2,582.1,111.1z'
              />
              <path d='M566.4,110.3c0-0.9,0-1.8,0-2.6c0-0.5,0.1-0.7,0.6-0.7c0.8,0,1.6,0,2.3,0c0.3,0,0.6-0.1,0.6,0.4
            c0,0.5-0.3,0.5-0.7,0.5c-0.5,0-1,0-1.4,0c-0.4,0-0.6,0.1-0.5,0.5c0,0,0,0,0,0.1c0,1.5-0.1,1.2,1.2,1.2c0.2,0,0.5,0,0.8,0
            c0.3,0,0.4,0.1,0.4,0.5c0,0.3-0.1,0.5-0.4,0.4c-0.4,0-0.9,0-1.4,0c-0.4,0-0.6,0.1-0.6,0.5c0,1.5-0.1,1.5,1.4,1.5
            c0.2,0,0.5,0,0.7,0c0.3,0,0.5,0.1,0.5,0.5c0,0.4-0.1,0.5-0.5,0.5c-0.9,0-1.7,0-2.6,0c-0.5,0-0.5-0.2-0.5-0.6c0-0.4,0-0.9,0-1.4
            C566.4,111.2,566.4,110.8,566.4,110.3z'
              />
              <path d='M545.3,113.8c-0.2,0-0.5-0.1-0.9-0.2c-0.4-0.1-0.2-0.4-0.2-0.7c0-0.4,0.3-0.2,0.5-0.2
            c0.2,0,0.5,0.1,0.7,0.1c0.5,0,1-0.1,1.2-0.7c0.2-0.6-0.1-1-0.6-1.2c-0.3-0.2-0.7-0.4-1.1-0.6c-0.8-0.5-1.1-1.2-0.9-2
            c0.2-0.8,0.7-1.2,1.6-1.3c0.5-0.1,0.9,0,1.3,0.1c0.4,0.1,0.3,0.4,0.3,0.6c0,0.4-0.3,0.3-0.5,0.2c-0.1,0-0.3-0.1-0.4-0.1
            c-0.5,0-1,0-1.2,0.6c-0.2,0.6,0.3,0.8,0.7,1.1c0.2,0.1,0.4,0.2,0.7,0.4c1,0.6,1.4,1.5,1.2,2.5
            C547.3,113.3,546.5,113.8,545.3,113.8z'
              />
              <path d='M552.1,111.8c0,0.4,0,0.9,0,1.3c0,0.5-0.4,0.7-0.6,0.5c-0.4-0.3-0.6-0.1-0.9,0c-0.7,0.3-1.3,0.2-1.9-0.2
            c-0.5-0.4-0.5-1-0.4-1.6c0.2-0.7,0.8-1,1.4-1.1c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.5,0.2,0.6-0.2c0.1-0.3-0.1-0.7-0.4-0.8
            c-0.5-0.2-1-0.1-1.5,0.1c-0.1,0.1-0.3,0.4-0.5,0c-0.1-0.2-0.2-0.6,0.2-0.7c0.7-0.4,1.5-0.5,2.2-0.1c0.7,0.3,0.8,0.9,0.9,1.6
            C552.1,110.9,552.1,111.4,552.1,111.8C552.1,111.8,552.1,111.8,552.1,111.8z M550.7,111.3c-0.9,0-1.5,0.4-1.5,0.9
            c0.1,0.5,0.4,0.7,0.9,0.7c0.6,0,1.1-0.6,1.1-1.2C551.3,111.3,550.9,111.4,550.7,111.3z'
              />
              <path d='M597.9,109.8c-0.3-0.5,0-0.8,0.5-1c1.6-0.6,2.7,0.2,2.8,1.9c0,0.8,0,1.6,0,2.3c0,0.6-0.4,0.7-0.7,0.5
            c-0.4-0.3-0.5-0.1-0.8,0c-1.3,0.5-2.5-0.3-2.4-1.6c0.1-0.6,0.6-1.2,1.4-1.3c0.3-0.1,0.6,0,0.8-0.1c0.2,0,0.6,0.3,0.6-0.2
            c0-0.4-0.2-0.7-0.6-0.8C599.2,109.4,598.8,109.5,597.9,109.8z M599,113c0.7,0,1.2-0.6,1.2-1.2c0-0.4-0.3-0.4-0.6-0.4
            c-0.8,0-1.5,0.4-1.5,0.9C598.2,112.7,598.6,112.9,599,113z'
              />
              <path d='M588.2,111.2c0,1.5-1,2.5-2.3,2.5c-1.3,0-2.3-1.1-2.3-2.6c0-1.5,1-2.5,2.3-2.5
            C587.3,108.7,588.2,109.7,588.2,111.2z M584.6,111.2c0,0.4,0.1,0.7,0.2,1c0.5,0.9,1.5,1,2.1,0.2c0.5-0.7,0.5-1.8,0-2.5
            c-0.5-0.7-1.4-0.7-2,0C584.7,110.4,584.5,110.8,584.6,111.2z'
              />
              <path d='M559.6,111.2c0,0.6,0,1.3,0,1.9c0,0.5-0.4,0.8-0.8,0.4c-0.3-0.3-0.4-0.2-0.6,0c-1.4,0.7-2.5,0.1-2.6-1.5
            c-0.1-0.9,0-1.8,0-2.7c0-0.3,0.1-0.5,0.4-0.5c0.4,0,0.5,0.1,0.5,0.5c0,0.8,0,1.6,0,2.4c0,0.6,0.2,1.2,0.9,1.3
            c0.6,0.1,1.1-0.5,1.2-1.2c0.1-0.8,0.1-1.5,0.1-2.3c0-0.4,0-0.6,0.5-0.6c0.5,0,0.4,0.4,0.4,0.6
            C559.6,110,559.6,110.6,559.6,111.2C559.6,111.2,559.6,111.2,559.6,111.2z'
              />
              <path d='M561.1,111.2c0-0.3,0-0.6,0-0.9c0-0.4,0-0.7-0.5-0.7c-0.3,0-0.5-0.1-0.5-0.4c0-0.3,0.2-0.4,0.5-0.3
            c0.5,0.1,0.6-0.2,0.6-0.6c0-0.2-0.1-0.5,0.2-0.6c0.4-0.1,0.8-0.3,0.7,0.4c-0.1,0.6,0,0.9,0.7,0.8c0.2,0,0.5,0,0.5,0.3
            c0,0.3-0.1,0.5-0.4,0.5c-0.7-0.1-0.8,0.3-0.8,0.9c0,0.5,0,1,0,1.5c0,0.6,0.2,1.1,0.9,0.9c0.2,0,0.4,0,0.4,0.2
            c0,0.2,0,0.4-0.2,0.6c-0.8,0.3-1.8-0.1-1.9-1C561.1,112.2,561.1,111.7,561.1,111.2C561.1,111.2,561.1,111.2,561.1,111.2z'
              />
              <path d='M589.1,111.2c0-0.6,0-1.2,0-1.9c0-0.5,0.4-0.6,0.6-0.4c0.4,0.5,0.7,0,1-0.1c0.7-0.2,1-0.1,1,0.5
            c0,0.2,0,0.3-0.3,0.3c-1,0.1-1.3,0.5-1.4,1.5c0,0.7,0,1.3,0,2c0,0.4-0.1,0.5-0.5,0.5c-0.4,0-0.4-0.2-0.4-0.5
            C589.1,112.5,589.1,111.8,589.1,111.2z'
              />
              <path d='M553.4,110.1c0-1,0-1.9,0-2.9c0-0.3-0.1-0.7,0.5-0.6c0.5,0,0.4,0.3,0.4,0.6c0,2,0,3.9,0,5.9
            c0,0.3,0,0.6-0.5,0.6c-0.5,0-0.4-0.3-0.4-0.6C553.4,112.1,553.4,111.1,553.4,110.1z'
              />
              <path d='M598.1,106.9c0.9-0.1,1.5,0.3,1.7,1.1c0,0.1,0,0.2-0.1,0.2c-0.2,0.1-0.4,0.1-0.6-0.1
            C598.8,107.7,598.5,107.4,598.1,106.9z'
              />
            </g>
          </g>
        </g>
        <g>
          <rect x='431.5' y='4.6' width='2.6' height='131.7' />
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
