import { useRecoilValue, useRecoilState } from 'recoil'
import { dashboardsAtom, currentDashboardAtom } from '../authentification/authState'
import DisplayDashboard from './DisplayDashboard'
import { DashboardSelectors } from './DashboardSelectors'

function Main (): JSX.Element {
  const dashboards = useRecoilValue(dashboardsAtom)
  const [currentDashboardId, setCurrentDashboard] = useRecoilState(currentDashboardAtom)

  if (dashboards === null) {
    return <>Error loading dashboards</>
  }

  if (dashboards.length === 1) {
    const dashboard = dashboards[0].dashboard_configs[0]
    return <DisplayDashboard dashboard={dashboard} />
  }

  let appDashboard = dashboards[0]
  if (currentDashboardId !== undefined) {
    const conf = dashboards.find(dash => dash.role === currentDashboardId)
    if (conf !== undefined) {
      appDashboard = conf
    }
  }
  const dashboard = appDashboard.dashboard_configs[0]
  if (dashboard === null) {
    return <></>
  }

  const dashboardSelector = (
    <DashboardSelectors
      dashboardIds={dashboards.map(w => w.role)}
      setCurrentDashboardId={setCurrentDashboard}
      currentDashboardId={currentDashboardId ?? dashboards[0].role}
    />
  )

  return (
    <DisplayDashboard
      dashboard={dashboard}
      dashboardSelector={dashboardSelector}
    />
  )
}

export default Main
