interface LogoProps {
  width?: number
  height?: number
  color?: string
}

// style="enable-background:new 0 0 546.9 140.9;"

const Logo = ({ width, height, color }: LogoProps): JSX.Element => (
  <svg
    version='1.1' x='0px' y='0px' height={height} width={width} style={{ fill: color }}
    viewBox='0 0 546.9 140.9'
  >
    <g>
      <g>
        <path d='M187.4,111.2h-10.8l-26.1-43.4c-1.5,0.1-3.8,0.1-7.1,0.1H138v43.4h-8.9V7.2H147c10.4,0,18.7,2.5,25.1,7.6
            c6.7,5.4,10,12.8,10,22.3c0,15.4-7.3,25.2-21.9,29.1L187.4,111.2z M173.3,37.3c0-8.5-3.4-14.4-10.1-17.8c-5.4-2.7-13.8-4-25.3-4
            v43.1c12.4,0,21.4-1.7,27-5C170.5,50.4,173.3,44.9,173.3,37.3z'
        />
        <path d='M260.9,34.7h5.4v76h-5.4V95.3c-5.7,11.5-15.5,17.2-29.4,17.2c-9.5,0-17.4-3.3-23.8-9.8
            c-6.4-6.5-9.6-14.5-9.6-23.8V34.7h5.4v44.2c0,7.8,2.7,14.5,8.2,20.3c5.5,5.7,12.1,8.6,19.8,8.6s14.6-2.9,20.5-8.6
            c5.9-5.7,8.9-12.5,8.9-20.3V34.7z'
        />
        <path d='M347.3,37.7c4.8-3.3,10.6-4.9,17.5-4.9c6.9,0,12.9,2.5,18.1,7.4c5.2,5,7.8,11.6,7.8,20.1v50.3h-5.4V60.3
            c0-6.5-2.1-12-6.4-16.3c-4.3-4.3-9.4-6.5-15.4-6.5c-6,0-11.4,2.3-16,6.8c-4.7,4.5-7,9.7-7,15.7v50.6h-5.4V60
            c0-6.1-2.1-11.3-6.4-15.8c-4.3-4.4-9.5-6.7-15.5-6.7s-11.4,2.3-15.9,6.8c-4.5,4.5-6.8,9.7-6.8,15.7v50.6h-5.4v-76h5.4v10.6
            c2.3-3.4,5.6-6.3,10-8.8c4.4-2.5,9.1-3.7,13.8-3.7c4.8,0,9.4,1.7,13.8,5c4.4,3.3,7.6,7.6,9.6,12.9C339.3,45.3,342.5,41,347.3,37.7
            z'
        />
        <g>
          <g>
            <rect x='57.6' y='128.2' width='55.2' height='8.9' />
            <g>
              <path d='M92,93.1c-9.3,9.5-20.4,14.2-33.4,14.2c-13,0-24.3-4.7-33.8-14.2C17,85.4,12.4,76.4,11,66.3H3.6
                        c1.5,12,6.7,22.5,15.8,31.4c10.8,10.7,23.7,16.1,38.8,16.1c9.9,0,19.1-2.5,27.8-7.6c8.6-5.1,15.1-12,19.4-20.8v25.9h7.4v-45
                        h-7.4C104,76.4,99.5,85.4,92,93.1z'
              />
              <path d='M24.8,25.6c9.5-9.6,20.7-14.4,33.6-14.4c13,0,24.1,4.8,33.4,14.3c8,8.2,12.6,17.7,13.7,28.4h7.2V7.2h-7.4
                        v26.1c-3.6-8.6-9.8-15.6-18.8-20.8c-9-5.2-18.5-7.9-28.3-7.9c-15.1,0-28.1,5.4-38.8,16.1C9.9,30.2,4.6,41.2,3.4,53.9h7.5
                        C12.1,43.3,16.7,33.8,24.8,25.6z'
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <path d='M535,76.4l0,1.1c-1.6,0-3,0.5-4.1,1.6c-1.1,1.1-1.7,2.5-1.7,4.1l0,8.3l5.8,0l0,1.4l-5.8,0l0,9.3l-1.3,0
            l0-18.9c0-2,0.7-3.7,2.1-5C531.4,77,533,76.4,535,76.4z'
        />
        <path d='M539.5,78.5c-0.8,0-1.2-0.4-1.2-1.1c0-0.7,0.4-1.1,1.2-1.1c0.8,0,1.2,0.4,1.2,1.1
            C540.7,78.1,540.3,78.5,539.5,78.5z M540.2,83l0,18.3l-1.3,0l0-18.3L540.2,83z'
        />
        <path d='M450.3,110.7l0,8.4h-0.6l0-2.1c-0.4,0.7-0.9,1.3-1.6,1.7c-0.7,0.4-1.5,0.6-2.3,0.6c-1.2,0-2.3-0.4-3.2-1.3
            c-0.9-0.9-1.3-1.9-1.3-3.1c0-1.2,0.4-2.2,1.3-3.1c0.9-0.9,1.9-1.3,3.2-1.3c0.8,0,1.6,0.2,2.3,0.6c0.7,0.4,1.2,1,1.5,1.7l0-2.1
            L450.3,110.7z M441.9,115c0,1,0.4,1.9,1.2,2.7c0.8,0.8,1.7,1.1,2.8,1.1c1.1,0,2-0.4,2.7-1.1c0.8-0.8,1.1-1.7,1.1-2.7
            c0-1-0.4-2-1.1-2.7c-0.8-0.8-1.7-1.2-2.7-1.2c-1.1,0-2,0.4-2.7,1.2C442.3,113,441.9,113.9,441.9,115z'
        />
        <path d='M456.9,119.1h-0.6l0-8.4h0.6l0,1.7c0.6-1.3,1.7-1.9,3.3-1.9c1.1,0,1.9,0.4,2.7,1.1c0.7,0.7,1.1,1.6,1.1,2.6
            l0,4.9h-0.6l0-4.9c0-0.9-0.3-1.6-0.9-2.2c-0.6-0.6-1.3-0.9-2.2-0.9c-0.9,0-1.6,0.3-2.3,1c-0.7,0.6-1,1.4-1,2.2L456.9,119.1z'
        />
        <path d='M478.4,110.7l0,8.4h-0.6l0-2.1c-0.4,0.7-0.9,1.3-1.6,1.7c-0.7,0.4-1.5,0.6-2.3,0.6c-1.2,0-2.3-0.4-3.2-1.3
            c-0.9-0.9-1.3-1.9-1.3-3.1c0-1.2,0.4-2.2,1.3-3.1c0.9-0.9,1.9-1.3,3.2-1.3c0.8,0,1.6,0.2,2.3,0.6c0.7,0.4,1.2,1,1.5,1.7l0-2.1
            L478.4,110.7z M470.1,114.9c0,1,0.4,1.9,1.2,2.7c0.8,0.8,1.7,1.1,2.8,1.1c1.1,0,2-0.4,2.7-1.1c0.8-0.8,1.1-1.7,1.1-2.7
            c0-1-0.4-2-1.1-2.7c-0.8-0.8-1.7-1.2-2.7-1.2c-1.1,0-2,0.4-2.7,1.2C470.5,113,470.1,113.9,470.1,114.9z'
        />
        <path d='M487.7,118.7v0.5c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.3-0.9-2.3l0-11h0.6l0,11c0,0.8,0.3,1.4,0.8,1.9
            C486.3,118.5,487,118.7,487.7,118.7z'
        />
        <path d='M499.8,110.7h0.6l0,8.1c0,1.1-0.4,2-1.2,2.8c-0.8,0.8-1.7,1.2-2.9,1.2c-1.4,0-2.4-0.5-3.2-1.5l0-0.9
            c0.4,0.6,0.8,1.1,1.4,1.4c0.6,0.4,1.2,0.5,1.8,0.5c0.9,0,1.7-0.3,2.4-1c0.7-0.7,1-1.5,1-2.4l0-1.4c-0.6,1.2-1.7,1.8-3.2,1.8
            c-1,0-1.9-0.4-2.7-1.1c-0.7-0.7-1.1-1.6-1.1-2.6l0-4.9h0.6l0,4.9c0,0.9,0.3,1.6,1,2.2c0.6,0.6,1.4,0.9,2.3,0.9
            c0.9,0,1.6-0.3,2.3-1c0.6-0.6,1-1.4,0.9-2.2L499.8,110.7z'
        />
        <path d='M509.3,118.6v0.5c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.3-0.9-2.3l0-8.7h0.6l0,3.4l2.6,0v0.5l-2.6,0l0,4.8
            c0,0.8,0.3,1.4,0.8,1.9C507.9,118.4,508.5,118.6,509.3,118.6z'
        />
        <path d='M515.1,108.6c-0.4,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.4,0,0.5,0.2,0.5,0.5S515.4,108.6,515.1,108.6z
             M515.4,110.7l0,8.4h-0.6l0-8.4H515.4z'
        />
        <path d='M525.8,119.2h-0.2c-1.2,0-2.2-0.4-3.1-1.3c-0.9-0.9-1.3-1.9-1.3-3.1c0-1.2,0.4-2.2,1.3-3.1
            c0.9-0.9,2-1.3,3.2-1.3c1.3,0,2.4,0.5,3.3,1.4l0,0.9c-0.4-0.6-0.9-1-1.5-1.4c-0.6-0.3-1.2-0.5-1.9-0.5c-1,0-2,0.4-2.7,1.1
            c-0.8,0.8-1.2,1.7-1.2,2.7c0,1.1,0.4,2,1.2,2.7c0.8,0.8,1.7,1.1,2.7,1.1c0.7,0,1.3-0.2,1.9-0.5c0.6-0.3,1.1-0.8,1.5-1.4l0,1
            C528.2,118.8,527.1,119.2,525.8,119.2z'
        />
        <path d='M536.3,110.9c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.3,0.1,0.6,0.4,0.9c0.3,0.3,0.6,0.5,1,0.7
            c0.4,0.2,0.8,0.4,1.2,0.7c0.4,0.2,0.7,0.6,1,0.9c0.3,0.4,0.4,0.8,0.4,1.3c0,0.7-0.3,1.4-0.8,1.9c-0.6,0.5-1.2,0.8-2,0.8
            c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.5-1-0.9l0-0.9c0.5,1,1.2,1.6,2.3,1.6c0.7,0,1.2-0.2,1.6-0.6c0.4-0.4,0.7-0.8,0.7-1.3
            s-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.6-1-0.9c-0.4-0.2-0.8-0.5-1.2-0.7c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.4-0.7-0.4-1.2
            c0-0.4,0.2-0.8,0.5-1.1c0.4-0.3,0.8-0.5,1.4-0.5c0.6,0,1,0.2,1.5,0.6v0.8C537.4,111.2,536.9,110.9,536.3,110.9z'
        />
        <path d='M541.3,103.8l-103.2,0.2l0,1.1l103.2-0.2L541.3,103.8z' />
        <path d='M535.1,91.1l-7.2,0l0,2.1l7.2,0L535.1,91.1z' />
        <path d='M442.6,86.3c1.7-1.7,3.7-2.6,6-2.6c2.3,0,4.3,0.8,6,2.5c1.4,1.4,2.2,3,2.4,4.9l1.3,0l0-8.1l-1.3,0l0,4.6
            c-0.6-1.5-1.8-2.7-3.4-3.7c-1.6-0.9-3.3-1.4-5.1-1.4c-2.7,0-5,0.9-6.9,2.8c-1.7,1.6-2.6,3.4-2.8,5.7l1.3,0
            C440.4,89.3,441.2,87.7,442.6,86.3z'
        />
        <path d='M454.6,98.2c-1.7,1.7-3.6,2.5-6,2.5c-2.3,0-4.3-0.8-6-2.5c-1.4-1.4-2.3-3.2-2.5-4.9l-1.3,0
            c0.2,2.1,1.2,4.1,2.8,5.7c1.9,1.9,4.2,2.8,6.9,2.8c1.8,0,3.4-0.5,5-1.4s2.6-2.1,3.5-3.7l0,4.5l1.3,0l0-8.1l-1.3,0
            C456.8,95,456,96.8,454.6,98.2z'
        />
        <path d='M464.6,89.2c0-1.4,0.6-2.7,1.7-3.8c1.1-1.1,2.4-1.6,3.9-1.6c1.5,0,2.7,0.5,3.7,1.6c1,1.1,1.5,2.3,1.5,3.8l0,2
            l1.5,0l0-2c0-1.4,0.6-2.7,1.7-3.8c1.1-1.1,2.4-1.6,3.9-1.6c1.5,0,2.7,0.5,3.8,1.6c1,1,1.6,2.4,1.6,3.9l0,1.9l1.3,0l0-1.9
            c0-2-0.6-3.7-1.9-4.9c-1.3-1.2-2.7-1.8-4.4-1.8c-1.7,0-3.1,0.4-4.3,1.2c-1.2,0.8-2,1.8-2.4,3.1c-0.5-1.3-1.3-2.3-2.4-3.1
            c-1.1-0.8-2.2-1.2-3.4-1.2c-1.2,0-2.3,0.3-3.4,0.9c-1.1,0.6-2,1.3-2.5,2.1l0-2.6l-1.3,0l0,8.1l1.3,0L464.6,89.2z'
        />
        <path d='M489.1,93.2l-1.3,0l0,8.1l1.3,0L489.1,93.2z' />
        <path d='M464.6,93.3l-1.3,0l0,8.1l1.3,0L464.6,93.3z' />
        <path d='M476.8,93.2l-1.5,0l0,8.1l1.5,0L476.8,93.2z' />
        <path d='M496.9,86.2c1.7-1.7,3.7-2.6,6-2.6c2.3,0,4.3,0.8,6,2.5c1.4,1.4,2.2,3,2.4,4.9l1.3,0l0-8.1l-1.3,0l0,4.6
            c-0.6-1.5-1.8-2.7-3.4-3.7c-1.6-0.9-3.3-1.4-5.1-1.4c-2.7,0-5,0.9-6.9,2.8c-1.7,1.6-2.6,3.4-2.8,5.7l1.3,0
            C494.7,89.2,495.5,87.6,496.9,86.2z'
        />
        <path d='M508.9,98.1c-1.7,1.7-3.6,2.5-6,2.5c-2.3,0-4.3-0.8-6-2.5c-1.4-1.4-2.3-3.2-2.5-4.9l-1.3,0
            c0.2,2.1,1.2,4.1,2.8,5.7c1.9,1.9,4.2,2.8,6.9,2.8c1.8,0,3.4-0.5,5-1.4c1.5-0.9,2.6-2.1,3.5-3.7l0,4.5l1.3,0l0-8.1l-1.3,0
            C511.1,94.9,510.3,96.7,508.9,98.1z'
        />
        <path d='M518.8,70.6l-1.3,0l0,20.4l1.3,0L518.8,70.6z' />
        <path d='M517.6,93.2l0,1.6c0,2,0.7,3.7,2.1,5c1.4,1.3,2.9,1.9,5,1.9l0-1.1c-1.7,0-3-0.5-4.1-1.6
            c-1.1-1.1-1.7-2.5-1.7-4.1l0-1.6L517.6,93.2z'
        />
        <path d='M401.9,103.1V82.6h2.5V91c0.6-0.7,1.3-1.2,2-1.6c0.8-0.4,1.6-0.6,2.6-0.6c0.8,0,1.6,0.2,2.2,0.5
            c0.7,0.3,1.2,0.8,1.7,1.4c0.5,0.6,0.8,1.4,1.1,2.3c0.3,0.9,0.4,1.9,0.4,3.1c0,1-0.1,2-0.4,2.9c-0.3,0.9-0.7,1.7-1.2,2.3
            c-0.5,0.6-1.2,1.2-1.9,1.5c-0.8,0.4-1.6,0.6-2.6,0.6c-0.9,0-1.7-0.2-2.4-0.5c-0.6-0.4-1.2-0.8-1.7-1.5l-0.1,1.3
            c-0.1,0.4-0.3,0.5-0.6,0.5H401.9z M408.2,90.7c-0.8,0-1.5,0.2-2.2,0.6c-0.6,0.4-1.2,0.9-1.7,1.6v6.8c0.5,0.6,0.9,1,1.5,1.3
            c0.6,0.3,1.2,0.4,1.8,0.4c1.3,0,2.4-0.5,3.1-1.4c0.7-0.9,1.1-2.3,1.1-4c0-0.9-0.1-1.7-0.3-2.4c-0.2-0.7-0.4-1.2-0.7-1.6
            c-0.3-0.4-0.7-0.7-1.1-0.9C409.3,90.8,408.8,90.7,408.2,90.7z'
        />
        <path d='M421.3,107.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.6,0.2h-1.9l2.6-5.6L415.3,89h2.2
            c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.3l3.8,8.9c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.2,0.6
            c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.6l3.7-8.9c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.2h2L421.3,107.2z'
        />
      </g>
    </g>
  </svg>
)

export default Logo
