// WIP Check here: https://60354592b5e6c900236c2d81-rshtzqxsiu.chromatic.com/?path=/story/tools-card--extra
import useDictionary from '../../utils/useDictionary'
import { useTranslation } from 'react-i18next'
import useData from '../../utils/useData'
import Skeleton from '@mui/material/Skeleton'
import { IrisDoubleCardType } from '../../types/componentTypes'
import { AmalfiDoubleCard } from '@amalfi-analytics/components'

export const getDisplay = (result: string | number, errorMsg: string, format?: string): string => {
  let display
  if (result === null || result === undefined) {
    display = errorMsg
  } else if (format === 'HH:mm') {
    const ROUNDED_VALUE = Math.round(Number(result))
    const hours = Math.floor(ROUNDED_VALUE / 60).toString().padStart(2, '0')
    const minutes = (ROUNDED_VALUE % 60).toString().padStart(2, '0')
    display = hours + ':' + minutes
  } else {
    display = result.toLocaleString('ca', { minimumFractionDigits: 0 })
  }
  return display
}

// TODO: FIX THIS
// Format the value - Should we always be like this?
interface DoubleValue {
  bottom: number | string
  top: number | string
}

const IrisDoubleCard = ({ element }: { element: IrisDoubleCardType }): JSX.Element => {
  const { props, dictName, applicationID } = element
  const { label, indicatorBottom, indicatorTop } = props ?? {}
  const { t } = useTranslation()
  const { searchInDictionary } = useDictionary(dictName, applicationID)
  const { data, loading, error } = useData<DoubleValue>(element)

  if (error !== undefined) {
    console.error(error)
  }
  if (loading || error !== undefined || data === undefined) {
    return <Skeleton variant='rectangular' width='96%' height='100' style={{ margin: 10 }} />
  }

  // Format the card
  const colorTop = indicatorTop?.color !== undefined ? indicatorTop?.color : 'primary'
  const colorBottom = indicatorBottom?.color !== undefined ? indicatorBottom?.color : 'primary'

  // Format the value
  const result = data
  const errorMsg = t('error.no_data')
  const displayTop = getDisplay(result.top, errorMsg, indicatorTop?.format)
  const displayBot = getDisplay(result.bottom, errorMsg, indicatorBottom?.format)
  const card = {
    label: searchInDictionary(label),
    indicatorTop: {
      label: searchInDictionary(indicatorTop?.label),
      value: displayTop,
      color: colorTop
    },
    indicatorBottom: {
      label: searchInDictionary(indicatorBottom?.label),
      value: displayBot,
      color: colorBottom
    }
  }
  return <AmalfiDoubleCard {...card} />
}

export default IrisDoubleCard
