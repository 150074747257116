import { RecoilState, RecoilValueReadOnly, useRecoilValue, useSetRecoilState } from 'recoil'
import { Skeleton } from '@mui/material'
import { AmalfiMinMaxSelector } from '@amalfi-analytics/components'
import useDictionary from '../../utils/useDictionary'

interface AmalfiMinMaxPickerProps {
  atom: RecoilState<{
    name: string
    value: {
      gte: number
      lt: number
    }
    // dictName?: string
  }>
  selector: RecoilValueReadOnly<{
    name: string
    value: {
      gte: number
      lt: number
    }
    dictName?: string
    dictAppId?: string
  }>
}

const AmalfiMinMaxPicker = ({ atom, selector }: AmalfiMinMaxPickerProps): JSX.Element => {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)

  const { loading, searchInDictionary } = useDictionary(state.dictName, state.dictAppId)

  if (state === null) {
    return <></>
  }

  if (state === undefined || loading) {
    return <Skeleton />
  }

  const handleMin = (event: { target: { value: string } }): void => {
    setState({ ...state, value: { ...state.value, gte: Number(event.target.value) } })
  }

  const handleMax = (event: { target: { value: string } }): void => {
    setState({ ...state, value: { ...state.value, lt: Number(event.target.value) } })
  }

  const submitRange = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
  }

  const min = {
    value: state.value.gte,
    label: searchInDictionary('min'),
    handle: handleMin
  }

  const max = {
    value: state.value.lt,
    label: searchInDictionary('max'),
    handle: handleMax
  }

  const label = searchInDictionary(state.name)

  return <AmalfiMinMaxSelector label={label} min={min} max={max} handleSubmit={submitRange} />
}

export default AmalfiMinMaxPicker
