import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

interface optionProps {
  code: string
  descriptor: string
}

export interface AmalfiAsyncAutocompleteProps {
  options: readonly optionProps[]
  label: string
  getOptionLabel: (option: optionProps) => string
  onChange: (event: React.SyntheticEvent, value: optionProps[]) => void
  loading: boolean
  value: optionProps[]
}

const filterOptions = createFilterOptions<optionProps>({
  limit: 100
})

const AmalfiAsyncAutocomplete = ({
  options,
  label,
  getOptionLabel,
  onChange,
  loading,
  value
}: AmalfiAsyncAutocompleteProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  return (
    <Autocomplete<optionProps, true>
      id={'asynchronous-' + label}
      open={open}
      filterOptions={filterOptions}
      onOpen={() => { setOpen(true) }}
      onClose={() => { setOpen(false) }}
      multiple
      value={value}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      onChange={onChange}
      ChipProps={{ variant: 'outlined', color: 'primary' }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default AmalfiAsyncAutocomplete
