
import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

interface DashboardSelectorsProps {
  dashboardIds: string[]
  currentDashboardId: string
  setCurrentDashboardId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export function DashboardSelectors (props: DashboardSelectorsProps): JSX.Element {
  const { dashboardIds, setCurrentDashboardId, currentDashboardId } = props
  const onClick = (e: any): void => {
    setCurrentDashboardId(e.target.value)
    // We reload here because not all components refresh.
    window.location.reload()
  }
  return (
    <Select
      style={{
        position: 'absolute',
        right: 260,
        top: 5,
        zIndex: 100000,
        borderColor: 'white'
      }}
      color='secondary'
      sx={{
        '.MuiSelect-select': theme => ({
          color: theme.palette.primary.contrastText
        }),
        '.MuiSelect-icon': theme => ({
          color: theme.palette.primary.contrastText
        }),
        '.MuiSelect-nativeInput': theme => ({
          borderColor: theme.palette.primary.contrastText
        })
      }}
      value={currentDashboardId}
      onChange={onClick}
    >{
        dashboardIds.map(id => (
          <MenuItem key={`item_${id}`} value={id}>{id.replace(/-/g, ' ')}</MenuItem>
        ))
      }
    </Select>
  )
}
