interface LogoProps {
  width?: number
  height?: number
  color?: string
}

// style="enable-background:new 0 0 395.6 137.9;"
const Logo = ({ width, height, color }: LogoProps): JSX.Element => (
  <svg version='1.1' viewBox='0 0 395.6 137.9' height={height} width={width} style={{ fill: color }}>
    <g>
      <path d='M187.5,110h-10.8l-26.1-43.4c-1.5,0.1-3.8,0.1-7.1,0.1h-5.4v43.4h-8.9V6.1h17.9c10.4,0,18.7,2.5,25.1,7.6
        c6.7,5.4,10,12.8,10,22.3c0,15.4-7.3,25.2-21.9,29.1L187.5,110z M173.5,36.2c0-8.5-3.4-14.4-10.1-17.8c-5.4-2.7-13.8-4-25.3-4v43.1
        c12.4,0,21.4-1.7,27-5C170.7,49.3,173.5,43.8,173.5,36.2z'
      />
      <path d='M261,33.5h5.4v76H261V94.1c-5.7,11.5-15.5,17.2-29.4,17.2c-9.5,0-17.4-3.3-23.8-9.8
        c-6.4-6.5-9.6-14.5-9.6-23.8V33.5h5.4v44.2c0,7.8,2.7,14.5,8.2,20.3c5.5,5.7,12.1,8.6,19.8,8.6s14.6-2.9,20.5-8.6
        c5.9-5.7,8.9-12.5,8.9-20.3V33.5z'
      />
      <path d='M347.4,36.6c4.8-3.3,10.6-4.9,17.5-4.9c6.9,0,12.9,2.5,18.1,7.4c5.2,5,7.8,11.6,7.8,20.1v50.3h-5.4V59.2
        c0-6.5-2.1-12-6.4-16.3c-4.3-4.3-9.4-6.5-15.4-6.5c-6,0-11.4,2.3-16,6.8c-4.7,4.5-7,9.7-7,15.7v50.6H335V58.8
        c0-6.1-2.1-11.3-6.4-15.8c-4.3-4.4-9.5-6.7-15.5-6.7s-11.4,2.3-15.9,6.8c-4.5,4.5-6.8,9.7-6.8,15.7v50.6H285v-76h5.4v10.6
        c2.3-3.4,5.6-6.3,10-8.8c4.4-2.5,9.1-3.7,13.8-3.7c4.8,0,9.4,1.7,13.8,5c4.4,3.3,7.6,7.6,9.6,12.9
        C339.4,44.2,342.6,39.8,347.4,36.6z'
      />
      <g>
        <g>
          <rect x='57.7' y='127.1' style={{ fill: '#009845' }} width='55.2' height='8.9' />
          <g>
            <path d='M92.1,92c-9.3,9.5-20.4,14.2-33.4,14.2c-13,0-24.3-4.7-33.8-14.2c-7.8-7.8-12.4-16.7-13.8-26.8H3.7
                    c1.5,12,6.7,22.5,15.8,31.4c10.8,10.7,23.7,16.1,38.8,16.1c9.9,0,19.1-2.5,27.8-7.6c8.6-5.1,15.1-12,19.4-20.8v25.9h7.4v-45
                    h-7.4C104.1,75.3,99.7,84.3,92.1,92z'
            />
            <path d='M24.9,24.5C34.4,14.9,45.6,10,58.6,10c13,0,24.1,4.8,33.4,14.3c8,8.2,12.6,17.7,13.7,28.4h7.2V6.1h-7.4
                    v26.1c-3.6-8.6-9.8-15.6-18.8-20.8c-9-5.2-18.5-7.9-28.3-7.9c-15.1,0-28.1,5.4-38.8,16.1C10,29,4.7,40.1,3.6,52.8H11
                    C12.2,42.1,16.8,32.7,24.9,24.5z'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
