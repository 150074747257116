
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'

export default function Loading (): JSX.Element {
  return (
    <Dialog open fullScreen>
      <Box sx={{ display: 'flex', width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ transform: 'scale(2)' }}>
          <CircularProgress />
        </Box>
      </Box>
    </Dialog>
  )
}
