import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'
import useDictionary from '../../utils/useDictionary'
import useData from '../../utils/useData'
import { FigueresCardType } from '../../types/componentTypes'

interface InnerCardProps {
  title: string
  units: string
  error?: boolean
  display: string
}

// AmalfiCardFigueres
const InnerFigueresCard = (props: InnerCardProps): JSX.Element => {
  const { title, units, error, display } = props
  const displayLen = String(display).length

  return (
    <Grid
      container
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      spacing={0}
    >
      <Grid item>
        <Typography variant='h6' color='secondary.dark'>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={1}>
            <Divider orientation='vertical' sx={{ height: '50%', ml: 1, mt: 2 }} />
          </Grid>
          <Grid item xs={11}>
            <Box sx={{ m: 1 }}>
              <Typography variant={displayLen < 10 ? 'h3' : 'body1'} color={error === true ? 'error.dark' : 'secondary.dark'}>
                {display}
              </Typography>
              <Typography align='right' color='secondary.dark'>
                {units}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

// IrisCardFigueres
const FigueresCard = ({ element }: { element: FigueresCardType }): JSX.Element => {
  const { props, dictName, applicationID } = element
  const { label, units, format } = props ?? {}
  const { t } = useTranslation()
  const { searchInDictionary } = useDictionary(dictName, applicationID)
  const { data, error } = useData<number | string | { value: number | string, error: boolean }>(element)

  if (error !== undefined) {
    console.error(error)
  }
  if (data === undefined || error !== undefined) {
    return <Skeleton variant='rectangular' width='96%' height='100' style={{ margin: 10 }} />
  }
  // Format the value
  let result
  let err
  if (typeof data === 'number' || typeof data === 'string') {
    result = data
    err = props?.error
  } else {
    result = data.value
    err = data.error ?? props?.error
  }

  let display
  if (result === null || result === undefined) {
    display = t('error.no_data')
  } else if (format === 'HH:mm') {
    const ROUNDED_VALUE = Math.round(Number(result))
    const hours = Math.floor(ROUNDED_VALUE / 60).toString().padStart(2, '0')
    const minutes = (ROUNDED_VALUE % 60).toString().padStart(2, '0')
    display = hours + ':' + minutes
  } else if (format === 'percentage') {
    display = result.toLocaleString('ca', { minimumFractionDigits: 0 }) + '%'
  } else {
    display = result.toLocaleString('ca', { minimumFractionDigits: 0 })
  }

  const unitsText = searchInDictionary(units)
  const card = {
    error: err,
    units: display !== t('error.no_data') ? unitsText : '',
    title: searchInDictionary(label),
    display
  }
  return <InnerFigueresCard {...card} />
}

export default FigueresCard
