import { RecoilState, RecoilValueReadOnly, useRecoilValue, useSetRecoilState } from 'recoil'
import Skeleton from '@mui/material/Skeleton'
import { AmalfiCheckboxGroupButtons } from '@amalfi-analytics/components'
import useDictionary from '../../utils/useDictionary'

export interface paramsProps {
  [property: string]: {
    label: string
  }
}

interface checkboxValueProps {
  value: string
  selected: boolean
  name: string
}

interface CheckboxAtom {
  options: checkboxValueProps[]
  value: string[]
  name: string
  dictName?: string
  dictAppId?: string
}

interface IrisCheckboxProps {
  atom: RecoilState<CheckboxAtom>
  selector: RecoilValueReadOnly<CheckboxAtom>
}

const DICT_NAME = 'filters'

export default function IrisCheckbox ({ atom, selector }: IrisCheckboxProps): JSX.Element {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)

  const filtersDict = useDictionary(DICT_NAME)
  const { loading, searchInDictionary } = useDictionary(state.dictName, state.dictAppId)

  if (state === undefined || filtersDict.loading || loading) {
    return <Skeleton />
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentValue = event.target.value
    const index = state.value.indexOf(currentValue)
    let value

    if (index > -1) {
      value = state.value.filter(i => i !== currentValue)
    } else {
      value = state.value.concat([currentValue])
    }
    setState({ ...state, value })
  }

  const handleSelectAll = (): void => {
    const value = state.options.map(i => i.value)
    setState({ ...state, value })
  }
  const handleClean = (): void => {
    setState({ ...state, value: [] })
  }

  const checkbox = {
    error: state.value.length < 1,
    labels: {
      label: searchInDictionary(state.name),
      error: filtersDict.searchInDictionary('at_least_select')
    },
    boxesData: state.options.map(box => {
      return {
        value: box.value,
        checked: state.value.includes(box.value),
        label: searchInDictionary(box.name),
        onChange: handleChange
      }
    })
  }

  const button = {
    labels: {
      select: filtersDict.searchInDictionary('select'),
      deselect: filtersDict.searchInDictionary('deselect')
    },
    handleSelectAll,
    handleClean
  }

  return <AmalfiCheckboxGroupButtons buttonsProps={button} checkboxProps={checkbox} />
}
