import { componentsRepository, ContainedItem } from './dashboardUtils'

const ComponentFactory = (element: ContainedItem, children?: JSX.Element): React.ReactNode => {
  if (element.component === undefined) {
    throw new Error('Expected component.')
  }
  const Component = componentsRepository[element.component]
  if (Component === undefined) {
    return <h1 color='error'>{element.component + ' not found '}</h1>
  }
  return <Component element={element}>{children}</Component>
}

export default ComponentFactory
