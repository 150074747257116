import soil from '@amalfi-analytics/soil-sdk'
import { DictionaryContent, SoilConfig } from '@amalfi-analytics/soil-sdk/types/SoilInstance'
import moment from 'moment'
// import { memoize } from "lodash"

// export const getDict = memoize(_getDict)

export async function getDict (
  dictName: string | undefined,
  setter: (value: DictionaryContent) => void,
  config: SoilConfig | null
): Promise<{} | undefined> {
  if (config === null || dictName === undefined) {
    return {}
  }

  const lang = localStorage.getItem('i18nextLng') ?? 'ca' // TODO: - getLanguage
  const soilInstance = soil.configure(config)
  const response = await soilInstance.getDictionary(dictName, lang)
  setter(response.content)
}

export const formatStringDate = (value: string): string => {
  return moment(value).format('YYYY-MM-DD HH:mm:00')
}

export const formatMomentDate = (moment: moment.Moment): string => {
  return moment.format('YYYY-MM-DD HH:mm:00')
}
