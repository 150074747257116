import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import ErrorBoundary from '../errors/ErrorBoundary'
import { map, flatten, zip, isEqual, isArray, sortBy } from 'lodash'
// Mui / material components
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

// Iris / auth utils
import { filtersFamilyAtom, filtersFamilySelector, filtersDefault, multiFiltersFamilySelectorRaw } from './filtersStateIris'
import { dashboardFiltersGroupProps, dashboardFiltersProps } from '../types/types'
import useDictionary from '../utils/useDictionary'

// Amalfi components
import Autocomplete from '../irisComponents/filters/IrisAutocomplete'
import { AmalfiButton } from '@amalfi-analytics/components'

// IRIS components
import IrisSelector from '../irisComponents/filters/IrisSelector'
import IrisCheckbox from '../irisComponents/filters/IrisCheckbox'
import IrisDatePicker from '../irisComponents/filters/IrisDatePicker'
import IrisRetrospectiveSelector, { RetrospectiveSelector2021 as RetrospectiveSelectorStatic } from '../irisComponents/filters/IrisRetrospectiveSelector'
import IrisMinMax from '../irisComponents/filters/IrisMinMax'

// TO DO FISX ANY
// { string : () => JSX.Element}
const components: any = {
  // Material components
  Paper,
  Grid,

  // To do in Iris
  Autocomplete,

  // Done in Iris
  IrisSelector,
  IrisMinMax,
  IrisRetrospectiveSelector,
  IrisCheckbox,
  IrisDatePicker,
  RetrospectiveSelectorStatic
}

const DICT_NAME = 'filters'

export function AmalfiFilterDialog (props: dashboardFiltersGroupProps): JSX.Element {
  const { searchInDictionary, loading } = useDictionary(DICT_NAME)

  // Open close
  const [open, setOpen] = React.useState(false)
  const { disabled, name, filters } = props

  if (loading) {
    return <></>
  }

  const items = filters.map((filter: dashboardFiltersProps, i) => {
    const { component, value } = filter
    const Component = components[component]
    const atom = filtersFamilyAtom(value)
    const selector = filtersFamilySelector(value)
    return (
      <Grid key={`filtersDialog_${i}`} item xs={12}>
        <ErrorBoundary>
          <Box sx={{ paddingTop: 1 }}>
            <Component atom={atom} selector={selector} />
          </Box>
        </ErrorBoundary>
      </Grid>
    )
  })
  const handleClickOpen = (): void => { setOpen(true) }
  const handleClose = (): void => { setOpen(false) }

  if (disabled !== undefined && disabled) {
    return (
      <AmalfiButton
        label={searchInDictionary(name)}
        onClick={handleClickOpen}
        disabled
        variant='text'
      />
    )
  }

  return (
    <div>
      <AmalfiButton label={searchInDictionary(name)} onClick={handleClickOpen} variant='text' />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle sx={{ margin: 1 }}>
          {searchInDictionary(name)}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            {items}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

function ActiveFiltersList ({ possibleFilters }: { possibleFilters: string[] }): JSX.Element {
  const defaultFilters = useRecoilValue(filtersDefault)
  const filterValues = useRecoilValue(multiFiltersFamilySelectorRaw(possibleFilters))
  const { searchInDictionary, loading } = useDictionary(DICT_NAME)
  if (loading) {
    return <></>
  }

  const nameDefaultAndValue = zip(possibleFilters, possibleFilters.map(filtName => defaultFilters[filtName]), filterValues)
    .filter(filt => {
      const def = filt[1] as { value: any }
      const val = filt[2] as { value: any }
      if (!isArray(def.value)) {
        return !isEqual(def.value, val.value)
      }
      // Note: Here if the value is an array we sort the elements. Which may not be the best thing...
      return !isEqual(sortBy(def.value), sortBy(val.value))
    })

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0.5, m: 0, alignItems: 'center' }}>
      <Box sx={{ margin: 0.5 }}>
        <Typography variant='body1'>
          {searchInDictionary('active-filters')}:
        </Typography>
      </Box>
      {
        nameDefaultAndValue.length === 0 && (
          <Box sx={{ margin: 0.5 }}>
            <Typography variant='subtitle1' color='text.secondary'>
              {searchInDictionary('no-active-filters')}
            </Typography>
          </Box>
        )
      }
      {
        map(nameDefaultAndValue, (filt) => (
          <ActiveFilter key={filt[0]} label={searchInDictionary((filt[1] as { name?: string })?.name ?? filt[0])} filterKey={filt[0] as string} />
        ))
      }
    </Box>
  )
}

function ActiveFilter ({ filterKey, label }: { filterKey: string, label: string }): JSX.Element {
  const defaultFilterValue = (useRecoilValue(filtersDefault)[filterKey] as any)
  const setFilterValue = useSetRecoilState(filtersFamilyAtom(filterKey))
  return (
    <Box sx={{ margin: 0.5 }}>
      <Chip
        label={label}
        variant='outlined'
        color='primary'
        onDelete={() => setFilterValue(defaultFilterValue)}
      />
    </Box>
  )
}

export function AmalfiFiltersMenu (props: { config: Array<dashboardFiltersProps | dashboardFiltersGroupProps> }): JSX.Element {
  const { config } = props
  const buttons = config.map((group: dashboardFiltersProps | dashboardFiltersGroupProps, i) => {
    if (group.filters !== undefined) {
      const disabled = group?.disabled ?? false
      return (
        <Grid key={`gridFiltersMenu_${i}`} item>
          <ErrorBoundary>
            <AmalfiFilterDialog {...group} disabled={disabled} />
          </ErrorBoundary>
        </Grid>
      )
    }

    const { component, value, disabled } = group
    const Component = components[component]
    const atom = filtersFamilyAtom(value)
    const selector = filtersFamilyAtom(value)

    return (
      <Grid key={`gridFiltersMenu_${i}`} item>
        <ErrorBoundary>
          <Component atom={atom} selector={selector} disabled={disabled ?? false} />
        </ErrorBoundary>
      </Grid>
    )
  })

  const filterNames = flatten(
    config
      .map((group: dashboardFiltersProps | dashboardFiltersGroupProps) => group.filters !== undefined ? group.filters : group))
    .map(filt => filt.value)

  return (
    <>
      <Paper sx={{ marginBottom: 1 }}>
        <Grid container justifyContent='space-evenly' alignItems='center' style={{ padding: 10, paddingLeft: 60 }}>
          {buttons}
        </Grid>
      </Paper>
      <Paper sx={{ padding: 1, marginBottom: 1 }}>
        <ActiveFiltersList possibleFilters={Array.from(new Set(filterNames))} />
      </Paper>
    </>
  )
}
