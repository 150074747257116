import { Typography, Box } from '@mui/material'
import { TitleProps } from '../types/componentTypes'

const Title = (props: TitleProps): JSX.Element => {
  const { label, subtitle, extra, fullWidth } = props
  if (subtitle !== undefined) {
    if (extra !== undefined) {
      return (
        <Box sx={{ color: 'secondary.dark' }}>
          <Box
            sx={{ display: 'flex', alignItems: 'baseline', marginBottom: -10 }}
          >
            <Typography
              variant='h4'
              style={{
                paddingRight: 10,
                width:
                  fullWidth !== undefined && fullWidth ? 'max-content' : 'auto'
              }}
            >
              {label}{' '}
            </Typography>
            <Typography
              variant='h6'
              style={{
                width:
                  fullWidth !== undefined && fullWidth ? 'max-content' : 'auto'
              }}
            >
              {extra}
            </Typography>
          </Box>
          <Typography
            variant='h4'
            style={{
              width:
                fullWidth !== undefined && fullWidth ? 'max-content' : 'auto'
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      )
    }
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          color: 'secondary.dark',
          flexWrap: 'wrap'
        }}
      >
        <Typography
          variant='h4'
          style={{
            paddingRight: 10,
            width: fullWidth !== undefined && fullWidth ? 'max-content' : 'auto'
          }}
        >
          {label}
        </Typography>
        <Typography
          variant='h6'
          style={{
            width: fullWidth !== undefined && fullWidth ? 'max-content' : 'auto'
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    )
  }
  return (
    <Box sx={{ color: 'secondary.dark' }}>
      <Typography
        variant='h4'
        style={{
          width: fullWidth !== undefined && fullWidth ? 'max-content' : 'auto'
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}

export default Title
