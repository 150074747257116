import { APP_NAME } from '../authentification/authConfig'

export const getApplication = (): string | undefined => {
  return APP_NAME
}

export function setMetadata (): void {
  const application = getApplication()
  if (application !== undefined) {
    document.title = `${application.toUpperCase()} | Amalfi Analytics`
  }
}
