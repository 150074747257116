import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

export interface AmalfiSelectProps {
  itemsData: Array<{ value: string, label: string }>
  value: string
  handleChange: (event: SelectChangeEvent) => void
  label: string
}

export default function AmalfiSelect (props: AmalfiSelectProps): JSX.Element {
  const { value, itemsData, handleChange, label } = props

  const items = itemsData.map(item => (
    <MenuItem
      key={item.label}
      value={item.value}
    >
      {item.label}
    </MenuItem>
  ))
  // id="demo-simple-select-label"
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id={`selector-${label}`}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`selector-${label}`}
          value={value}
          label={label}
          onChange={handleChange}
        >
          {items}
        </Select>
      </FormControl>
    </Box>
  )
}
