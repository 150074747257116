import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Locize from 'i18next-locize-backend'

const FALLBACK_LANG = 'ca'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Locize)
  .init({
    fallbackLng: FALLBACK_LANG,
    defaultNS: 'default',
    // resources,
    debug: false,
    interpolation: {
      // react already safes from xss
      escapeValue: false
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    },
    backend: {
      projectId: 'e91bb5fb-f964-4c60-b54d-073789d466d8',
      referenceLng: 'ca'
    }
  }
  )
