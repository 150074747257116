// Import utils
import axios from 'axios'
import { decode } from 'js-base64'
import { zipObject, difference } from 'lodash'

// Import host elements
import { getApplication } from '../utils/host'

// Import auth elements
import { IRIS_URL, LOGIN_KEY } from './authConfig'
import { refreshTokensAtomType } from './authState'
import { logoutAction } from './Logout'

// Import types
import { configurationType, dashboardResponse } from '../types/types'

/// ////////////
// Constants //
/// ////////////

export const LOGIN_URL = 'https://auth.amalfianalytics.com/api/login'
export const FORGET_URL = 'https://auth.amalfianalytics.com/password/forgot?tenantId='
export const REFRESH_URL = 'https://auth.amalfianalytics.com/api/jwt/refresh'
export const RECOVER_URL = 'https://auth.amalfianalytics.com/password/change?changePasswordId='

/// ///////////////
// Translations //
/// ///////////////

// Dictionary for the storage checkbox
export const checkboxLabelDict: { [x: string]: string } = {
  ca: 'Recordar en aquest equip',
  es: 'Recordarme en este equipo',
  en: 'Remember me on this computer',
  fr: 'Se souvenir de moi sur cet ordinateur'
}

/// ////////
// Types //
/// ////////

export interface Response { error: boolean, errorMessage?: string, errorURL?: string }

export interface UserLogin {
  email: string
  password: string
}

export type ErrorLogin = {
  message: string
} | null

/// ////////////
// Functions //
/// ////////////

export const getTokenExpirationDate = (token: string): Date => {
  if (token === null) {
    return new Date()
  }
  const [, encodedPayload] = token.split('.')
  const payload = JSON.parse(decode(encodedPayload))
  const expirationDate = new Date(payload.exp * 1000)
  return expirationDate
}

interface LoginResponse {
  state: boolean
  msg?: string
  status?: number
  response?: { changePasswordId: string }
  data?: { token: string, refreshToken: string }
}

export async function login (
  user: string,
  password: string,
  appId: string
): Promise<LoginResponse> {
  const data = {
    loginId: user,
    password,
    applicationId: appId
  }
  const headers = {
    Authorization: LOGIN_KEY,
    'Content-Type': 'application/json'
  }
  try {
    const response = await axios.post(LOGIN_URL, data, { headers })
    // TO DO:  CHECK ALL POSSIBLE RESPONSES
    if (response.status === 202) {
      return { state: false }
    } else if (response.status === 203) {
      return { state: false, msg: 'error.33', status: 203, response: response.data }
    }
    if (response.data.refreshToken === undefined || response.data.token === undefined) {
      return { state: false }
    }
    return { state: true, data: { token: response.data.token, refreshToken: response.data.refreshToken } }
  } catch (err) {
    return { state: false }
  }
}

export async function getDashboards (token: string): Promise<any> {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  }
  const response = await axios.get(IRIS_URL, { headers })
  return response.data
}

export async function askDashboards (token: string): Promise<{
  state: boolean
  data?: undefined
} | {
  state: boolean
  data: any
}> {
  try {
    const response = await getDashboards(token)
    if (response.result.length === 0) {
      return { state: false }
    }
    return { state: true, data: response }
  } catch {
    return { state: false }
  }
}

function filterDashboard (dashboards: dashboardResponse): configurationType[] {
  const location = getApplication()
  const dashboard = dashboards.result.filter(d => location === undefined || d.role.includes(location))
  return dashboard
}

export function getFilterDashboard (dashboards: dashboardResponse): configurationType[] {
  const dashboard = filterDashboard(dashboards)
  return dashboard
}

export function askFilterDashboard (dashboards: dashboardResponse): {
  state: boolean
  data?: undefined
} | {
  state: boolean
  data: configurationType[]
} {
  const dashboard = filterDashboard(dashboards)
  if (dashboard.length === 0) {
    return { state: false }
  }
  return { state: true, data: dashboard }
}

export function extractAppIds (dashboards: configurationType[]): string[] | null {
  const appsIds: string[] = []
  dashboards.map(d => d.dashboard_configs).forEach(a => a.forEach(b => b.appsId.forEach(c => {
    if (!appsIds.includes(c)) { appsIds.push(c) }
  })))

  return (appsIds.length > 0) ? appsIds : null
}

async function toRefreshToken (refreshToken: string): Promise<{
  token: string
  expirationDate: Date
}> {
  const headers = {
    'content-type': 'application/json'
  }
  const data = {
    refreshToken
    // "token": token
  }
  const response = await axios.post(REFRESH_URL, data, { headers })

  if (response.status !== 200) {
    throw new Error('Failed refresing token ' + refreshToken)
  }
  const newToken = response.data.token as string
  return {
    token: newToken,
    expirationDate: getTokenExpirationDate(newToken)

  }
}

export async function refreshSoilTokens (refreshTokens: refreshTokensAtomType): Promise<{
  [x: string]: {
    token: string
    expirationDate: Date
  }
}> {
  const appIds = Object.keys(refreshTokens)
  const response = await Promise.all(appIds.map(async (appId: string) => {
    return await toRefreshToken(refreshTokens[appId])
  }))
  return { ...zipObject(appIds, response) }
}

export async function refreshDashboards (irisToken: string, appIdsInTokens: string[], setDashboardAtom: any): Promise<void> {
  const dashboards = await getDashboards(irisToken)
  const dashboard = getFilterDashboard(dashboards)
  const appIds = extractAppIds(dashboard)
  // if some appIds are missing we need to logout
  if (difference(appIds, appIdsInTokens).length > 0) {
    return logoutAction()
  }
  setDashboardAtom(dashboard)
}
