import { IrisPaperType } from '../../types/componentTypes'
import useDictionary from '../../utils/useDictionary'
import { AmalfiPaper } from '@amalfi-analytics/components'

const IrisPaper = ({ element, children }: { element: IrisPaperType, children: JSX.Element }): JSX.Element => {
  const { props, dictName, applicationID } = element
  const {
    title, subtitle, fullWidth
    // components
  } = props ?? {}
  const { searchInDictionary } = useDictionary(dictName, applicationID)

  const paper = {
    title: searchInDictionary(title),
    subtitle: searchInDictionary(subtitle),
    fullWidth
  }
  return <AmalfiPaper {...paper}>{children}</AmalfiPaper>
}

export default IrisPaper
