import useDictionary from '../../utils/useDictionary'
import { useRecoilValue } from 'recoil'
import { filtersFamilySelector } from '../../filters/filtersStateIris'
import AmalfiActualizationMenu from '../../inConstruction/AmalfiActualitzationMenu'
import useData from '../../utils/useData'
import { IrisActualizationMenuType } from '../../types/componentTypes'

const IrisActualizationMenu = ({ element }: { element: IrisActualizationMenuType }): JSX.Element => {
  const { props, dictName, applicationID } = element
  const { data } = useData(element)
  const { searchInDictionary } = useDictionary(dictName, applicationID)
  const filterValue = useRecoilValue(filtersFamilySelector(props?.currentTimeFilter ?? ''))
  const menuData = { ...data, ...props }
  if (menuData === undefined) {
    return <></>
  }
  const { lastUpdate, lights, lightsLabel, lastUpdateLabel } = menuData
  const lastUpdateLabelValue = searchInDictionary(lastUpdateLabel)
  const lightsLabelValue = searchInDictionary(lightsLabel)

  return (
    <AmalfiActualizationMenu
      lastUpdate={lastUpdate}
      lastUpdateLabel={lastUpdateLabelValue}
      lightsLabel={lightsLabelValue}
      timeFilterValue={filterValue as { value: { gte: string, lt: string } } | undefined}
      lights={lights}
    />
  )
}

export default IrisActualizationMenu
